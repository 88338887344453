import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import {
  BillingScheduleModel,
  BillingScheduleResponse,
  Currency,
  PaginationArgsNew,
  ParsedResponse,
  PlanModel,
  PlansResponse,
  UsageEventModel,
  UsageMetricModel
} from '@sequencehq/core-models'
import { isDemoEnv } from 'lib/environment/environment'

export type { ParsedResponse }

type GetApiPlansApiArg = PaginationArgsNew & {
  name?: string
  currency?: string
  createdBefore?: string
  createdAfter?: string
  sortBy?: string
  sortOrder?: string
}

export type GetApiPlansPlanIdApiArg = {
  planId: string
}

type JustId = {
  id: string
}

export type PostApiUsageEventsApiArg = {
  usageEventRequest: Omit<UsageEventModel, 'id' | 'sequenceAccountId'>
}

export type PostUsageMetricsApiArg = {
  body: {
    aggregationProperty?: string
    aggregationType: 'COUNT' | 'UNIQUE' | 'SUM' | 'CUSTOM'
    eventType: string
    groupingProperty?: string
    metricType: 'SIMPLE' | 'GROUPED'
    name: string
    description?: string
    propertyFilters: Record<string, unknown>
    propertiesToNegate?: string[]
    unit?: string
  }
}

export type PutUsageMetricsApiArg = {
  id: string
  body: {
    aggregationProperty?: string
    aggregationType: 'COUNT' | 'UNIQUE' | 'SUM' | 'CUSTOM'
    eventType: string
    groupingProperty?: string
    metricType: 'SIMPLE' | 'GROUPED'
    name: string
    description?: string
    propertyFilters: Record<string, unknown>
    propertiesToNegate?: string[]
    unit?: string
  }
}

export type GetUsageMetricsIdApiArg = {
  id: string
}

interface BaseBodyPostBillingSchedulesApiArg {
  autoIssueInvoices: boolean
  collectionPaymentProvider?: 'STRIPE' | 'UNRECOGNIZED' | 'NONE'
  customerId: string
  discounts?: {
    amount: number
    discountCalculationType: string
    message: string
    priceIds: string[]
    separateLineItem: boolean
    validFrom?: string
    validTo?: string
  }[]
  endDate?: string
  purchaseOrderNumber?: string
  reference?: string
  label?: string
  recurrenceDayOfMonth?: number
  startDate: string
  taxRates?: {
    priceId: string
    taxRateId: string
  }[]
  createAsDraft: boolean
}

interface CustomTypeBodyPostBillingSchedulesApiArg
  extends BaseBodyPostBillingSchedulesApiArg {
  type: 'CUSTOM'
  priceIds: string[]
}

interface PlanTypeBodyPostBillingSchedulesApiArg
  extends BaseBodyPostBillingSchedulesApiArg {
  planId: string
  type: 'PLAN'
}

interface PhasesTypeBodyPostBillingSchedulesApiArg
  extends BaseBodyPostBillingSchedulesApiArg {
  phases: Array<{
    startDate: string
    endDate?: string
    priceIds: string[]
  }>
  type: 'PHASES'
}
type BodyPostBillingSchedulesApiArg =
  | CustomTypeBodyPostBillingSchedulesApiArg
  | PlanTypeBodyPostBillingSchedulesApiArg
  | PhasesTypeBodyPostBillingSchedulesApiArg

export type PostBillingSchedulesApiArg = {
  /** Specifying a unique ID for this request here lets you retry it without risk of performing the same operation twice. */
  'Idempotency-Key'?: string
  body: BodyPostBillingSchedulesApiArg
}

export type PutBillingSchedulesBillingScheduleIdApiArg = {
  /** Specifying a unique ID for this request here lets you retry it without risk of performing the same operation twice. */
  'Idempotency-Key'?: string
  /** Billing Schedule ID */
  billingScheduleId: string
  body: {
    autoIssueInvoices: boolean
    endDate?: string
    purchaseOrderNumber?: string
    recurrenceDayOfMonth?: number
    startDate: string
    taxRates?: {
      priceId: string
      taxRateId: string
    }[]
    priceIds?: Array<string>
    phases?: Array<{
      startDate: string
      endDate?: string
      priceIds: string[]
    }>
    type?: 'CUSTOM' | 'PHASES' | 'PLAN'
  }
}

export type PutBillingSchedulesBillingScheduleIdActivateApiArg = {
  /** Specifying a unique ID for this request here lets you retry it without risk of performing the same operation twice. */
  'Idempotency-Key'?: string
  /** Billing Schedule ID */
  billingScheduleId: string
}

export type PutBillingSchedulesBillingScheduleIdArchiveApiArg = {
  /** Specifying a unique ID for this request here lets you retry it without risk of performing the same operation twice. */
  'Idempotency-Key'?: string
  /** Billing Schedule ID */
  billingScheduleId: string
}

export type GetApiSelfApiResponse = /** status 200 OK */ {
  launchDarklyHash?: string
  sequenceAccounts: string[]
  sequenceUser: {
    createdAt: string
    email: string
    id: string
  }
}
export type GetApiSelfApiArg = void

export type PostExchangeStytchIstArg = {
  body: {
    discoveryFlow: {
      organisationId: string
      intermediateSessionToken: string
    }
  }
}

export type PostMagicLinksFlowAuthArg = {
  body: {
    magicLinksFlow: {
      magicLinksToken: string
    }
  }
}

export type PostAuthFlowArgApiResponse = {
  session: {
    requestId: string
    memberAuthenticated: boolean
    member: {
      id: string
      name: string
      email: string
      emailAddressVerified: boolean
      organisationId: string
    }
    memberSession: {
      memberSessionId: string
      expiresAt: string
      startedAt: string
      sequenceAccountId: string
      sequenceUserId: string
    }
    sessionJwt: string
  }
}

export const baseApi = createApi({
  baseQuery: fetchBaseQuery({
    baseUrl: `${import.meta.env.VITE_API_ORIGIN ?? ''}/api`,
    credentials: isDemoEnv() ? 'omit' : 'include',
    prepareHeaders: headers => {
      if (isDemoEnv()) {
        headers.set(
          'Authorization',
          `Basic ${import.meta.env.VITE_DEMO_API_KEY}`
        )
      }

      headers.set('Sequence-Version', '2024-01-01')

      return headers
    }
  }),
  tagTypes: ['Products'],
  endpoints: build => ({
    getInvoiceDownload: build.query<string, { id: string }>({
      query: args => ({
        url: `/invoices/${args.id}/download`,
        responseHandler: response => response.blob()
      })
    }),
    getCreditNoteDownload: build.query<string, { id: string }>({
      query: args => ({
        url: `/credit-notes/${args.id}/download`,
        responseHandler: response => response.blob()
      })
    }),
    getInvoiceGridCsvExport: build.query<
      string,
      {
        currency: Currency
        startDate: string | undefined
        endDate: string | undefined
      }
    >({
      query: ({ currency, startDate, endDate }) => ({
        url: `/invoices/invoices-grid-csv?invoiceCurrency=${currency}${
          startDate ? `&startDate=${startDate}` : ''
        }${endDate ? `&endDate=${endDate}` : ''}`,
        responseHandler: response => response.blob()
      })
    }),
    getInvoiceCsvExport: build.query<string, string>({
      query: queryParams => ({
        url: `/invoices/invoices-csv?${queryParams}`,
        responseHandler: response => response.blob()
      })
    }),
    getCreditNotesCsvExport: build.query<string, string>({
      query: queryParams => ({
        url: `/credit-notes/credit-notes-csv?${queryParams}`,
        responseHandler: response => response.blob()
      })
    }),
    getApiPlans: build.query<ParsedResponse<PlansResponse>, GetApiPlansApiArg>({
      query: queryArg => ({
        url: `/plans`,
        params: {
          after: queryArg.after,
          before: queryArg.before,
          limit: queryArg.limit,
          name: queryArg.name,
          createdAfter: queryArg.createdAfter,
          createdBefore: queryArg.createdBefore,
          sortBy: queryArg.sortBy,
          sortOrder: queryArg.sortOrder
        }
      })
    }),
    getApiPlansPlanId: build.query<
      ParsedResponse<PlanModel>,
      GetApiPlansPlanIdApiArg
    >({
      query: queryArg => ({
        url: `/plans/${queryArg.planId}`
      })
    }),
    getApiUsageEventsId: build.query<ParsedResponse<UsageEventModel>, JustId>({
      query: queryArg => ({
        url: `/usage-events/${queryArg.id}`
      })
    }),
    postApiUsageEvents: build.mutation<
      ParsedResponse<UsageEventModel>,
      PostApiUsageEventsApiArg
    >({
      query: queryArg => ({
        url: `/usage-events`,
        method: 'POST',
        body: queryArg.usageEventRequest
      })
    }),
    getBillingScheduleBillingScheduleId: build.query<
      ParsedResponse<BillingScheduleResponse>,
      JustId
    >({
      query: queryArg => ({
        url: `/billing-schedules/${queryArg.id}`
      })
    }),
    postUsageMetrics: build.mutation<
      ParsedResponse<UsageMetricModel>,
      PostUsageMetricsApiArg
    >({
      query: queryArg => ({
        url: `/usage-metrics`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    putUsageMetricsById: build.mutation<
      ParsedResponse<UsageMetricModel>,
      PutUsageMetricsApiArg
    >({
      query: queryArg => ({
        url: `/usage-metrics/${queryArg.id}`,
        method: 'PUT',
        body: queryArg.body
      })
    }),
    getUsageMetricsId: build.query<
      ParsedResponse<UsageMetricModel>,
      GetUsageMetricsIdApiArg
    >({
      query: queryArg => ({
        url: `/usage-metrics/${queryArg.id}`
      })
    }),
    postBillingSchedules: build.mutation<
      ParsedResponse<BillingScheduleModel>,
      PostBillingSchedulesApiArg
    >({
      query: queryArg => ({
        url: `/billing-schedules`,
        method: 'POST',
        body: queryArg.body,
        headers: { 'Idempotency-Key': queryArg['Idempotency-Key'] }
      })
    }),
    putBillingSchedulesBillingScheduleId: build.mutation<
      ParsedResponse<BillingScheduleModel>,
      PutBillingSchedulesBillingScheduleIdApiArg
    >({
      query: queryArg => ({
        url: `/billing-schedules/${queryArg.billingScheduleId}`,
        method: 'PUT',
        body: queryArg.body,
        headers: { 'Idempotency-Key': queryArg['Idempotency-Key'] }
      })
    }),
    putBillingSchedulesBillingScheduleIdActivate: build.mutation<
      ParsedResponse<BillingScheduleModel>,
      PutBillingSchedulesBillingScheduleIdActivateApiArg
    >({
      query: queryArg => ({
        url: `/billing-schedules/${queryArg.billingScheduleId}/activate`,
        method: 'PUT',
        headers: { 'Idempotency-Key': queryArg['Idempotency-Key'] }
      })
    }),
    putBillingSchedulesBillingScheduleIdArchive: build.mutation<
      ParsedResponse<BillingScheduleModel>,
      PutBillingSchedulesBillingScheduleIdArchiveApiArg
    >({
      query: queryArg => ({
        url: `/billing-schedules/${queryArg.billingScheduleId}/archive`,
        method: 'PUT',
        headers: { 'Idempotency-Key': queryArg['Idempotency-Key'] }
      })
    }),
    getApiSelf: build.query<
      ParsedResponse<GetApiSelfApiResponse>,
      GetApiSelfApiArg
    >({
      query: () => ({ url: `/self` })
    }),
    postExchangeStytchIst: build.mutation<
      ParsedResponse<PostAuthFlowArgApiResponse>,
      PostExchangeStytchIstArg
    >({
      query: queryArg => ({
        url: `/auth/session`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postMagicLinksFlowAuth: build.mutation<
      ParsedResponse<PostAuthFlowArgApiResponse>,
      PostMagicLinksFlowAuthArg
    >({
      query: queryArg => ({
        url: `/auth/session`,
        method: 'POST',
        body: queryArg.body
      })
    })
  })
})
