import { useCallback, useMemo, useState } from 'react'

import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { CubeStatus } from 'Cube/domain/cube.domain.types'

enum AvailableModal {
  Archive = 'archive',
  DeleteLatestDraft = 'deleteLatestDraft',
  Revoke = 'revoke'
}

type UseQuoteEditorKebabMenu = () => {
  duplicateQuote: {
    visible: boolean
    onClick: () => void
  }
  archiveQuote: {
    visible: boolean
    onClick: () => void
  }
  discardChanges: {
    visible: boolean
    onClick: () => void
  }
  revokeQuote: {
    visible: boolean
    onClick: () => void
  }
  modals: {
    [AvailableModal.Archive]: {
      active: boolean
      onClose: () => void
      onConfirm: () => Promise<void>
      submitting: boolean
      showSignatureWarning: boolean
    }
    [AvailableModal.DeleteLatestDraft]: {
      active: boolean
      onClose: () => void
      onConfirm: () => void
      submitting: boolean
    }
    [AvailableModal.Revoke]: {
      active: boolean
      onClose: () => void
      onConfirm: () => void
      submitting: boolean
    }
  }
  quoteId: string
}

export const useQuoteEditorKebabMenu: UseQuoteEditorKebabMenu = () => {
  const ctx = useCubeContext()

  const [activeModal, setActiveModal] = useState<AvailableModal | null>(null)
  const [submitting, setSubmitting] = useState(false)

  const modals = useMemo(
    () => ({
      [AvailableModal.Archive]: {
        active: activeModal === AvailableModal.Archive,
        submitting,
        onClose: () => setActiveModal(null),
        onConfirm: async () => {
          /** Reload the quote */
          await ctx.mutators.external.in.core()
          setActiveModal(null)
        },
        showSignatureWarning:
          ctx.queries.rawData.data.common.status === CubeStatus.QuoteReadyToSign
      },
      [AvailableModal.DeleteLatestDraft]: {
        active: activeModal === AvailableModal.DeleteLatestDraft,
        submitting,
        onClose: () => setActiveModal(null),
        onConfirm: async () => {
          setSubmitting(true)

          if (ctx.mutators.external.out.quote?.deleteLatestDraft) {
            await ctx.mutators.external.out.quote.deleteLatestDraft()
          }

          setSubmitting(false)
          setActiveModal(null)
        }
      },
      [AvailableModal.Revoke]: {
        active: activeModal === AvailableModal.Revoke,
        submitting,
        onClose: () => setActiveModal(null),
        onConfirm: () => {
          setActiveModal(null)
        }
      }
    }),
    [activeModal, ctx, submitting]
  )

  const duplicateQuoteOnClick = useCallback(() => {
    const onClick = async () => {
      await ctx.mutators.external.out.quote?.duplicate()
    }

    void onClick()
  }, [ctx.mutators.external.out.quote])

  const duplicateQuote = useMemo(
    () => ({
      visible: ctx.queries.availableFeatures.quote.duplicate.available.visible,
      onClick: duplicateQuoteOnClick
    }),
    [
      ctx.queries.availableFeatures.quote.duplicate.available.visible,
      duplicateQuoteOnClick
    ]
  )

  const archiveQuoteOnClick = useCallback(() => {
    setActiveModal(AvailableModal.Archive)
  }, [])

  const isVisibleDiscardChanges = useMemo(
    () =>
      ctx.queries.availableFeatures.quote.deleteLatestDraft.available.visible,
    [ctx.queries.availableFeatures.quote.deleteLatestDraft.available.visible]
  )

  const archiveQuote = useMemo(
    () => ({
      visible: ctx.queries.availableFeatures.quote.archive.available.visible,
      onClick: archiveQuoteOnClick
    }),
    [
      archiveQuoteOnClick,
      ctx.queries.availableFeatures.quote.archive.available.visible
    ]
  )

  const discardChangesOnClick = useCallback(() => {
    setActiveModal(AvailableModal.DeleteLatestDraft)
  }, [])

  const discardChanges = useMemo(
    () => ({
      visible: isVisibleDiscardChanges,
      onClick: discardChangesOnClick
    }),
    [discardChangesOnClick, isVisibleDiscardChanges]
  )

  const revokeQuoteOnClick = useCallback(() => {
    setActiveModal(AvailableModal.Revoke)
  }, [])

  const revokeQuote = useMemo(
    () => ({
      visible: ctx.queries.availableFeatures.quote.revoke.available.visible,
      onClick: revokeQuoteOnClick
    }),
    [
      ctx.queries.availableFeatures.quote.revoke.available.visible,
      revokeQuoteOnClick
    ]
  )

  return {
    duplicateQuote,
    archiveQuote,
    discardChanges,
    revokeQuote,
    modals,
    quoteId: ctx.queries.rawData.data.common.id
  }
}
