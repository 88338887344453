import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { SequenceMenuItem } from '@sequencehq/core-components'
import { useNotifications } from 'lib/hooks/useNotifications'

export const useCreateTemplateFromQuote = (props: {
  quoteId: string
  onSuccess?: (newQuoteTemplateId: string) => void
}) => {
  const { displayNotification, hideNotification } = useNotifications()

  return {
    createTemplateFromQuote: async () => {
      const pendingTemplateCreationNotificationId = displayNotification(
        'Creating template from quote'
      )
      const newTemplateRes =
        await dashboard20240730Client.postCreateQuoteTemplateFromQuote({
          id: props.quoteId
        })

      hideNotification(pendingTemplateCreationNotificationId)

      if (newTemplateRes.error || !newTemplateRes.data?.template?.id) {
        displayNotification('Failed to create template', {
          type: 'error'
        })
        return false
      }

      displayNotification('Template created', {
        type: 'success'
      })
      props.onSuccess?.(newTemplateRes.data.template.id)
      return true
    },
    creationInProgress: false
  }
}

export const CreateTemplateFromQuoteMenuItem = (props: {
  quoteId: string
  disabled?: boolean
  onSuccess?: (newQuoteTemplateId: string) => void
}) => {
  const { createTemplateFromQuote } = useCreateTemplateFromQuote({
    quoteId: props.quoteId,
    onSuccess: props.onSuccess
  })
  return (
    <SequenceMenuItem
      uuid="create-template-from-quote"
      label="Save as template"
      isDisabled={props.disabled}
      onClick={createTemplateFromQuote}
    />
  )
}
