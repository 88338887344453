import { useCallback } from 'react'
import { CorePortErrors } from 'modules/Cube/domain/cube.domain.types'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dashboard/v20240730'

export type ApiQuoteTemplate = DashboardApi20240730.GetQuoteTemplate.Response

type UseLoadQuoteTemplate = () => (quoteTemplateId: string) => Promise<{
  data: ApiQuoteTemplate | null
  error: CorePortErrors | null
}>

export const useLoadQuoteTemplate: UseLoadQuoteTemplate = () => {
  const load = useCallback(async (quoteTemplateId: string) => {
    const quoteTemplateResponse =
      await dashboard20240730Client.getQuoteTemplate({
        id: quoteTemplateId
      })

    if (!quoteTemplateResponse.data || quoteTemplateResponse.error) {
      return {
        data: null,
        error:
          quoteTemplateResponse.res.status === 404
            ? CorePortErrors.NotFound
            : CorePortErrors.Other
      }
    }

    return {
      data: quoteTemplateResponse.data,
      error: null
    }
  }, [])

  return load
}
