import React, { FC, useEffect, useState } from 'react'
import {
  InfiniteScroll,
  BulkActionsPositioner,
  ScrollFade
} from '@sequencehq/core-components'
import { useEnrichedBulkActions } from './hooks/useEnrichedBulkActions'
import { isEmpty } from 'lodash'
import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useMarkCompletedBulkActionsAsReviewed } from './hooks/useMarkCompletedBulkActionsAsReviewed'
import { usePrevious } from 'lib/usePrevious'
import { BulkActionInFlight } from './BulkActionInFlight'

const INACTIVE_REFETCH_INTERVAL = 20000
const ACTIVE_REFETCH_INTERVAL = 2000

type Props = {
  fallback: React.ReactNode
  selectionCount?: number
}

const useBulkActionsInFlight = (props: {
  selectionCount: number
}) => {
  const [reviewingId, setReviewingId] = useState<string | null>(null)
  const [refetchInterval, setRefetchInterval] = useState(
    INACTIVE_REFETCH_INTERVAL
  )

  const enrichedBulkActions = useEnrichedBulkActions(refetchInterval)
  const hasActiveBulkActions = enrichedBulkActions.bulkActions.some(
    bulkAction => bulkAction.status === 'PROCESSING'
  )

  const reviewBulkAction = useMutation(
    dashboard20240730Client.postReviewBulkAction
  )

  const dismiss = (id: string) => {
    reviewBulkAction.mutate({ id })
    if (reviewingId === id) setReviewingId(null)
  }

  useEffect(() => {
    setRefetchInterval(
      hasActiveBulkActions ? ACTIVE_REFETCH_INTERVAL : INACTIVE_REFETCH_INTERVAL
    )
  }, [hasActiveBulkActions])

  const markCompletedBulkActionsAsReviewed =
    useMarkCompletedBulkActionsAsReviewed()

  const previousSelectionCount = usePrevious(props.selectionCount)
  // biome-ignore lint/correctness/useExhaustiveDependencies: this should only run on unmount or selection count change
  useEffect(() => {
    if (
      previousSelectionCount !== props.selectionCount &&
      props.selectionCount > 0
    ) {
      markCompletedBulkActionsAsReviewed()
    }

    // Mark any completed bulk actions as reviewed when the component unmounts
    return () => {
      markCompletedBulkActionsAsReviewed()
    }
  }, [props.selectionCount, previousSelectionCount])

  return {
    enrichedBulkActions,
    dismiss,
    reviewingId,
    setReviewingId
  }
}

export const BulkActionsInFlight: FC<Props> = ({
  fallback,
  selectionCount = 0
}) => {
  const { enrichedBulkActions, dismiss, reviewingId, setReviewingId } =
    useBulkActionsInFlight({
      selectionCount
    })

  if (
    !enrichedBulkActions.bulkActions.length ||
    isEmpty(enrichedBulkActions.invoices)
  )
    return fallback

  return (
    <BulkActionsPositioner>
      <InfiniteScroll
        hasMore={enrichedBulkActions.hasNextPage}
        isLoading={enrichedBulkActions.isLoading}
        next={enrichedBulkActions.fetchNextPage}
      >
        <ScrollFade maxHeight={400}>
          {enrichedBulkActions.bulkActions.map(bulkAction => {
            return (
              <BulkActionInFlight
                key={bulkAction.id}
                bulkAction={bulkAction}
                onDismiss={() => dismiss(bulkAction.id)}
                invoices={enrichedBulkActions.invoices}
                isReviewing={reviewingId === bulkAction.id}
                onChangeReviewing={isReviewing =>
                  setReviewingId(isReviewing ? bulkAction.id : null)
                }
              />
            )
          })}
        </ScrollFade>
      </InfiniteScroll>
    </BulkActionsPositioner>
  )
}
