import { useMemo } from 'react'

import { Text, Flex } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Regular,
  Lato20Bold
} from '@sequencehq/design-tokens'
import { calculatePhaseTotalAmount } from '@sequencehq/utils/src/phase/calculatePhaseTotalAmount'
import { toMoney } from '@sequencehq/utils'

import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { apiPhaseToCalculatePhaseTotalAmountPhase } from '../../QuoteEditorPreview/previewRenderers.adapters'
import { phasesToQuotePhases } from 'Cube/communication/external/common.api.v1/adapters/common.adapters.out'

export const ContractValue = () => {
  const ctx = useCubeContext()

  const total = useMemo(() => {
    const hasPhaseBlock = ctx.queries.rawData.data.presentation.blocks.some(
      b => b.blockType === 'phases'
    )

    if (!hasPhaseBlock) {
      return 0
    }

    const phases = phasesToQuotePhases(ctx.queries.rawData.data)

    const { totalDiscountedRate } = calculatePhaseTotalAmount(
      apiPhaseToCalculatePhaseTotalAmountPhase(phases)
    )

    return totalDiscountedRate
  }, [ctx.queries.rawData.data])

  const currency = useMemo(
    () =>
      ctx.queries.rawData.data.common.currency ??
      ctx.queries.rawData.configuration.currency.default,
    [
      ctx.queries.rawData.data.common.currency,
      ctx.queries.rawData.configuration.currency.default
    ]
  )

  return (
    <Flex flexDir="column" gap="8px" marginBottom="16px">
      <Text
        {...Lato20Bold}
        color={GreyGrey80}
        data-testid="quote-information-inspector-contract-value"
      >
        {toMoney({ currency, value: String(total) })}
      </Text>
      <Text {...Lato13Regular} color={GreyGrey60}>
        Final amount may change due to usage, proration, credit or local taxes
      </Text>
    </Flex>
  )
}
