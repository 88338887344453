import { v1ApiLoadedData, VariantPrice } from '../ports'
import {
  ApiPricingTier,
  GraduatedPrice,
  LinearPrice,
  PackagedPrice,
  Price,
  PriceEditorPercentageTier,
  PriceEditorTier,
  PricingEditorDomainInput,
  PricingEditorDomainOutput,
  PricingModel,
  Product,
  SeatBasedGraduatedPrice,
  SeatBasedLinearPrice,
  StandardPrice,
  UsageTierType,
  VolumePrice
} from '../../../../domain'
import { match } from 'ts-pattern'
import {
  arrayToIdKeyedObject,
  enforceMinimumPrecision,
  Price as CommonPrice
} from '@sequencehq/utils'
import {
  INITIAL_PRICING_EDITOR_STATE,
  INITIAL_PRODUCT_DATA,
  NEW_PRICE_PATTERN,
  NEW_PRODUCT_PATTERN
} from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { sanitizePercentageTiers } from 'common/drawers/PricingEditor/utils/sanitizePercentageTiers'
import { sanitizeTiers } from 'common/drawers/PricingEditor/utils/sanitizeTiers'
import {
  v1ApiCreateListPriceData,
  v1ApiCreateProductData,
  v1ApiUpdateListPriceData
} from '../ports/list.api.v1/entitySaving'
import { v1ApiCreateVariantPriceData } from '../ports/variant.api.v1/entitySaving'
import { flow } from 'lodash/fp'
import { IntegrationServices } from '@sequencehq/api/dist/utils/commonEnums'

export const apiTiersToInternalTiers = (
  apiTiers: ApiPricingTier[]
): {
  usageTierType: UsageTierType
  percentageTiers: PriceEditorPercentageTier[]
  tiers: PriceEditorTier[]
} => {
  if (!apiTiers || apiTiers.length === 0) {
    return {
      usageTierType: 'FIXED',
      percentageTiers: sanitizePercentageTiers([
        {
          id: 'percentage-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPercentage: '0',
          flatFee: '0.00',
          maxPrice: '',
          minPrice: ''
        }
      ]),
      tiers: sanitizeTiers([
        {
          id: 'fixed-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPrice: '0.00',
          flatFee: '0.00'
        }
      ])
    }
  }

  if ('isPricePercentage' in apiTiers[0] && apiTiers[0]?.isPricePercentage) {
    return {
      usageTierType: 'PERCENTAGE',
      percentageTiers: sanitizePercentageTiers(
        apiTiers.map((apiTier, idx) => ({
          id: `tier-${idx}`,
          firstUnit:
            idx === 0
              ? '0.00'
              : (
                  parseFloat(apiTiers[idx - 1]?.upperBound ?? '0.00') + 0.01
                ).toFixed(2) ?? '0.00',
          lastUnit: apiTier.upperBound
            ? enforceMinimumPrecision(2)(apiTier.upperBound)
            : '',
          unitPrice: '0.00',
          unitPercentage: enforceMinimumPrecision(2)(apiTier.price ?? '0.00'),
          flatFee: enforceMinimumPrecision(2)(apiTier.fee ?? '0') ?? '0.00',
          maxPrice:
            'maxPrice' in apiTier && apiTier.maxPrice
              ? enforceMinimumPrecision(2)(apiTier.maxPrice)
              : '',
          minPrice:
            'minPrice' in apiTier && apiTier.minPrice
              ? enforceMinimumPrecision(2)(apiTier.minPrice)
              : ''
        }))
      ),
      tiers: sanitizeTiers([
        {
          id: 'fixed-tier-1',
          firstUnit: '0',
          lastUnit: '',
          unitPrice: '0.00',
          flatFee: '0.00'
        }
      ])
    }
  }

  return {
    usageTierType: 'FIXED',
    percentageTiers: sanitizePercentageTiers([
      {
        id: 'percentage-tier-1',
        firstUnit: '0',
        lastUnit: '',
        unitPercentage: '0',
        flatFee: '0.00',
        maxPrice: '',
        minPrice: ''
      }
    ]),
    tiers: sanitizeTiers(
      apiTiers.map((apiTier, idx) => ({
        id: `tier-${idx}`,
        firstUnit:
          idx === 0
            ? '0'
            : (
                parseFloat(apiTiers[idx - 1]?.upperBound ?? '0') + 1
              ).toString() ?? '0',
        lastUnit: apiTier.upperBound ?? '',
        unitPrice: enforceMinimumPrecision(2)(apiTier.price ?? '0.00'),
        flatFee: enforceMinimumPrecision(2)(apiTier.fee ?? '0') ?? '0.00'
      }))
    )
  }
}
export const apiPriceToInternalPrice =
  (args: {
    product?: Product
    integrations: PricingEditorDomainInput['data']['integrations']
  }) =>
  (price: v1ApiLoadedData['price']): Price => {
    const commonData = <PM extends PricingModel>(pricingModel: PM) => {
      const integrationIds = Object.keys(args.integrations).reduce(
        (acc, integrationService) => {
          const existingIntegration = price.integrationIds.find(
            integrationId => integrationId.service === integrationService
          )
          return {
            ...acc,
            [integrationService]:
              existingIntegration?.id ??
              args.integrations[integrationService].defaultLedgerAccount
          }
        },
        {}
      )

      return {
        id: price.id,
        common: {
          currency: price.currency,
          name: price.name || args.product?.name || '',
          productId: args.product?.id ?? '',
          pricingModel,
          integrationIds
        }
      }
    }

    return match(price.structure)
      .with({ pricingType: 'FIXED' }, (matchedApiPrice): StandardPrice => {
        return {
          ...commonData('STANDARD'),
          modelSpecific: {
            billingType: price.billingType,
            price: enforceMinimumPrecision(2)(matchedApiPrice.price),
            billingFrequency: price.billingFrequency
          }
        }
      })
      .with({ pricingType: 'ONE_TIME' }, (matchedApiPrice): StandardPrice => {
        return {
          ...commonData('STANDARD'),
          modelSpecific: {
            billingType: price.billingType,
            price: matchedApiPrice.price,
            billingFrequency: price.billingFrequency
          }
        }
      })
      .with({ pricingType: 'GRADUATED' }, (matchedApiPrice): GraduatedPrice => {
        return {
          ...commonData('GRADUATED'),
          modelSpecific: {
            usageMetricId: matchedApiPrice.usageMetricId,
            usageCalculationMode: matchedApiPrice.usageCalculationMode,
            usageCalculationPeriod: price.usageCalculationPeriod,
            ...apiTiersToInternalTiers(matchedApiPrice.tiers),
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with({ pricingType: 'VOLUME' }, (matchedApiPrice): VolumePrice => {
        return {
          ...commonData('VOLUME'),
          modelSpecific: {
            usageMetricId: matchedApiPrice.usageMetricId,
            ...apiTiersToInternalTiers(matchedApiPrice.tiers),
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            includePercentageLimits:
              matchedApiPrice.tiers.filter(
                tier => !!tier.maxPrice || !!tier.minPrice
              ).length > 0,
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with({ pricingType: 'PACKAGE' }, (matchedApiPrice): PackagedPrice => {
        return {
          ...commonData('PACKAGED'),
          modelSpecific: {
            usageMetricId: matchedApiPrice.usageMetricId,
            pricePerPackage: parseFloat(
              matchedApiPrice.pricePerPackage
            ).toFixed(2),
            packageSize: matchedApiPrice.packageSize,
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with({ pricingType: 'LINEAR' }, (matchedApiPrice): LinearPrice => {
        return {
          ...commonData('LINEAR'),
          modelSpecific: {
            price: matchedApiPrice.isPricePercentage
              ? '0.00'
              : enforceMinimumPrecision(2)(matchedApiPrice.pricePerUnit),
            percentage: matchedApiPrice.isPricePercentage
              ? matchedApiPrice.pricePerUnit
              : '0',
            minPrice:
              matchedApiPrice.isPricePercentage && matchedApiPrice.minPrice
                ? matchedApiPrice.minPrice
                : '',
            maxPrice:
              matchedApiPrice.isPricePercentage && matchedApiPrice.maxPrice
                ? matchedApiPrice.maxPrice
                : '',
            linearPriceType: matchedApiPrice.isPricePercentage
              ? 'PERCENTAGE'
              : 'FIXED',
            usageMetricId: matchedApiPrice.usageMetricId,
            billingFrequency: price.billingFrequency,
            billingType: 'IN_ARREARS',
            parameters: price.customMetricParameters.reduce(
              (acc, param) => ({
                ...acc,
                [param.parameterId]: param.value
              }),
              {}
            )
          }
        }
      })
      .with(
        { pricingType: 'SEAT_BASED' },
        (matchedApiPrice): SeatBasedLinearPrice | SeatBasedGraduatedPrice => {
          // If there are tiers, then it's a graduated price
          if (
            'tiers' in price.structure &&
            (price.structure.tiers ?? []).length > 0
          ) {
            return {
              ...commonData('SEAT_BASED_GRADUATED'),
              modelSpecific: {
                seatMetricId: matchedApiPrice.seatMetricId,
                pricePerSeat: enforceMinimumPrecision(2)(
                  matchedApiPrice.pricePerSeat
                ),
                contractedMinimumSeats: (
                  matchedApiPrice.contractedMinimumSeats ?? 0
                ).toFixed(0),
                prorationStrategy: matchedApiPrice.prorationStrategy,
                billingFrequency: price.billingFrequency,
                billingType: price.billingType,
                overagesBillingFrequency:
                  matchedApiPrice.overagesBillingFrequency ?? 'NEVER',
                ...apiTiersToInternalTiers(matchedApiPrice.tiers ?? []),
                prorateFlatFees: matchedApiPrice.prorateFlatFees
              }
            }
          }

          return {
            ...commonData('SEAT_BASED_LINEAR'),
            modelSpecific: {
              seatMetricId: matchedApiPrice.seatMetricId,
              pricePerSeat: enforceMinimumPrecision(2)(
                matchedApiPrice.pricePerSeat
              ),
              contractedMinimumSeats: (
                matchedApiPrice.contractedMinimumSeats ?? 0
              ).toFixed(0),
              prorationStrategy: matchedApiPrice.prorationStrategy,
              billingFrequency: price.billingFrequency,
              billingType: price.billingType,
              overagesBillingFrequency:
                matchedApiPrice.overagesBillingFrequency ?? 'NEVER',
              prorateFlatFees: matchedApiPrice.prorateFlatFees
            }
          }
        }
      )
      .exhaustive()
  }

export const internalTiersToApiTiers = ({
  tiers
}: {
  tiers: PriceEditorTier[]
}): ApiPricingTier[] => {
  return tiers.map((tier, idx) => {
    return {
      price: enforceMinimumPrecision(2)(tier.unitPrice),
      isPricePercentage: false,
      /**
       * The last tier never has an upper bound, so we will never save it.
       */
      ...(idx === tiers.length - 1 ? {} : { upperBound: tier.lastUnit }),
      fee: enforceMinimumPrecision(2)(tier.flatFee)
    }
  })
}

export const internalPercentageTiersToApiTiers = ({
  tiers,
  includePercentageLimits
}: {
  tiers: PriceEditorPercentageTier[]
  includePercentageLimits: boolean
}): ApiPricingTier[] => {
  return tiers.map((tier, idx) => {
    return {
      price: enforceMinimumPrecision(2)(tier.unitPercentage),
      isPricePercentage: true,
      /**
       * The last tier never has an upper bound, so we will never save it.
       */
      ...(idx === tiers.length - 1
        ? {}
        : { upperBound: enforceMinimumPrecision(2)(tier.lastUnit) }),
      fee: enforceMinimumPrecision(2)(tier.flatFee),
      maxPrice:
        tier.maxPrice && includePercentageLimits
          ? enforceMinimumPrecision(2)(tier.maxPrice)
          : undefined,
      minPrice:
        tier.minPrice && includePercentageLimits
          ? enforceMinimumPrecision(2)(tier.minPrice)
          : undefined
    }
  })
}

export const internalPriceToApiPrice =
  (productId: Product['id']) =>
  (price: Price): v1ApiCreateListPriceData | v1ApiCreateVariantPriceData => {
    return {
      name: price.common.name,
      currency: price.common.currency,
      billingFrequency: price.modelSpecific.billingFrequency,
      billingType: price.modelSpecific.billingType,
      productId,
      integrationIds: Object.entries(price.common.integrationIds).map(
        ([service, id]) => ({
          service,
          id
        })
      ) as v1ApiCreateListPriceData['integrationIds'],
      ...('usageCalculationPeriod' in price.modelSpecific
        ? { usageCalculationPeriod: price.modelSpecific.usageCalculationPeriod }
        : {}),
      customMetricParameters: match(price)
        .with({ common: { pricingModel: 'STANDARD' } }, () => [])
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as v1ApiCreateListPriceData['customMetricParameters']
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as v1ApiCreateListPriceData['customMetricParameters']
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as v1ApiCreateListPriceData['customMetricParameters']
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) =>
            Object.entries(matchedModel.parameters).map(([id, value]) => ({
              parameterId: id,
              value: value
            })) as v1ApiCreateListPriceData['customMetricParameters']
        )
        .with({ common: { pricingModel: 'SEAT_BASED_LINEAR' } }, () => [])
        .with({ common: { pricingModel: 'SEAT_BASED_GRADUATED' } }, () => [])
        .exhaustive(),
      structure: match(price)
        .with(
          { common: { pricingModel: 'STANDARD' } },
          ({ modelSpecific: matchedModel }) => {
            if (matchedModel.billingFrequency === 'ONE_TIME') {
              return {
                pricingType: 'ONE_TIME',
                price: enforceMinimumPrecision(2)(matchedModel.price)
              }
            }

            return {
              pricingType: 'FIXED',
              price: enforceMinimumPrecision(2)(matchedModel.price)
            }
          }
        )
        .with(
          { common: { pricingModel: 'LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'LINEAR',
            pricePerUnit:
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? enforceMinimumPrecision(2)(matchedModel.percentage)
                : enforceMinimumPrecision(2)(matchedModel.price),
            minPrice:
              matchedModel.minPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.minPrice
                : undefined,
            maxPrice:
              matchedModel.maxPrice &&
              matchedModel.linearPriceType === 'PERCENTAGE'
                ? matchedModel.maxPrice
                : undefined,
            usageMetricId: matchedModel.usageMetricId,
            isPricePercentage: matchedModel.linearPriceType === 'PERCENTAGE'
          })
        )
        .with(
          { common: { pricingModel: 'VOLUME' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'VOLUME',
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits:
                      matchedModel.includePercentageLimits
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'GRADUATED',
            tiers:
              matchedModel.usageTierType === 'PERCENTAGE'
                ? internalPercentageTiersToApiTiers({
                    tiers: matchedModel.percentageTiers,
                    includePercentageLimits: false
                  })
                : internalTiersToApiTiers({
                    tiers: matchedModel.tiers
                  }),
            usageMetricId: matchedModel.usageMetricId,
            usageCalculationMode: matchedModel.usageCalculationMode
          })
        )
        .with(
          { common: { pricingModel: 'PACKAGED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'PACKAGE',
            packageSize: matchedModel.packageSize,
            pricePerPackage: enforceMinimumPrecision(2)(
              matchedModel.pricePerPackage
            ),
            usageMetricId: matchedModel.usageMetricId
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_LINEAR' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED',
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(
              matchedModel.contractedMinimumSeats
            ),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatMetricId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency,
            prorateFlatFees: false
          })
        )
        .with(
          { common: { pricingModel: 'SEAT_BASED_GRADUATED' } },
          ({ modelSpecific: matchedModel }) => ({
            pricingType: 'SEAT_BASED',
            pricePerSeat: matchedModel.pricePerSeat,
            contractedMinimumSeats: parseInt(
              matchedModel.contractedMinimumSeats
            ),
            prorationStrategy: matchedModel.prorationStrategy,
            seatMetricId: matchedModel.seatMetricId,
            chargeMethod: 'CHARGE_CONSUMED',
            overagesBillingFrequency:
              matchedModel.overagesBillingFrequency == 'NEVER'
                ? undefined
                : matchedModel.overagesBillingFrequency,
            tiers: internalTiersToApiTiers({
              tiers: matchedModel.tiers
            }),
            prorateFlatFees: matchedModel.prorateFlatFees
          })
        )
        .exhaustive() as v1ApiCreateListPriceData['structure']
    }
  }

const internalPriceToVariant =
  (apiPrice: VariantPrice) =>
  (domainPrice: Price): Price =>
    ({
      ...domainPrice,
      common: {
        ...domainPrice.common,
        listPriceId: apiPrice.listPriceId
      }
    }) as Price

const internalStructureToCommonStructure = (
  internalPrice: Price
): CommonPrice['structure'] => {
  return match(internalPrice)
    .with(
      { common: { pricingModel: 'STANDARD' } },
      ({ modelSpecific: matchedModel }) => {
        if (matchedModel.billingFrequency === 'ONE_TIME') {
          return {
            pricingType: 'ONE_TIME',
            price: enforceMinimumPrecision(2)(matchedModel.price)
          }
        }

        return {
          pricingType: 'FIXED',
          price: enforceMinimumPrecision(2)(matchedModel.price)
        }
      }
    )
    .with(
      { common: { pricingModel: 'LINEAR' } },
      ({ modelSpecific: matchedModel }) => ({
        pricingType: 'LINEAR',
        pricePerUnit:
          matchedModel.linearPriceType === 'PERCENTAGE'
            ? enforceMinimumPrecision(2)(matchedModel.percentage)
            : enforceMinimumPrecision(2)(matchedModel.price),
        minPrice:
          matchedModel.minPrice && matchedModel.linearPriceType === 'PERCENTAGE'
            ? matchedModel.minPrice
            : undefined,
        maxPrice:
          matchedModel.maxPrice && matchedModel.linearPriceType === 'PERCENTAGE'
            ? matchedModel.maxPrice
            : undefined,
        usageMetricId: matchedModel.usageMetricId,
        isPricePercentage: matchedModel.linearPriceType === 'PERCENTAGE'
      })
    )
    .with(
      { common: { pricingModel: 'VOLUME' } },
      ({ modelSpecific: matchedModel }) => ({
        pricingType: 'VOLUME',
        tiers:
          matchedModel.usageTierType === 'PERCENTAGE'
            ? internalPercentageTiersToApiTiers({
                tiers: matchedModel.percentageTiers,
                includePercentageLimits: matchedModel.includePercentageLimits
              })
            : internalTiersToApiTiers({
                tiers: matchedModel.tiers
              }),
        usageMetricId: matchedModel.usageMetricId
      })
    )
    .with(
      { common: { pricingModel: 'GRADUATED' } },
      ({ modelSpecific: matchedModel }) => ({
        pricingType: 'GRADUATED',
        tiers:
          matchedModel.usageTierType === 'PERCENTAGE'
            ? internalPercentageTiersToApiTiers({
                tiers: matchedModel.percentageTiers,
                includePercentageLimits: false
              })
            : internalTiersToApiTiers({
                tiers: matchedModel.tiers
              }),
        usageMetricId: matchedModel.usageMetricId,
        usageCalculationMode: matchedModel.usageCalculationMode
      })
    )
    .with(
      { common: { pricingModel: 'PACKAGED' } },
      ({ modelSpecific: matchedModel }) => ({
        pricingType: 'PACKAGE',
        packageSize: matchedModel.packageSize,
        pricePerPackage: enforceMinimumPrecision(2)(
          matchedModel.pricePerPackage
        ),
        usageMetricId: matchedModel.usageMetricId
      })
    )
    .with(
      { common: { pricingModel: 'SEAT_BASED_LINEAR' } },
      ({ modelSpecific: matchedModel }) => ({
        pricingType: 'SEAT_BASED',
        pricePerSeat: matchedModel.pricePerSeat,
        contractedMinimumSeats: parseInt(matchedModel.contractedMinimumSeats),
        prorationStrategy: matchedModel.prorationStrategy,
        seatMetricId: matchedModel.seatMetricId,
        chargeMethod: 'CHARGE_CONSUMED',
        overagesBillingFrequency:
          matchedModel.overagesBillingFrequency == 'NEVER'
            ? undefined
            : matchedModel.overagesBillingFrequency,
        prorateFlatFees: matchedModel.prorateFlatFees
      })
    )
    .with(
      { common: { pricingModel: 'SEAT_BASED_GRADUATED' } },
      ({ modelSpecific: matchedModel }) => ({
        pricingType: 'SEAT_BASED',
        pricePerSeat: matchedModel.pricePerSeat,
        contractedMinimumSeats: parseInt(matchedModel.contractedMinimumSeats),
        prorationStrategy: matchedModel.prorationStrategy,
        seatMetricId: matchedModel.seatMetricId,
        chargeMethod: 'CHARGE_CONSUMED',
        overagesBillingFrequency:
          matchedModel.overagesBillingFrequency == 'NEVER'
            ? undefined
            : matchedModel.overagesBillingFrequency,
        tiers: internalTiersToApiTiers({
          tiers: matchedModel.tiers
        }),
        prorateFlatFees: matchedModel.prorateFlatFees
      })
    )
    .exhaustive() as v1ApiCreateListPriceData['structure']
}

const internalParametersToCommonParameters = (
  internalPrice: Price
): CommonPrice['customMetricParameters'] => {
  return match(internalPrice)
    .with({ common: { pricingModel: 'STANDARD' } }, () => [])
    .with(
      { common: { pricingModel: 'LINEAR' } },
      ({ modelSpecific: matchedModel }) =>
        Object.entries(matchedModel.parameters).map(([id, value]) => ({
          parameterId: id,
          value: value
        })) as v1ApiCreateListPriceData['customMetricParameters']
    )
    .with(
      { common: { pricingModel: 'VOLUME' } },
      ({ modelSpecific: matchedModel }) =>
        Object.entries(matchedModel.parameters).map(([id, value]) => ({
          parameterId: id,
          value: value
        })) as v1ApiCreateListPriceData['customMetricParameters']
    )
    .with(
      { common: { pricingModel: 'GRADUATED' } },
      ({ modelSpecific: matchedModel }) =>
        Object.entries(matchedModel.parameters).map(([id, value]) => ({
          parameterId: id,
          value: value
        })) as v1ApiCreateListPriceData['customMetricParameters']
    )
    .with(
      { common: { pricingModel: 'PACKAGED' } },
      ({ modelSpecific: matchedModel }) =>
        Object.entries(matchedModel.parameters).map(([id, value]) => ({
          parameterId: id,
          value: value
        })) as v1ApiCreateListPriceData['customMetricParameters']
    )
    .with({ common: { pricingModel: 'SEAT_BASED_LINEAR' } }, () => [])
    .with({ common: { pricingModel: 'SEAT_BASED_GRADUATED' } }, () => [])
    .exhaustive()
}

export const internalPriceToCommonPrice = (
  internalPrice: Price
): CommonPrice => ({
  id: internalPrice.id,
  billingFrequency: internalPrice.modelSpecific.billingFrequency,
  billingType: internalPrice.modelSpecific.billingType,
  currency: internalPrice.common.currency,
  customMetricParameters: internalParametersToCommonParameters(internalPrice),
  integrationIds: Object.entries(internalPrice.common.integrationIds).map(
    ([integration, value]) => ({
      service: integration as IntegrationServices,
      id: value
    })
  ),
  name: internalPrice.common.name,
  productId: internalPrice.common.productId,
  structure: internalStructureToCommonStructure(internalPrice)
})

const internalProductToApiProduct = (
  internalProduct: Product
): v1ApiCreateProductData => ({
  name: internalProduct.name,
  label: internalProduct.label,
  taxCategoryId: internalProduct.taxCategoryId,
  revenueRecognitionMethod: internalProduct.revenueRecognitionMethod
})

export const apiIntegrationsToInternal = (
  xeroIntegration: v1ApiLoadedData['xeroIntegration']
): PricingEditorDomainInput['data']['integrations'] =>
  xeroIntegration.enabled
    ? arrayToIdKeyedObject([
        {
          id: 'Xero',
          defaultLedgerAccount:
            xeroIntegration.ledgerAccounts.defaults.chargeAccount || '',
          options: xeroIntegration.ledgerAccounts.options
        }
      ])
    : {}

const toDomainInputData =
  (apiData: Omit<v1ApiLoadedData, 'accountSettings'>) =>
  (domainPrice: Price): PricingEditorDomainInput['data'] => ({
    integrations: apiIntegrationsToInternal(apiData.xeroIntegration),
    listPrices: apiData.listPrices
      ? arrayToIdKeyedObject(
          apiData.listPrices.map(listPrice =>
            apiPriceToInternalPrice({
              product: apiData.product,
              integrations: apiIntegrationsToInternal(apiData.xeroIntegration)
            })(listPrice)
          )
        )
      : {},
    product: apiData.product
      ? {
          id: apiData.product.id,
          name: apiData.product.name,
          label: apiData.product.label,
          taxCategoryId: apiData.product.taxCategoryId,
          taxCategoryName: apiData.product.taxCategoryName
        }
      : INITIAL_PRODUCT_DATA,
    pricingEditorData: {
      ...INITIAL_PRICING_EDITOR_STATE.data.pricingEditorData,
      common: {
        ...domainPrice.common,
        id: apiData.price.id
      },
      [domainPrice.common.pricingModel]: domainPrice.modelSpecific
    }
  })

export const dataAdapter = {
  in: {
    variant: (
      data: Omit<v1ApiLoadedData, 'accountSettings'>
    ): {
      domainInputData: PricingEditorDomainInput['data']
      initialData: Omit<PricingEditorDomainInput['initialData'], 'mode'>
    } => {
      const domainPrice: Price = flow(
        apiPriceToInternalPrice({
          product: data.product,
          integrations: apiIntegrationsToInternal(data.xeroIntegration)
        }),
        internalPriceToVariant(data.price)
      )(data.price)

      return {
        domainInputData: toDomainInputData(data)(domainPrice),
        initialData: {
          price: domainPrice,
          product: data.product
        }
      }
    },
    list: (
      data: Omit<v1ApiLoadedData, 'accountSettings'>
    ): {
      domainInputData: PricingEditorDomainInput['data']
      initialData: Omit<PricingEditorDomainInput['initialData'], 'mode'>
    } => {
      const domainPrice = apiPriceToInternalPrice({
        product: data.product,
        integrations: apiIntegrationsToInternal(data.xeroIntegration)
      })(data.price)

      return {
        domainInputData: toDomainInputData(data)(domainPrice),
        initialData: {
          price: domainPrice,
          product: data.product
        }
      }
    }
  },
  out: {
    list: (
      data: PricingEditorDomainOutput
    ): {
      product?: v1ApiCreateProductData
      price: v1ApiCreateListPriceData | v1ApiUpdateListPriceData
    } => {
      // The casting is because TS can't quite seem to work out that the type it matches on for `modelSpecific` will equate to the `common.pricingModel` value.
      const price = {
        id: data.rawData.data.pricingEditorData.common.id,
        common: data.rawData.data.pricingEditorData.common,
        modelSpecific:
          data.rawData.data.pricingEditorData[
            data.rawData.data.pricingEditorData.common.pricingModel
          ]
      } as Price

      /**
       * We only create products via the pricing editor,
       * so we only return the product data when we're dealing with a new product.
       * If `apiProduct` is undefined, the assumption is that we don't want to persist any of its data to the API.
       * */
      const apiProduct = data.rawData.data.product.id.match(NEW_PRODUCT_PATTERN)
        ? internalProductToApiProduct(data.rawData.data.product)
        : undefined

      const apiPrice = internalPriceToApiPrice(
        data.rawData.data.pricingEditorData.common.productId
      )(price)

      if (
        data.rawData.data.pricingEditorData.common.id.match(NEW_PRICE_PATTERN)
      ) {
        return {
          product: apiProduct,
          price: apiPrice
        }
      } else {
        return {
          product: apiProduct,
          price: {
            ...apiPrice,
            id: data.rawData.data.pricingEditorData.common.id
          }
        }
      }
    },
    variant: (data: PricingEditorDomainOutput): v1ApiCreateVariantPriceData => {
      const price = {
        id: data.rawData.data.pricingEditorData.common.id,
        common: data.rawData.data.pricingEditorData.common,
        modelSpecific:
          data.rawData.data.pricingEditorData[
            data.rawData.data.pricingEditorData.common.pricingModel
          ]
      } as Price

      const apiPrice = internalPriceToApiPrice(
        data.rawData.data.pricingEditorData.common.productId
      )(price)

      const listPriceId = data.rawData.data.pricingEditorData.common.listPriceId
      return {
        ...apiPrice,
        ...(listPriceId ? { listPriceId } : {})
      }
    }
  }
}
