import { Outlet, RouteObject, useLocation } from 'react-router-dom'
import { SavedViewPage } from './pages/SavedViewPage'

export const savedViewsRoutes: RouteObject[] = [
  {
    path: 'saved-view',
    element: <Outlet />,
    children: [
      {
        path: ':id',
        element: <SavedViewPage />
      }
    ]
  }
]

export const savedViewPath = (id: string) => `/saved-view/${id}`

export const useIsOnSavedViewPage = () => {
  const { pathname } = useLocation()
  return pathname.startsWith('/saved-view/')
}
