import { FC } from 'react'
import { Box, Flex, Grid } from '@chakra-ui/react'
import { HomeHeader } from './components/HomeHeader'
import {
  DraftBillingSchedulesCard,
  DraftInvoicesCard,
  OverdueInvoicesCard,
  UnbilledCustomersCard
} from './components/HomeCards'
import { SchedulesActivity } from './components/SchedulesActivity'
import { BackgroundLines } from './components/BackgroundLines'
import { MoreOutOfSequence } from './components/MoreOutOfSequence'
import { QuotesReadyToExecute } from './components/QuotesReadyToExecute'

const Home: FC = () => {
  return (
    <Box overflowY="scroll" width="full" height="full" position="relative">
      <BackgroundLines />
      <Flex
        position="relative"
        flexDirection="column"
        maxWidth="888px"
        width="calc(100% - 48px)"
        marginX="auto"
      >
        <Flex flexDirection="column" py="24px" gap="24px">
          <HomeHeader />
        </Flex>
        <Grid templateColumns="repeat(4,1fr)" gap="16px" width="full">
          <DraftBillingSchedulesCard />
          <DraftInvoicesCard />
          <OverdueInvoicesCard />
          <UnbilledCustomersCard />
        </Grid>
        <Flex flexDirection="column" py="24px">
          <SchedulesActivity />
          <QuotesReadyToExecute />
          <MoreOutOfSequence />
        </Flex>
      </Flex>
    </Box>
  )
}

export default Home
