import { Currency } from '@sequencehq/api/commonEnums'
import { CubeDomainInterface } from 'Cube/domain/cube.domain'
import { QuoteTemplateUpdateBody } from '../ports/entitySaving/useSaveQuoteTemplate'
import { CubeReducerState } from 'Cube/domain/cube.domain.types'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type PutQuoteTemplatePhase =
  DashboardApi20240730.PutUpdateQuoteTemplate.PutPhase

export const phasesToQuoteTemplatePhases = (
  data: CubeReducerState['data']
): Array<{
  name?: string
  prices: Array<PutQuoteTemplatePhase['prices'][0]>
  discounts: Array<PutQuoteTemplatePhase['discounts'][0]>
  minimums: Array<PutQuoteTemplatePhase['minimums'][0]>
  duration?: {
    years: number
    months: number
    weeks: number
    days: number
  }
}> => {
  return Object.values(data.phases).map(phase => {
    /**
     * Generate a new set of unique uuids, as the BE does not allow for
     * the same priceId to be updated in the PUT.
     */
    const newPriceMap: Record<string, string> = phase.priceIds.reduce(
      (acc, priceId) => ({
        ...acc,
        [priceId]: crypto.randomUUID()
      }),
      {}
    )

    return {
      name: phase.name,
      prices: phase.priceIds.map(
        (priceId): PutQuoteTemplatePhase['prices'][0] => {
          const price = data.prices[priceId]

          const { id, ...rest } = {
            ...price,
            structure: price.structure
          }

          return {
            temporaryId: newPriceMap[id],
            ...rest
          }
        }
      ),
      discounts: Object.values(phase.discountIds).map(discountId => {
        const discount = data.discounts[discountId]

        return {
          restrictToPrices: discount.priceIds.map(
            priceId => newPriceMap[priceId]
          ),
          type: discount.discountCalculationType,
          amount: discount.amount.toString(),
          message: discount.message,
          separateLineItem: true,
          seatDiscountType: discount.seatDiscountType
        }
      }),
      minimums: Object.values(phase.minimumIds).map(minimumId => {
        const minimum = data.minimums[minimumId]
        return {
          restrictToPrices: minimum.scope.priceIds.map(
            priceId => newPriceMap[priceId]
          ),
          amount: minimum.value
        }
      }),
      duration:
        phase.duration &&
        phase.duration !== 'OPEN_ENDED' &&
        phase.duration !== 'MILESTONE'
          ? {
              years: phase.duration?.years ?? 0,
              months: phase.duration?.months ?? 0,
              weeks: 0,
              days: 0
            }
          : undefined
    }
  })
}

export const domainToApi = (
  data: CubeDomainInterface['queries']['rawData']['data']
): QuoteTemplateUpdateBody => {
  return {
    template: {
      title: data.common.title,
      currency: data.common.currency as Currency,
      dealType: data.quote.dealType,
      presentation: data.presentation,
      ...(data.quote.expiresIn?.days
        ? { expiryDays: data.quote.expiresIn?.days }
        : {})
    },
    phases: phasesToQuoteTemplatePhases(data),
    attachmentAssetIds: data.quote.attachmentAssets.map(asset => asset.id)
  }
}
