import Page from 'components/Page'
import { Outlet } from 'react-router-dom'

const HomePage = () => (
  <Page title="Home" paddingBottom={0}>
    <Outlet />
  </Page>
)

export default HomePage
