import { Box, Spinner, Tooltip } from '@chakra-ui/react'
import { ExclamationCircleIcon, XCircleIcon } from '@heroicons/react/16/solid'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  GreyGrey60,
  GreyGrey80,
  IndigoIndigo50,
  RedRed60
} from '@sequencehq/design-tokens'
import { MAX_FILE_SIZE, MAX_FILE_SIZE_TEXT } from 'Assets/constants'
import { FC, useEffect, useState } from 'react'
import { match } from 'ts-pattern'
import { Asset } from '../attachmentReducer'
import { AttachmentUI } from './AttachmentUI'

const uploadFile = async (file: File) => {
  const formData = new FormData()
  formData.append('file', file)

  return await dashboard20240730Client.postAssets({
    formData
  })
}

type UploadStatus = 'UPLOADING' | 'API_ERROR' | 'FILE_SIZE_TOO_LARGE'

export const NewAttachment: FC<{
  file: File
  clientId: string
  onUploadSuccess: (asset: Asset, clientId: string) => void
  onClickDelete: () => void
}> = ({ file, onUploadSuccess, onClickDelete, clientId }) => {
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(
    file.size >= MAX_FILE_SIZE ? 'FILE_SIZE_TOO_LARGE' : 'UPLOADING'
  )

  useEffect(() => {
    if (uploadStatus !== 'UPLOADING') {
      return
    }

    uploadFile(file)
      .then(res => {
        if (res.error) {
          setUploadStatus('API_ERROR')
          return
        }

        res.data && onUploadSuccess(res.data, clientId)
      })
      .catch(() => setUploadStatus('API_ERROR'))
  }, [file, onUploadSuccess, clientId, uploadStatus])

  return (
    <AttachmentUI
      name={file.name}
      fileSizeBytes={file.size}
      icon={match(uploadStatus)
        .with('UPLOADING', () => (
          <Spinner width="16px" height="16px" color={IndigoIndigo50} />
        ))
        .with('API_ERROR', () => (
          <Tooltip label={'Upload failed'}>
            <ExclamationCircleIcon width={16} color={RedRed60} />
          </Tooltip>
        ))
        .with('FILE_SIZE_TOO_LARGE', () => (
          <Tooltip label={`File size too large. Maximum ${MAX_FILE_SIZE_TEXT}`}>
            <ExclamationCircleIcon width={16} color={RedRed60} />
          </Tooltip>
        ))
        .exhaustive()}
      controls={
        <Box
          color={GreyGrey60}
          _hover={{ color: GreyGrey80 }}
          paddingX={1}
          onClick={e => {
            e.stopPropagation()
            onClickDelete()
          }}
          cursor="pointer"
        >
          <XCircleIcon width={16} />
        </Box>
      }
    />
  )
}
