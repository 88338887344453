import { Flex, Img } from '@chakra-ui/react'
import { Inspector } from '@sequencehq/core-components'
import { FC, ReactNode } from 'react'
import { xeroIntegrationConfig } from '../xero.integration.config'
import { LinkCustomerToServiceWidget } from '../../common/LinkEntities/LinkCustomerToService'
import { useIntegrationActive } from 'Integrations/utils/useIntegrationActive'
import { SmallIcon } from 'Integrations/integrationsConfig/common/SmallIcon'
import { LinkedInvoiceWidget } from 'Integrations/integrationsConfig/common/LinkEntities/LinkedInvoiceWidget/LinkedInvoiceWidget'
import { useIntegrationAuthError } from 'Integrations/utils/useIntegrationAuthError'
import { NotAuthenticatedItemContent } from 'Integrations/integrationsConfig/common/NotAuthenticatedItemContent'
import { LinkOrCreateCustomerModal } from 'Integrations/integrationsConfig/common/LinkEntities/LinkCustomerToService/LinkOrCreateCustomerModal'
import { LinkedCreditNoteWidget } from 'Integrations/integrationsConfig/common/LinkEntities/LinkedCreditNoteWidget/LinkedCreditNoteWidget'

export interface XeroInspectorItemProps {
  customerId: string
  canUnlink?: boolean
}

export const LinkXeroCustomerWidget: FC<{
  customerId: string
  canUnlink?: boolean
}> = props => (
  <LinkCustomerToServiceWidget
    customerId={props.customerId}
    sequenceEntityLabel="customer"
    externalEntityLabel="customer"
    service="Xero"
    canUnlink={props.canUnlink}
    linkModal={linkProps => <LinkOrCreateCustomerModal {...linkProps} />}
  />
)

export const LinkXeroInvoiceWidget: FC<{
  invoiceId: string
}> = props => <LinkedInvoiceWidget invoiceId={props.invoiceId} service="Xero" />

export const LinkXeroCreditNoteWidget: FC<{
  creditNoteId: string
}> = props => (
  <LinkedCreditNoteWidget creditNoteId={props.creditNoteId} service="Xero" />
)

export const XeroInspectorItem: FC<{
  children: ReactNode
}> = props => {
  const integrationActive = useIntegrationActive('Xero')
  const integrationAuthError = useIntegrationAuthError('Xero')

  if (!integrationActive && !integrationAuthError) {
    return null
  }

  return (
    <Inspector.IconItem
      data-testid="xero.inspector.item"
      content={
        integrationAuthError ? (
          <NotAuthenticatedItemContent
            service="Xero"
            data-testid="xero.inspector.content.unauth"
            serviceDescription={xeroIntegrationConfig.description}
            serviceName={xeroIntegrationConfig.title}
            logo={
              <Img
                src={xeroIntegrationConfig.smallLogoUrl}
                height="24px"
                width="24px"
              />
            }
          />
        ) : (
          <Inspector.Content
            title="Xero"
            icon={<SmallIcon url={xeroIntegrationConfig.smallLogoUrl} />}
            data-testid="xero.inspector.content"
          >
            <Flex flexDirection="column" gap="16px">
              {props.children}
            </Flex>
          </Inspector.Content>
        )
      }
      tooltip="Xero"
      icon={<SmallIcon url={xeroIntegrationConfig.smallLogoUrl} />}
    />
  )
}
