import { atom } from 'jotai'
import { loadable } from 'jotai/utils'
import { ExtractionData } from './types'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { SYSTEM_PROMPT, USER_PROMPT } from './prompts'

const extractionResultAtom = atom<null | Promise<ExtractionData | null>>(null)

export const loadableExtractionResultAtom = loadable(extractionResultAtom)

export const performExtractionAtom = atom(null, (_, set, assetId: string) => {
  set(extractionResultAtom, getExtractionResult(assetId))
})

const getExtractionResult = async (assetId: string) => {
  const extractionResult = await dashboardv99990101Client.postProcessContract({
    body: {
      assetId,
      systemPrompt: SYSTEM_PROMPT,
      userPrompt: USER_PROMPT
    }
  })
  if (extractionResult.error) {
    throw 'Error extracting contract'
  }

  if (isValidExtractionJson(extractionResult.data?.json)) {
    return extractionResult.data.json.data as ExtractionData
  }

  return null
}

const isValidExtractionJson = (
  json: unknown
): json is { data: ExtractionData } => {
  return typeof json === 'object' && json !== null && 'data' in json
}
