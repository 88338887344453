import { Customer } from '../types'

const LOGO_DEV_KEY = import.meta.env.VITE_LOGO_DEV_KEY

export function getLogoSrc(customer: Pick<Customer, 'domain'>) {
  if (!customer.domain) {
    return undefined
  }

  return `https://img.logo.dev/${customer.domain}?token=${LOGO_DEV_KEY}&format=png`
}
