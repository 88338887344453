import {
  CubeReducerState,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'
import { getStripePaymentAvailable } from 'Cube/domain/postActionStages/queries/availableFeatures/schedule/getStripePaymentAvailable.ts'

export const getStripeAutoChargeAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (
    getStripePaymentAvailable(prevState).available.enabled &&
    prevState.data.schedule.stripePayment
  ) {
    return {
      available: {
        visible: true,
        enabled: true
      },
      reasons: []
    }
  }

  return {
    available: {
      visible: false,
      enabled: false
    },
    reasons: []
  }
}
