import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey30,
  GreyGrey70,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { QuoteTemplateItem, TemplateItemSkeleton } from './QuoteTemplateItem'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { Skeleton } from '@sequencehq/core-components'

const useQuoteTemplatesList = () => {
  const templatesListQuery = useQuery(
    dashboard20240730Client.getListQuoteTemplates,
    undefined,
    {
      select: res => {
        if (!res?.items) {
          return []
        }

        return res.items.map(template => template.id)
      }
    }
  )

  return {
    isLoading: templatesListQuery.isPending,
    quoteTemplateIds: templatesListQuery.data ?? []
  }
}

export const QuoteTemplatesList = () => {
  const { isLoading, quoteTemplateIds } = useQuoteTemplatesList()

  if (isLoading || quoteTemplateIds.length === 0) {
    return null
  }

  return (
    <Box
      width="100%"
      maxWidth={`calc(100vw - 206px)`}
      padding="24px"
      paddingBottom="12px"
      backgroundColor={GreyGrey0}
      borderBottom={`1px solid ${GreyGrey30}`}
      overflow="hidden"
    >
      <Box color={GreyGrey70} {...Lato14Bold} marginBottom="24px">
        Create new quote from template
      </Box>
      <Box width="100%" overflow="auto" paddingBottom="12px">
        <Flex gap="24px">
          {quoteTemplateIds.map(quoteTemplateId => (
            <QuoteTemplateItem
              key={quoteTemplateId}
              quoteTemplateId={quoteTemplateId}
            />
          ))}
        </Flex>
      </Box>
    </Box>
  )
}
