import { FC } from 'react'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'

type ConfirmRecalculateInvoiceModalProps = {
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmRecalculateInvoiceModal: FC<
  ConfirmRecalculateInvoiceModalProps
> = ({ onConfirm, onClose }) => {
  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Recalculate invoice
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            Recalculating the invoice will update line items based on the latest
            usage data.
          </Box>
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              data-testid="recalculate-invoice.confirm"
              variant="primary2"
              onClick={onConfirm}
              height="32px"
              width="100%"
            >
              Recalculate invoice
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
