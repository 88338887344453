import { Box, Flex, Link, Text } from '@chakra-ui/react'
import { useQuery } from '@sequencehq/api'
import {
  BorderRadius4,
  BorderRadius8,
  GreyGrey10,
  GreyGrey30,
  GreyGrey70,
  GreyGrey80,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import {
  ArrowRightIcon,
  CalendarDaysIcon,
  FlagIcon,
  PlayCircleIcon
} from '@heroicons/react/16/solid'
import { ActivityCard } from '@sequencehq/core-components'
import { FC, useState } from 'react'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import Spinner from 'components/Loading'
import { format, add } from '@sequencehq/utils/dates'

const DISPLAY_LIMIT = 3

export const SchedulesActivity = () => {
  const [{ today, next30Days }] = useState(() => {
    const now = new Date()
    const next30Days = add(now, { days: 30 })
    return {
      today: format(now, 'yyyy-MM-dd'),
      next30Days: format(next30Days, 'yyyy-MM-dd')
    }
  })

  const { data: startingData, isLoading: startingIsLoading } = useQuery(
    dashboard20240730Client.getBillingSchedules,
    {
      billingScheduleStatus: 'ACTIVE',
      limit: 99,
      startingAfter: today,
      startingBefore: next30Days,
      sortBy: 'START_DATE',
      sortOrder: 'ASC',
      includeOpenEndedSchedules: 'true'
    }
  )

  const { data: endingData, isLoading: endingIsLoading } = useQuery(
    dashboard20240730Client.getBillingSchedules,
    {
      billingScheduleStatus: 'ACTIVE',
      limit: 99,
      endingBefore: next30Days,
      endingAfter: today
    },
    {
      select: data =>
        data?.items.sort(
          (a, b) =>
            new Date(a.endDate).valueOf() - new Date(b.endDate).valueOf()
        )
    }
  )

  const ending = {
    displayedItems: endingData?.slice(0, DISPLAY_LIMIT) ?? [],
    remainingItems: endingData?.slice(DISPLAY_LIMIT).length ?? 0
  }

  const starting = {
    displayedItems: startingData?.items?.slice(0, DISPLAY_LIMIT) ?? [],
    remainingItems: startingData?.items?.slice(DISPLAY_LIMIT).length ?? 0
  }

  const isLoading = startingIsLoading || endingIsLoading

  const isEmpty =
    !isLoading &&
    starting.displayedItems.length === 0 &&
    ending.displayedItems.length === 0

  return (
    <Flex direction="column">
      <Flex alignItems="baseline">
        <Text textStyle="sectionHeader" {...Lato14Bold}>
          Billing schedules
        </Text>
      </Flex>

      <Flex gap="6px" minHeight="142px">
        {isLoading && (
          <Flex
            p="12px"
            width="full"
            borderRadius={BorderRadius8}
            background={GreyGrey10}
            marginTop="12px"
          >
            <Spinner />
          </Flex>
        )}

        {starting.displayedItems.length > 0 && (
          <ActivityCardsSection
            title="Track upcoming schedules "
            remainingItems={starting.remainingItems}
          >
            {starting.displayedItems.map(schedules => (
              <ActivityCard
                key={schedules.id}
                title={
                  <CustomerPreviewCard customerId={schedules.customerId} />
                }
                date={schedules.startDate}
                dateIcon={<PlayCircleIcon />}
                dateLabel="Starts "
                href={`/billing-schedules/${schedules.id}`}
              />
            ))}
          </ActivityCardsSection>
        )}

        {ending.displayedItems.length > 0 && (
          <ActivityCardsSection
            title="Review ending schedules"
            remainingItems={ending.remainingItems}
          >
            {ending.displayedItems.map(schedules => (
              <ActivityCard
                key={schedules.id}
                title={
                  <CustomerPreviewCard customerId={schedules.customerId} />
                }
                date={schedules.endDate}
                dateIcon={<FlagIcon />}
                dateLabel="Ends "
                href={`/billing-schedules/${schedules.id}`}
              />
            ))}
          </ActivityCardsSection>
        )}

        {isEmpty && <EmptyState />}
      </Flex>
    </Flex>
  )
}

const CustomerPreviewCard = ({ customerId }: { customerId: string }) => (
  <Box
    minHeight="24px"
    marginLeft="-4px"
    marginTop="-4px"
    marginBottom="-4px"
    css={{
      '[aria-haspopup=dialog] > div': {
        paddingLeft: '4px'
      }
    }}
  >
    <CustomerPreviewCardPill customerId={customerId} variant="ghost" />
  </Box>
)

interface ActivityCardsSectionProps {
  title: string
  remainingItems: number
  children: React.ReactNode
}

const ActivityCardsSection: FC<ActivityCardsSectionProps> = ({
  remainingItems,
  title,
  children
}) => {
  return (
    <Flex
      direction="column"
      gap="8px"
      p="12px"
      width="full"
      borderRadius={BorderRadius8}
      background={GreyGrey10}
      marginTop="12px"
    >
      <Text {...Lato13Bold} color={GreyGrey70} paddingBottom="4px">
        {title}
      </Text>

      {children}

      {remainingItems > 0 && (
        <Link
          href="/billing-schedules?billingScheduleStatus=ACTIVE"
          color="indigo.50"
          _hover={{ textDecoration: 'none' }}
          display="flex"
          gap="4px"
          paddingTop="4px"
          alignItems="center"
        >
          <Text {...Lato13Bold} color={IndigoIndigo50}>
            View {remainingItems > 10 ? 'all' : `${remainingItems} more`}
          </Text>
          <ArrowRightIcon width="16px" height="16px" />
        </Link>
      )}
    </Flex>
  )
}

const EmptyState = () => (
  <Flex
    alignItems="center"
    flexDir="column"
    justifyContent="center"
    direction="column"
    width="full"
    p="12px"
    borderRadius={BorderRadius8}
    background={GreyGrey10}
    marginTop="12px"
  >
    <Box
      backgroundColor={GreyGrey30}
      borderRadius={BorderRadius4}
      width="24px"
      height="24px"
      p="4px"
    >
      <CalendarDaysIcon color={GreyGrey70} width="16px" height="16px" />
    </Box>
    <Text
      {...Lato13Bold}
      color={GreyGrey90}
      paddingTop="8px"
      paddingBottom="4px"
    >
      No activity to display
    </Text>
    <Text {...Lato13Regular} color={GreyGrey80}>
      You don’t have any schedule activity in the next 30 days.
    </Text>
  </Flex>
)
