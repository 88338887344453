import {
  CubeReducerState,
  PostActionStage
} from 'modules/Cube/domain/cube.domain.types'
import { getTotalPricingDuration } from 'modules/Cube/domain/postActionStages/queries/getTotalPricingDuration'
import { getAvailableFeatures } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/getAvailableFeatures'
import { getSelectedCurrency } from 'modules/Cube/domain/postActionStages/queries/getSelectedCurrency'
import { getOrderedPhases } from 'modules/Cube/domain/postActionStages/queries/getOrderedPhases'
import { getAvailableFrequencies } from 'modules/Cube/domain/postActionStages/queries/getAvailableFrequencies'
import { getAvailableTaxRates } from 'modules/Cube/domain/postActionStages/queries/getAvailableTaxRates'
import { getPricingDates } from 'modules/Cube/domain/postActionStages/queries/getPricingDates'
import { getResolvedPhaseData } from 'modules/Cube/domain/postActionStages/queries/getResolvedPhaseData'
import { getAvailableListPrices } from './queries/getAvailableListPrices'
import { getIsAQuoteTemplate } from './utils/getIsQuoteATemplate'

export const queries: PostActionStage = () => prevState => {
  /**
   * Accessing phase date, and analysis of phases, is valuable to
   * a number of further queries. Therefore we perform this first,
   * and pass the result to any relevant queries.
   */
  const resolvedPhases = getResolvedPhaseData(prevState)
  const orderedPhases = getOrderedPhases({ resolvedPhases })(prevState)

  const newQueryState: Omit<
    CubeReducerState['queries'],
    'cubeDataUpdated' | 'validation'
  > = {
    selectedCurrency: getSelectedCurrency(prevState),
    availableFrequencies: getAvailableFrequencies(prevState),
    availableTaxRates: getAvailableTaxRates(prevState),
    resolvedPhases,
    orderedPhases,
    pricingDates: getPricingDates({ orderedPhases })(prevState),
    totalPricingDuration: getTotalPricingDuration(prevState),
    availableFeatures: getAvailableFeatures({
      orderedPhases,
      resolvedPhases
    })(prevState),
    availableListPrices: getAvailableListPrices(prevState),
    quote: {
      isATemplate: getIsAQuoteTemplate(prevState)
    }
  }

  return {
    ...prevState,
    queries: {
      ...newQueryState,
      cubeDataUpdated: prevState.queries.cubeDataUpdated,
      validation: prevState.queries.validation
    }
  }
}
