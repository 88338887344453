import { getIsAQuoteTemplate } from 'Cube/domain/postActionStages/utils/getIsQuoteATemplate'
import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'
import { hasCubeBeenUpdated } from 'modules/Cube/domain/postActionStages/queries/availableFeatures/utils/hasCubeBeenUpdated'

export const getSaveTemplateAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const available =
    getIsAQuoteTemplate(prevState) && !prevState.data.common.isArchived

  if (!available) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: { status: prevState.data.common.status }
        }
      ]
    }
  }

  if (prevState.editor.savingDraft) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.SavingInProgress,
          metadata: {}
        }
      ]
    }
  }

  const enabled = hasCubeBeenUpdated(prevState)

  return {
    available: {
      visible: true,
      enabled: enabled
    },
    reasons: enabled
      ? []
      : [{ reasonType: DisabledReasonType.NoChangesMade, metadata: {} }]
  }
}
