import { uniq } from 'lodash/fp'
import { BulkAction } from './types'

export function allIds(
  bulkAction: Pick<BulkAction, 'requestedIds' | 'completedIds' | 'failedIds'>
): Array<string> {
  return uniq(
    [
      ...bulkAction.requestedIds,
      ...bulkAction.completedIds,
      ...bulkAction.failedIds
    ].sort()
  )
}

export function allCompletedIds(
  bulkAction: Pick<BulkAction, 'completedIds' | 'failedIds'>
): Array<string> {
  return uniq([...bulkAction.completedIds, ...bulkAction.failedIds].sort())
}
