import { Flex, Spinner } from '@chakra-ui/react'
import { GreyGrey60, Lato13Regular } from '@sequencehq/design-tokens'

export const NavLinksLoading = () => (
  <Flex
    alignItems="center"
    height="32px"
    padding="0 8px"
    gap="8px"
    {...Lato13Regular}
    color={GreyGrey60}
  >
    <Spinner height="16px" width="16px" color={GreyGrey60} /> Loading...
  </Flex>
)
