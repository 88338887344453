import { useSequenceInfiniteQuery } from '@sequencehq/api'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useMemo } from 'react'

type PaginatedBulkActions =
  DashboardApi20240730.GetBulkActions.PaginatedBulkActions

export const useBulkActions = (
  config?: Parameters<typeof useSequenceInfiniteQuery<PaginatedBulkActions>>[2]
) => {
  const { infiniteQuery } = useSequenceInfiniteQuery<PaginatedBulkActions>(
    dashboard20240730Client.getBulkActions,
    undefined,
    config
  )
  const pages = infiniteQuery.data?.pages

  const bulkActions = useMemo(
    () => pages?.flatMap(page => page.items).flat() ?? [],
    [pages]
  )

  return { bulkActions, infiniteQuery }
}
