import { SequenceMenuItemType } from '@sequencehq/core-components'
import { MinimumRow, PricingRow } from './usePricingTable'
import PencilSquareIcon from '@heroicons/react/24/solid/PencilSquareIcon'
import TrashIcon from '@heroicons/react/24/solid/TrashIcon'
import {
  GreyGrey60,
  GreyGrey70,
  IndigoIndigo50,
  Lato12Bold,
  Lato14Regular,
  RedRed50,
  RedRed60
} from '@sequencehq/design-tokens'
import { CheckIcon } from '@heroicons/react/16/solid'

const styles = {
  tableHeader: {
    ...Lato12Bold,
    color: GreyGrey60,
    textTransform: 'uppercase' as const,
    border: 'none',
    padding: '10px 8px'
  },
  tableCell: {
    alignItems: 'center',
    ...Lato14Regular,
    border: 'none',
    textAlign: 'left' as const,
    padding: '8px'
  },
  editIcon: {
    width: '16px',
    height: '16px',
    color: IndigoIndigo50
  },
  deleteIcon: {
    width: '16px',
    height: '16px',
    color: RedRed50
  },
  error: {
    ...Lato14Regular,
    color: RedRed60,
    padding: '8px 0 0',
    width: '280px'
  },
  tooltip: {
    ...Lato14Regular,
    color: GreyGrey70,
    lineHeight: '20px'
  }
}

export const productRowActions = (row: PricingRow): SequenceMenuItemType[] => {
  const allActions: Record<
    keyof PricingRow['availableFeatures'],
    SequenceMenuItemType
  > = {
    canEdit: {
      isDisabled: false,
      label: row.availableFeatures.canEdit?.disabled
        ? 'View price'
        : 'Edit price',
      tooltip: row.availableFeatures.canEdit?.disabledReason,
      onClick: row.availableFeatures.canEdit?.handler
    },
    canDelete: {
      isDisabled: row.availableFeatures.canDelete?.disabled,
      label: 'Remove product',
      tooltip: row.availableFeatures.canDelete?.disabledReason,
      onClick: row.availableFeatures.canDelete?.handler,
      style: { color: RedRed60 }
    },
    canEditDiscount: {
      isDisabled: row.availableFeatures.canEditDiscount?.disabled,
      label: 'Edit discount',
      tooltip: row.availableFeatures.canEditDiscount?.disabledReason,
      onClick: row.availableFeatures.canEditDiscount?.handler
    },
    canDeleteDiscount: {
      isDisabled: row.availableFeatures.canDeleteDiscount?.disabled,
      label: 'Remove discount',
      tooltip: row.availableFeatures.canDeleteDiscount?.disabledReason,
      onClick: row.availableFeatures.canDeleteDiscount?.handler ?? (() => null),
      style: { color: RedRed60 }
    },
    canEditPriceMetadata: {
      isDisabled: row.availableFeatures.canEditPriceMetadata?.disabled,
      label: 'ARR Calculation',
      tooltip: row.availableFeatures.canEditPriceMetadata?.disabledReason,
      hidden: row.availableFeatures.canEditPriceMetadata?.hidden,
      subItems: [
        {
          label: 'Include (default)',
          iconRight:
            row.phasePriceMetadata?.arrCalculation !== 'EXCLUDE' ? (
              <CheckIcon height="16px" width="16px" />
            ) : undefined,
          onClick: () =>
            row.availableFeatures.canEditPriceMetadata?.handler({
              arrCalculation: 'INCLUDE'
            })
        },
        {
          label: 'Exclude',
          iconRight:
            row.phasePriceMetadata?.arrCalculation === 'EXCLUDE' ? (
              <CheckIcon height="16px" width="16px" />
            ) : undefined,
          onClick: () =>
            row.availableFeatures.canEditPriceMetadata?.handler({
              arrCalculation: 'EXCLUDE'
            })
        }
      ]
    }
  }

  return Object.keys(row.availableFeatures).map(
    actionName =>
      allActions[actionName as keyof PricingRow['availableFeatures']]
  )
}

export const minimumRowActions = (row: MinimumRow): SequenceMenuItemType[] => {
  return [
    {
      isDisabled: !row.actions.edit.available,
      label: 'Edit minimum',
      onClick: row.actions.edit.handler,
      iconLeft: <PencilSquareIcon {...styles.editIcon} />
    },
    {
      isDisabled: !row.actions.delete.available,
      label: 'Remove minimum',
      onClick: row.actions.delete.handler,
      iconLeft: <TrashIcon {...styles.deleteIcon} />
    }
  ]
}
