import { useTaxIntegrationProvider } from 'lib/hooks/useTaxIntegrationProvider.ts'
import { useLoadAllItemsQuery, useQuery } from '@sequencehq/api/utils'
import {
  Dashboardv99990101Api,
  dashboardv99990101Client
} from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { Dashboardv99990101CommonModels } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { TAX_INTEGRATION_PROVIDERS } from 'lib/hooks/useTaxIntegrationProvider.ts'
import { TaxStatus } from 'components/Customers/types'

type UseTaxCategoryValidationProps = {
  taxCategoryId: string
  customerCountry: string
  customerTaxStatus: Dashboardv99990101CommonModels.Customers.CustomerTaxStatus
}

type UseTaxCategoryValidation = (props: UseTaxCategoryValidationProps) =>
  | {
      valid: boolean
      provider: typeof TAX_INTEGRATION_PROVIDERS.SEQUENCE
      taxRatesForCountry: Array<Dashboardv99990101Api.GetTaxRates.TaxRate>
    }
  | {
      valid: boolean
      provider: typeof TAX_INTEGRATION_PROVIDERS.AVALARA
      taxRatesForCountry: undefined
    }

const exemptTaxStatuses: TaxStatus[] = ['TAX_EXEMPT', 'REVERSE_CHARGED']

export const useTaxCategoryValidation: UseTaxCategoryValidation = ({
  taxCategoryId,
  customerCountry,
  customerTaxStatus
}) => {
  const { isAvalara, isSequence } = useTaxIntegrationProvider()
  const { data: taxRatesData, isLoading } = useLoadAllItemsQuery(
    dashboardv99990101Client.getTaxRates,
    undefined,
    undefined,
    { enabled: isSequence }
  )

  const { data: avalaraMappingsData } = useQuery(
    dashboardv99990101Client.getAllTaxCategoriesWithAvalaraMappings,
    undefined,
    {
      enabled: isAvalara
    }
  )

  if (exemptTaxStatuses.includes(customerTaxStatus)) {
    if (isAvalara) {
      return {
        valid: true,
        provider: TAX_INTEGRATION_PROVIDERS.AVALARA
      }
    }

    return {
      valid: true,
      provider: TAX_INTEGRATION_PROVIDERS.SEQUENCE,
      taxRatesForCountry: []
    }
  }

  if (isAvalara) {
    const avalaraMappings = avalaraMappingsData?.items ?? []
    const mappedTaxCategory = avalaraMappings.find(
      mapping => mapping.taxCategoryId === taxCategoryId
    )

    if (!mappedTaxCategory?.avalaraTaxCode) {
      return {
        valid: false,
        provider: TAX_INTEGRATION_PROVIDERS.AVALARA
      }
    }

    return {
      valid: true,
      provider: TAX_INTEGRATION_PROVIDERS.AVALARA
    }
  }

  const taxRates = taxRatesData?.pages.flatMap(page => page.items) ?? []
  const taxRatesForCustomerCountry = taxRates.filter(
    ({ country }) => country === customerCountry
  )
  const taxCategoryHasTaxRate = taxRatesForCustomerCountry.some(
    taxRate => taxRate.taxCategoryId === taxCategoryId
  )

  const isValid = !isLoading && taxCategoryHasTaxRate

  return {
    valid: isValid,
    provider: TAX_INTEGRATION_PROVIDERS.SEQUENCE,
    taxRatesForCountry: taxRatesForCustomerCountry
  }
}
