import { getIsAQuoteTemplate } from 'Cube/domain/postActionStages/utils/getIsQuoteATemplate'
import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getDeleteLatestQuoteDraftAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (getIsAQuoteTemplate(prevState)) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: { status: prevState.data.common.status }
        }
      ]
    }
  }

  return {
    available: {
      visible:
        !prevState.data.common.isArchived &&
        prevState.data.common.status === CubeStatus.QuoteDraft &&
        !!prevState.data.quote.publishedAt,
      enabled:
        !prevState.data.common.isArchived &&
        prevState.data.common.status === CubeStatus.QuoteDraft &&
        !!prevState.data.quote.publishedAt
    },
    reasons: []
  }
}
