import { TextBlockConfig } from '@sequencehq/quote-content'
import { BlockAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/blockAdapter.types'
import { textContentAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/utils/textContent.adapter'

export const heading3Adapter: BlockAdapter<'heading'> = {
  in: block => {
    const config = block.config as TextBlockConfig

    return [
      {
        type: 'heading',
        content: textContentAdapter.in(config.text),
        props: {
          level: 3,
          textAlignment: config.textAlignment ?? 'left',
          textColor: config.textColor ?? 'default',
          backgroundColor: config.backgroundColor ?? 'default'
        }
      }
    ] as ReturnType<BlockAdapter['in']>
  },
  out: () => block => {
    return {
      blockType: 'heading3',
      config: {
        text: textContentAdapter.out(block.content),
        textAlignment: block.props.textAlignment,
        textColor: block.props.textColor,
        backgroundColor: block.props.backgroundColor
      },
      version: 1
    }
  }
}
