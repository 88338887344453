import { Inspector } from '@sequencehq/core-components'
import {
  LinkQuickbooksCustomerWidget,
  LinkQuickbooksCreditNoteWidget,
  LinkXeroCustomerWidget,
  LinkXeroCreditNoteWidget,
  QuickBooksInspectorItem,
  XeroInspectorItem
} from 'Integrations/index'
import { IntegrationServices, useQuery } from '@sequencehq/api/dist/utils'
import { useCreditNoteEditing } from 'CreditNotes/hooks/useCreditNoteEditing'
import {
  dashboardv99990101Client,
  Dashboardv99990101CommonModels
} from '@sequencehq/api/dashboard/v99990101'
import { AggregatedCreditNote } from 'CreditNotes/types'
import { P } from 'ts-pattern'
import { props } from 'lodash/fp'

const serviceHasLinkedCustomer =
  (service: IntegrationServices) =>
  (
    customerLinks: Dashboardv99990101CommonModels.Customers.Customer['integrationIds']
  ) => {
    return Boolean(customerLinks.find(cl => cl.service === service)?.id)
  }

export const useCreditNoteInspector = (creditNote: AggregatedCreditNote) => {
  const customerIntegrationsQuery = useQuery(
    dashboardv99990101Client.getCustomer,
    {
      id: creditNote.customerId
    },
    {
      select: customer => {
        if (!customer) {
          return null
        }
        return customer.integrationIds
      }
    }
  )

  return {
    customerId: creditNote.customerId,
    creditNoteId: creditNote.id,
    hasLinkedCustomer: customerIntegrationsQuery.data
      ? {
          xero: serviceHasLinkedCustomer('Xero')(
            customerIntegrationsQuery.data
          ),
          quickbooks: serviceHasLinkedCustomer('QuickBooks_Online')(
            customerIntegrationsQuery.data
          ),
          salesforce: serviceHasLinkedCustomer('Salesforce')(
            customerIntegrationsQuery.data
          )
        }
      : {
          xero: false,
          quickbooks: false,
          salesforce: false
        }
  }
}

export const CreditNoteInspector = (props: {
  creditNote: AggregatedCreditNote
}) => {
  const hook = useCreditNoteInspector(props.creditNote)

  return (
    <Inspector>
      <Inspector.ItemGroup>
        <XeroInspectorItem>
          <LinkXeroCustomerWidget customerId={hook.customerId} />
          {hook.hasLinkedCustomer.xero && (
            <LinkXeroCreditNoteWidget creditNoteId={hook.creditNoteId} />
          )}
        </XeroInspectorItem>
        <QuickBooksInspectorItem>
          <LinkQuickbooksCustomerWidget customerId={hook.customerId} />
          {hook.hasLinkedCustomer.quickbooks && (
            <LinkQuickbooksCreditNoteWidget creditNoteId={hook.creditNoteId} />
          )}
        </QuickBooksInspectorItem>
      </Inspector.ItemGroup>
    </Inspector>
  )
}
