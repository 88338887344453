import { getIsAQuoteTemplate } from 'Cube/domain/postActionStages/utils/getIsQuoteATemplate'
import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

const visibleDuringStatuses = [
  CubeStatus.QuoteDraft,
  CubeStatus.ScheduleUnsaved
]

export const getEditCustomerAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (getIsAQuoteTemplate(prevState)) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: { status: prevState.data.common.status }
        }
      ]
    }
  }

  if (prevState.data.quote.publishedAt) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.CantChangeAfterPublish,
          metadata: {}
        }
      ]
    }
  }

  if (!visibleDuringStatuses.includes(prevState.data.common.status)) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: {
            status: prevState.data.common.status
          }
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
