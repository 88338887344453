import { Flex, Text } from '@chakra-ui/react'
import { UsersIcon as UsersIcon24 } from '@heroicons/react/24/solid'
import { Badge } from '@sequencehq/core-components'
import {
  customerAliasOptions,
  SeatSnapshotModel
} from '@sequencehq/core-models'
import { RedRed20, RedRed50 } from '@sequencehq/design-tokens'
import {
  MagicTable,
  MagicTableCell,
  MagicTableCellEmpty,
  useMagicTableInfiniteQuery,
  usePersistMagicTableQueryParams,
  MagicTableFilterConfig,
  useLinkMagicTableWithSearchParams,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import {
  SeatSnapshotsPropFilters,
  SeatSnapshotsView
} from 'modules/Seats/SeatBalances/types'
import { useGetCustomersByAliasByAliasQuery } from 'features/api'
import { ExtractQueryParams } from 'lib/types'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import {
  CalendarIcon,
  CodeBracketSquareIcon,
  CommandLineIcon,
  HashtagIcon,
  IdentificationIcon
} from '@heroicons/react/16/solid'
import { timestampFilterOptions } from 'components/UsageEvents/UsageEvents.tsx'
import { isoTimestampIgnoringTime } from '@sequencehq/utils/src/dates'
import { useSeatMetrics } from 'Seats/SeatMetric/useSeatMetrics'
import Spinner from 'components/Loading'
import { useCustomerNamesByAlias } from 'components/UsageEvents/useCustomerNamesByAlias.tsx'
import { useNavigate } from 'react-router-dom'

export const SeatSnapshotsMagicTable = () => {
  const navigate = useNavigate()

  const { persistMagicTableQueryParams } = usePersistMagicTableQueryParams()

  const { metrics: seatMetrics, loading: loadingSeatMetrics } = useSeatMetrics()

  const {
    isLoading: isLoadingAliases,
    aliases,
    customersWithAliases
  } = useCustomerNamesByAlias()

  const filters: MagicTableFilterConfig<SeatSnapshotsPropFilters>[] = [
    {
      type: 'text',
      paramName: 'customerEventId',
      label: 'Event ID',
      icon: CodeBracketSquareIcon
    },
    {
      type: 'multiSelect',
      paramName: 'seatType',
      options: (seatMetrics ?? []).map(seatMetric => ({
        label: seatMetric.label,
        value: seatMetric.seatType
      })),
      format: value => <Text>{value}</Text>,
      label: 'Seat metric',
      icon: CommandLineIcon
    },
    {
      type: 'multiSelectAliased',
      paramName: 'customerName',
      paramNameAliasOf: 'customerAlias',
      options: (customersWithAliases ?? []).map(customer => ({
        label: customer.customerName,
        value: customer.aliases.join(',')
      })),
      format: value => (
        <Text>
          {
            (customersWithAliases ?? []).find(
              customer => customer.aliases.join(',') === value
            )?.customerName
          }
        </Text>
      ),
      label: 'Customer',
      icon: IdentificationIcon
    },
    {
      type: 'multiSelect',
      paramName: 'customerAlias',
      options: customerAliasOptions(aliases ?? []),
      format: value => {
        const alias = (aliases ?? []).find(
          ({ value: customerAlias }) => customerAlias === value
        )
        const aliasValue = alias?.value ?? '-'
        return <Badge>{aliasValue}</Badge>
      },
      label: 'Customer alias',
      icon: HashtagIcon
    },
    {
      type: 'date',
      paramName: 'eventTimestamp',
      paramNameBefore: 'eventTimestampBefore',
      paramNameAfter: 'eventTimestampAfter',
      optionsBefore: timestampFilterOptions,
      optionsAfter: timestampFilterOptions,
      format: (_, label) => label,
      label: 'Timestamp',
      stringifyDate: (date: Date) => {
        return isoTimestampIgnoringTime(date, 'start')
      },
      stringifyDateBefore: (date: Date) =>
        isoTimestampIgnoringTime(date, 'end'),
      stringifyDateAfter: (date: Date) =>
        isoTimestampIgnoringTime(date, 'start'),
      icon: CalendarIcon
    }
  ]

  const { activeFilters, onChangeActiveFilters, sortBy, onChangeSortBy } =
    useLinkMagicTableWithSearchParams(filters)

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getSeatEvents,
    filters,
    activeFilters,
    sortBy
  )

  if (
    loadingSeatMetrics ||
    isLoadingAliases ||
    !seatMetrics ||
    !customersWithAliases ||
    !aliases
  ) {
    return <Spinner></Spinner>
  }

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<SeatSnapshotsView>
          activeFilters={activeFilters}
          onChangeActiveFilters={onChangeActiveFilters}
          sortBy={sortBy}
          onChangeSortBy={onChangeSortBy}
          entityNamePlural="seat events"
          sequenceUserId={userId}
          infiniteQuery={infiniteQuery}
          rowPath={(row: SeatSnapshotsView['model']) =>
            persistMagicTableQueryParams(`/seat-events/${row.id}`)
          }
          columns={[
            {
              id: 'total',
              accessorKey: 'total',
              header: 'Total Seats',
              cell: value => {
                const total = value.getValue<SeatSnapshotModel['total']>()
                return <MagicTableCell text={`${total ?? 0}`} />
              }
            },
            {
              id: 'customer',
              accessorKey: 'customerAlias',
              header: 'Customer',
              cell: value => (
                <MagicTableAutoLoader<
                  ExtractQueryParams<typeof useGetCustomersByAliasByAliasQuery>,
                  { customerId?: string }
                >
                  queryParams={{
                    alias: value.getValue<SeatSnapshotModel['customerAlias']>()
                  }}
                  useQuery={useGetCustomersByAliasByAliasQuery}
                  extract={customer => ({
                    customerId: customer?.id
                  })}
                >
                  {({ customerId }) => {
                    if (!customerId) {
                      return (
                        <MagicTableCell
                          text="Alias not mapped to Customer"
                          borderColor={RedRed20}
                          textColor={RedRed50}
                        />
                      )
                    }

                    return (
                      <Flex
                        alignItems="center"
                        justifyContent="space-between"
                        height="40px"
                        pl="6px"
                      >
                        <CustomerPreviewCardPill
                          customerId={customerId}
                          variant="ghost"
                        />
                      </Flex>
                    )
                  }}
                </MagicTableAutoLoader>
              )
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat type',
              cell: value => (
                <MagicTableCellEmpty>
                  <Flex overflow="hidden">
                    <Badge sentiment="neutral">
                      {value.getValue<SeatSnapshotModel['seatType']>()}
                    </Badge>
                  </Flex>
                </MagicTableCellEmpty>
              ),
              enableSorting: true
            },
            {
              id: 'id',
              accessorKey: 'id',
              header: 'Event ID',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<SeatSnapshotModel['id']>()}
                />
              )
            },
            {
              id: 'snapshotTimestamp',
              accessorKey: 'eventTimestamp',
              header: 'Timestamp (UTC)',
              cell: value => (
                <MagicTableCell
                  text={value.getValue<
                    DashboardApi20240730.GetSeatEvents.SeatEvent['eventTimestamp']
                  >()}
                />
              ),
              enableSorting: true
            }
          ]}
          emptyStateUnfiltered={entityNamePlural => (
            <MagicTableEmptyState
              entityNamePlural={entityNamePlural}
              imageSrc="/img/empty/gauge.png"
              learnMoreText="Leverage comprehensive seat-based billing infrastructure"
              learnMoreHref="https://docs.sequencehq.com/seats/seat-updates"
              buttonNew={{
                label: 'New seat event',
                onClick: () => navigate('/seat-events/new')
              }}
            />
          )}
          emptyStateFiltered={(entityNamePlural, clearFilters) => (
            <MagicTableFilterEmptyState
              entityNamePlural={entityNamePlural}
              entityIcon={UsersIcon24}
              actionClearFilters={clearFilters}
              actionViewAll={() => navigate('/seat-events')}
              variant="TABLE"
            />
          )}
          filters={filters}
        />
      )}
    </CurrentUserId>
  )
}
