import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { SequenceMenuItem } from '@sequencehq/core-components'
import { useNotifications } from 'lib/hooks/useNotifications'

export const useCreateQuoteFromTemplate = (props: {
  quoteTemplateId: string
  onSuccess?: (newQuoteId: string) => void
}) => {
  const { displayNotification, hideNotification } = useNotifications()

  return {
    createQuoteFromTemplate: async () => {
      const pendingNewQuoteCreationNotificationId = displayNotification(
        'Creating quote from template'
      )
      const newQuoteRes =
        await dashboard20240730Client.postCreateQuoteFromTemplate({
          id: props.quoteTemplateId
        })

      hideNotification(pendingNewQuoteCreationNotificationId)

      if (!newQuoteRes || !newQuoteRes.data?.id) {
        displayNotification('Failed to create quote from template', {
          type: 'error'
        })
        return false
      }

      displayNotification('Quote created', {
        type: 'success'
      })
      props.onSuccess?.(newQuoteRes.data?.id)
      return true
    },
    creationInProgress: false
  }
}

export const CreateQuoteFromTemplateMenuItem = (props: {
  quoteTemplateId: string
  disabled?: boolean
  onSuccess?: (newQuoteId: string) => void
}) => {
  const { createQuoteFromTemplate } = useCreateQuoteFromTemplate({
    quoteTemplateId: props.quoteTemplateId,
    onSuccess: props.onSuccess
  })
  return (
    <SequenceMenuItem
      uuid="create-quote-from-template"
      label="Create quote from template"
      isDisabled={props.disabled}
      onClick={createQuoteFromTemplate}
    />
  )
}
