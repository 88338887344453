import * as Sentry from '@sentry/react'
import { baseApi } from 'features/api/baseApi'
import { downloadBlob } from 'lib/downloadBlob'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useCallback } from 'react'

type UseInvoicesCsvExport = ({
  filtersApiQueryParams
}: {
  filtersApiQueryParams: string
}) => {
  download: {
    available: boolean
    disabled: boolean
    onClick: () => void
  }
}

export const useInvoicesCsvExport: UseInvoicesCsvExport = ({
  filtersApiQueryParams
}) => {
  const notifications = useNotifications()

  const [getDownload] =
    baseApi.endpoints.getInvoiceCsvExport.useLazyQuerySubscription()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification(
      'Preparing download - this may take a few minutes',
      {
        id: 'download-csv',
        type: 'neutral',
        isClosable: false
      }
    )
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const downloadCsvFile = useCallback(async () => {
    try {
      const fulfilled = await getDownload(filtersApiQueryParams).unwrap()

      downloadBlob('invoice-export.csv')(
        new Blob([fulfilled], { type: 'text/csv' })
      )

      showToastSuccess()
    } catch (e) {
      showToastError()
      Sentry.captureException(e)
    }
  }, [getDownload, showToastError, showToastSuccess, filtersApiQueryParams])

  const downloadCsv = useCallback(() => {
    showToastPreparing()

    void downloadCsvFile()
  }, [showToastPreparing, downloadCsvFile])

  return {
    download: {
      available: true,
      disabled: false,
      onClick: () => downloadCsv()
    }
  }
}
