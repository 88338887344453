import { useCallback, useEffect, useMemo, useState } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications'
import { dequal } from 'dequal'
import { useFlags } from 'launchdarkly-react-client-sdk'
import * as Sentry from '@sentry/react'
import { CreditNotesSettings } from 'modules/Settings/domain/settings.types'
import { INITIAL_CREDIT_NOTES_SETTINGS } from 'modules/Settings/domain/settings.constants'

enum LoadingState {
  UNINITIALIZED = 'UNINITIALIZED',
  LOADING = 'LOADING',
  READY = 'READY',
  ERROR = 'ERROR'
}

type UseCreditNotesSettings = () => {
  ready: boolean
  submitting: boolean
  hasChanges: boolean
  onSave: () => void
  fields: {
    generateCashCreditGrant: {
      hidden: boolean
      value: boolean
      onChange: (generateCashCreditGrant: boolean) => void
    }
  }
}

export const useCreditNotesSettings: UseCreditNotesSettings = () => {
  const flags = useFlags()
  const notifications = useNotifications()

  const [loadingState, setLoadingState] = useState<LoadingState>(
    LoadingState.UNINITIALIZED
  )
  const [submitting, setSubmitting] = useState<boolean>(false)

  const [initialData, setInitialData] = useState<CreditNotesSettings>(
    INITIAL_CREDIT_NOTES_SETTINGS
  )
  const [data, setData] = useState<CreditNotesSettings>(
    INITIAL_CREDIT_NOTES_SETTINGS
  )

  useEffect(() => {
    const loadData = async () => {
      try {
        setLoadingState(LoadingState.LOADING)
        const [creditNotesSettings] = await Promise.all([
          dashboard20240730Client.getCreditNotesSettings(undefined)
        ])

        const hasError = [creditNotesSettings.error].some(Boolean)

        if (hasError || creditNotesSettings.data === null) {
          throw new Error('Unable to fetch credit notes settings')
        }

        setInitialData(creditNotesSettings.data)
        setData(creditNotesSettings.data)
        setLoadingState(LoadingState.READY)
      } catch (e) {
        notifications.displayNotification(
          'Unable to fetch credit notes settings',
          {
            type: 'error'
          }
        )

        setLoadingState(LoadingState.ERROR)
        Sentry.captureException(e)
      }
    }

    void loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSave = useCallback(() => {
    const save = async () => {
      notifications.displayNotification('Saving settings...', {
        type: 'info'
      })

      try {
        setSubmitting(true)

        if (!dequal(initialData, data)) {
          const result = await dashboard20240730Client.putCreditNotesSettings({
            body: data
          })

          if (result.error) {
            throw new Error('Unable to update credit notes settings')
          }
        }

        notifications.displayNotification('Settings updated', {
          type: 'success'
        })
      } catch (e) {
        notifications.displayNotification(
          'Unable to update credit notes settings',
          {
            type: 'error'
          }
        )

        Sentry.captureException(e)
      }

      setSubmitting(false)
      setInitialData(data)
    }

    void save()
  }, [data, initialData, notifications])

  const onChangeGenerateCashCreditGrant = useCallback(
    (generateGrant: boolean) => {
      setData(prev => ({
        ...prev,
        generateCashCreditGrant: generateGrant ? 'ENABLED' : 'DISABLED'
      }))
    },
    []
  )

  const fields = useMemo(() => {
    return {
      generateCashCreditGrant: {
        value: data.generateCashCreditGrant === 'ENABLED',
        hidden: false,
        onChange: onChangeGenerateCashCreditGrant
      }
    }
  }, [data, onChangeGenerateCashCreditGrant])

  const hasChanges = useMemo(() => {
    return loadingState === LoadingState.READY && !dequal(initialData, data)
  }, [data, initialData, loadingState])

  return {
    ready: loadingState === LoadingState.READY,
    submitting,
    hasChanges,
    onSave,
    fields
  }
}
