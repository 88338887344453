import { ListBlockConfigV3 } from '@sequencehq/quote-content'
import { BlockAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/blockAdapter.types'
import { textContentAdapter } from 'modules/Cube/view/layouts/quote/QuoteEditorContent/ContentEditor/adapters/blocks/utils/textContent.adapter'

export const bulletListAdapterV3: BlockAdapter<'bulletListItem'> = {
  in: block => {
    const config = block.config as ListBlockConfigV3

    return config.items.map(item => {
      return {
        type: 'bulletListItem',
        content: textContentAdapter.in(item.text),
        props: {
          backgroundColor: item.backgroundColor ?? 'default'
        }
      }
    }) as ReturnType<BlockAdapter['in']>
  },
  out: () => block => {
    const items =
      block.children.length > 0
        ? (block.children
            .map(child => {
              if (!child.content) {
                return
              }

              return {
                text: textContentAdapter.out(child.content),
                backgroundColor: child.props.backgroundColor
              }
            })
            .filter(Boolean) as ListBlockConfigV3['items'])
        : ([
            {
              text: textContentAdapter.out(block.content),
              backgroundColor: block.props.backgroundColor
            }
          ] as ListBlockConfigV3['items'])

    return {
      blockType: 'bulletList',
      config: { items },
      version: 3
    }
  }
}
