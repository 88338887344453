import { Button, SimpleDialog } from '@sequencehq/core-components'
import { useAtom } from 'jotai'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { FC, ReactNode } from 'react'
import { loadableExtractionResultAtom, performExtractionAtom } from '../atoms'
import { GreyGrey10, Lato13Regular } from '@sequencehq/design-tokens'
import { Flex, Spinner, Text } from '@chakra-ui/react'
import { SparklesIcon } from '@heroicons/react/16/solid'

export const ExtractButton: FC<{ assetId: string }> = ({ assetId }) => {
  const flags = useFlags()
  const [, performExtraction] = useAtom(performExtractionAtom)
  const [{ state: extractionState }] = useAtom(loadableExtractionResultAtom)

  if (!flags.useContractIntakePrototype) {
    return null
  }

  const loading = extractionState === 'loading'

  return (
    <Flex
      backgroundColor={GreyGrey10}
      padding="8px"
      alignItems="center"
      m={2}
      borderRadius={4}
    >
      <Text flex={1} {...Lato13Regular}>
        AI extraction available
      </Text>
      <ConfirmExtractionDialog
        trigger={
          <Button
            disabled={loading}
            leadingIcon={
              loading ? <Spinner size="sm" /> : <SparklesIcon width="16px" />
            }
          >
            Extract with AI
          </Button>
        }
        onConfirm={() => performExtraction(assetId)}
      />
    </Flex>
  )
}

const ConfirmExtractionDialog: FC<{
  trigger: ReactNode
  onConfirm: () => void
}> = ({ trigger, onConfirm }) => {
  return (
    <SimpleDialog
      trigger={trigger}
      onConfirm={onConfirm}
      title="Extract with AI"
      confirmButtonText="Extract"
      submitButtonProps={{ trailingIcon: <SparklesIcon width="16px" /> }}
    >
      You're about to extract data from the attached document. This action will
      replace the current schedule settings.
    </SimpleDialog>
  )
}
