import { Box, Flex, Image, Link, Text } from '@chakra-ui/react'
import {
  GreyGrey70,
  GreyGrey90,
  IndigoIndigo50,
  Lato13Bold,
  Lato13Regular,
  Lato14Bold
} from '@sequencehq/design-tokens'
import { FC } from 'react'

import equalsLogo from 'Integrations/integrationsConfig/equals/equalsLogo.svg'

export const MoreOutOfSequence = () => {
  return (
    <Flex direction="column" paddingY="24px">
      <Flex alignItems="baseline">
        <Text textStyle="sectionHeader" {...Lato14Bold}>
          Get more out of Sequence
        </Text>
      </Flex>

      <Flex gap="6px" minHeight="142px">
        <Card
          title="ARR Dashboards, powered by Equals"
          description="Modern billing meets modern revenue analytics."
          ctaLabel="Get started"
          ctaHref="https://docs.sequencehq.com/integrations/equals#equals"
          icon={
            <Image
              src={equalsLogo}
              alt="Equals logo"
              height="24px"
              width="24px"
            />
          }
        />

        <Card
          title="Discover what’s new in Sequence"
          description="We have a track record of shipping fast and often."
          ctaLabel="View changelog"
          ctaHref="https://www.sequencehq.com/changelog"
          icon={
            <Image
              src="/img/sequence-circle-logo.png"
              alt="Equals logo"
              height="24px"
              width="24px"
            />
          }
        />
      </Flex>
    </Flex>
  )
}

interface CardProps {
  icon: React.ReactNode
  title: string
  description: string
  ctaHref: string
  ctaLabel: string
}

const Card: FC<CardProps> = ({
  icon,
  title,
  description,
  ctaHref,
  ctaLabel
}) => {
  return (
    <Flex width="full" gap="8px" paddingY="16px">
      <Box width="24px" height="24px" flex="0 0 24px">
        {icon}
      </Box>
      <Flex direction="column" width="full" gap="8px">
        <Text textStyle="sectionHeader" {...Lato13Bold} color={GreyGrey90}>
          {title}
        </Text>

        <Text {...Lato13Regular} color={GreyGrey70}>
          {description}
        </Text>

        <Link
          href={ctaHref}
          color="indigo.50"
          _hover={{ textDecoration: 'none' }}
          display="flex"
          gap="4px"
          width="fit-content"
          isExternal
          target="_blank"
          paddingTop="4px"
          alignItems="center"
        >
          <Text {...Lato13Regular} color={IndigoIndigo50}>
            {ctaLabel}
          </Text>
        </Link>
      </Flex>
    </Flex>
  )
}
