import { Box } from '@chakra-ui/react'
import { ProductModel } from '@sequencehq/core-models'
import {
  ControlEmptyState,
  filterOptionGroupsOnGroupNameOnly,
  InputSelectControl
} from '@sequencehq/core-components'
import { useSearchForProduct } from 'Cube/view/common/widgets/Pricing/Phase/SearchForProduct/useSearchForProduct'
import { Phase } from 'modules/Cube/domain/cube.domain.types'
import { FC } from 'react'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Link } from 'react-router-dom'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'

type SearchForProductProps = {
  phaseId: Phase['id']
  onSelection: (productId: ProductModel['id']) => void
  onBlur: () => void
}

export const SearchForProduct: FC<SearchForProductProps> = props => {
  const {
    productOptionsWithListPrices,
    onSelection,
    onAddNew,
    showAllProductsAddedStateWhenEmpty
  } = useSearchForProduct({
    onSelection: props.onSelection,
    phaseId: props.phaseId
  })

  const emptyState = showAllProductsAddedStateWhenEmpty ? (
    <AllProductsAddedState />
  ) : (
    <EmptyState onClick={onAddNew} />
  )

  return (
    <Box position="relative" width="376px">
      <InputSelectControl
        filterOptionGroups={filterOptionGroupsOnGroupNameOnly}
        initiallyOpen
        matchWidth
        data-testid="phase.searchForProduct"
        options={productOptionsWithListPrices}
        onChange={onSelection}
        placeholder="Find or create a new price"
        onToggle={(isOpen: boolean) => {
          requestAnimationFrame(() => {
            !isOpen && props.onBlur()
          })
        }}
        emptyState={emptyState}
        noResultsState={<NoResultsState />}
      />
    </Box>
  )
}

const EmptyState: FC<{ onClick: () => void }> = ({ onClick }) => {
  return (
    <ControlEmptyState
      title="Create your first product"
      content="You have no products configured. Head over to the product catalogue to create your first product"
      button={{
        text: 'Add new',
        onClick
      }}
    />
  )
}

const AllProductsAddedState = () => {
  return (
    <ControlEmptyState
      title="No products available"
      content="All your active products are currently used in this phase."
    />
  )
}

const NoResultsState = () => {
  return (
    <ControlEmptyState
      title="No results"
      content={
        <div>
          Head over to the{' '}
          <Link
            to="/products"
            target="_blank"
            style={{ color: IndigoIndigo50 }}
          >
            product catalogue
          </Link>{' '}
          to create and manage your products and list prices.
        </div>
      }
    />
  )
}
