import { SequenceMenuItem, SimpleDialog } from '@sequencehq/core-components'
import { RedRed60 } from '@sequencehq/design-tokens'
import { FC, useCallback } from 'react'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useMutation } from '@sequencehq/api'
import { useNotifications } from 'lib/hooks/useNotifications'
import { useNavigate } from 'react-router-dom'

export const DeleteSavedViewDialog: FC<{
  savedViewId: string
  onCancel: () => void
}> = ({ savedViewId, onCancel }) => {
  const { displayNotification } = useNotifications()
  const navigate = useNavigate()

  const deleteSavedViewMutator = useMutation(
    dashboard20240730Client.deleteSavedView
  )

  const onConfirm = useCallback(async () => {
    const deleteResult = await deleteSavedViewMutator.mutateAsync({
      id: savedViewId
    })

    if (!deleteResult) {
      displayNotification('Failed to delete saved view', { type: 'error' })
      return
    }

    displayNotification('Saved view deleted', { type: 'success' })
    navigate('/home')
  }, [
    deleteSavedViewMutator.mutateAsync,
    displayNotification,
    navigate,
    savedViewId
  ])

  return (
    <SimpleDialog
      trigger={
        <SequenceMenuItem
          label="Delete saved view"
          uuid="delete-saved-view"
          style={{ color: RedRed60 }}
        />
      }
      onConfirm={onConfirm}
      onCancel={onCancel}
      title="Delete saved view"
      confirmButtonText="Delete"
      destructive
      isSubmitting={deleteSavedViewMutator.isPending}
    >
      This will delete the saved view immediately. This action cannot
      be&nbsp;undone.
    </SimpleDialog>
  )
}
