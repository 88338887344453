import { Currency } from '@sequencehq/core-models'
import { DiscountEditorDiscount } from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { Discount } from 'modules/Cube/domain/cube.domain.types'

export const discountEditorCubeAdapter = {
  in: (
    discountModel: Discount,
    {
      currency
    }: {
      currency: Currency
    }
  ): DiscountEditorDiscount => {
    return {
      id: discountModel.id,
      name: discountModel.message,
      currency,
      nominalValue:
        discountModel.discountCalculationType === 'NOMINAL'
          ? discountModel.amount.toFixed(2)
          : '0.00',
      percentageValue:
        discountModel.discountCalculationType === 'PERCENTAGE'
          ? discountModel.amount.toFixed(2)
          : '0.00',
      discountCalculationType: discountModel.discountCalculationType,
      priceIds: discountModel.priceIds,
      applyTo: discountModel.priceIds.length ? 'SPECIFIC' : 'ALL',
      seatDiscountType: discountModel.seatDiscountType
    }
  },
  out: (internalDiscount: DiscountEditorDiscount): Discount => {
    return {
      id: internalDiscount.id,
      message: internalDiscount.name,
      discountCalculationType: internalDiscount.discountCalculationType,
      amount:
        internalDiscount.discountCalculationType === 'NOMINAL'
          ? parseFloat(internalDiscount.nominalValue)
          : parseFloat(internalDiscount.percentageValue),
      priceIds:
        internalDiscount.applyTo === 'SPECIFIC'
          ? internalDiscount.priceIds
          : [],
      applyToAllPrices: internalDiscount.applyTo === 'ALL',
      seatDiscountType: internalDiscount.seatDiscountType
    }
  }
}
