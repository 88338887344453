import { useCallback, useEffect, useMemo, useState } from 'react'
import { MerchantModel } from '@sequencehq/core-models'
import { useLazyGetMerchantForSequenceAccountQuery } from 'features/api'
import { LoaderError } from 'InvoiceEditor/domainManagement/invoiceEditor.types'
import * as Sentry from '@sentry/react'

type UseLoadMerchant = () => {
  loading: boolean
  error?: LoaderError
  data?: {
    merchant: MerchantModel
  }
}

export const useLoadMerchant: UseLoadMerchant = () => {
  const [merchant, setMerchant] = useState<MerchantModel | undefined>()
  const [fetchMerchant, { isLoading, error }] =
    useLazyGetMerchantForSequenceAccountQuery()

  const loadMerchant = useCallback(
    async () => fetchMerchant().then(response => response.data?.value()),
    [fetchMerchant]
  )

  useEffect(() => {
    loadMerchant()
      .then(loadedMerchant => {
        setMerchant(loadedMerchant)
      })
      .catch(e => {
        Sentry.captureException(e)
      })
  }, [loadMerchant])

  const data = useMemo(() => {
    if (isLoading || !merchant) {
      return
    }

    return {
      merchant
    }
  }, [isLoading, merchant])

  return {
    loading: isLoading,
    error,
    data
  }
}
