import { DiscountEditorDiscount } from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'

export const NEW_DISCOUNT_PATTERN = /^new-discount::/

export const INITIAL_DISCOUNT: Omit<DiscountEditorDiscount, 'id'> = {
  name: 'New discount',
  currency: 'USD',
  discountCalculationType: 'PERCENTAGE',
  nominalValue: '0.00',
  percentageValue: '0.00',
  priceIds: [],
  applyTo: 'SPECIFIC',
  seatDiscountType: 'ALL_SEATS'
}
