import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { Skeleton } from '@sequencehq/core-components'
import { GreyGrey30 } from '@sequencehq/design-tokens'

export const QuoteLoadingSkeleton = () => {
  return (
    <Grid
      data-testid="cube.loadingLayout"
      templateAreas={`
  "header header header"
  "content inspector-content inspector"
  `}
      templateRows={`48px 1fr`}
      templateColumns={`1fr 320px 48px`}
      width="100%"
      height="100vh"
      overflow="auto"
    >
      <GridItem
        gridArea="header"
        borderBottom={`1px solid ${GreyGrey30}`}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Skeleton height="24px" width="220px" />
      </GridItem>
      <GridItem
        gridArea="inspector-content"
        borderLeft={`1px solid ${GreyGrey30}`}
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        padding="16px"
        gap="8px"
      >
        <Skeleton height="16px" width="140px" />
        <Skeleton height="160px" width="100%" />
      </GridItem>
      <GridItem
        gridArea="inspector"
        borderLeft={`1px solid ${GreyGrey30}`}
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="8px"
        gap="2px"
      >
        <Skeleton height="32px" width="32px" />
        <Skeleton height="32px" width="32px" />
        <Skeleton height="32px" width="32px" />
      </GridItem>
      <GridItem
        gridArea="content"
        display="flex"
        alignItems="center"
        flexDirection="column"
        paddingTop="72px"
      >
        <Flex flexDirection="column" alignContent="flex-start" width="812px">
          <Box marginBottom="4px">
            <Skeleton height="40px" width="220px" />
          </Box>
          <Box marginBottom="16px">
            <Skeleton height="16px" width="400px" />
          </Box>
          <Box marginBottom="16px">
            <Skeleton height="28px" width="100%" />
          </Box>
          <Flex flexDirection="column" alignContent="flex-start" gap="8px">
            <Skeleton height="160px" width="100%" />
            <Skeleton height="20px" width="80%" />
            <Skeleton height="20px" width="60%" />
            <Skeleton height="20px" width="70%" />
            <Skeleton height="20px" width="80%" />
            <Skeleton height="20px" width="60%" />
            <Skeleton height="20px" width="70%" />
          </Flex>
        </Flex>
      </GridItem>
    </Grid>
  )
}
