import { Currency } from '@sequencehq/api/commonEnums'
import { format } from '@sequencehq/utils/dates'
import { v1ApiQuote } from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders/useLoadQuote'
import { QuoteMutationInterface } from 'modules/Cube/communication/external/quotes.api.v1/ports/entitySaving/useSaveQuote'
import { NEW_PRODUCT_PATTERN } from 'modules/Cube/domain/cube.constants'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import {
  CubeReducerState,
  Product,
  QuoteContact
} from 'modules/Cube/domain/cube.domain.types'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { phasesToQuotePhases } from '../../common.api.v1/adapters/common.adapters.out'

const commonFieldsToQuoteRequest = (
  data: CubeReducerState['data']['common']
): Pick<
  v1ApiQuote,
  | 'id'
  | 'title'
  | 'customerId'
  | 'currency'
  | 'quoteNumber'
  | 'status'
  | 'preliminaryBillingStartDate'
> => {
  return {
    id: data.id,
    title: data.title,
    customerId: data.customerId ? data.customerId : undefined,
    currency: data.currency as Currency,
    quoteNumber: data.alias,
    status: quoteStatusAdapter.out(data.status) as v1ApiQuote['status'],
    preliminaryBillingStartDate:
      data.startDate && format(data.startDate, 'yyyy-MM-dd')
  }
}

export const fieldsToQuoteRequest = (
  data: CubeReducerState['data']['quote']
): Pick<
  QuoteMutationInterface,
  | 'dealType'
  | 'expiryDays'
  | 'contactIds'
  | 'salesforceOpportunityId'
  | 'attachmentAssetIds'
> => {
  return {
    dealType: data.dealType,
    expiryDays: data.expiresIn?.days,
    contactIds: data.contacts.map(contact => contact.contactId),
    salesforceOpportunityId: data.salesforceOpportunityId ?? null,
    attachmentAssetIds: data.attachmentAssets.map(asset => asset.id)
  }
}

export const extractNewProducts = (
  products: CubeReducerState['data']['products']
): Product[] =>
  Object.values(products).filter(product =>
    product.id.match(NEW_PRODUCT_PATTERN)
  )

export const domainToApi = (
  data: CubeDomainInterface['queries']['rawData']['data']
): QuoteMutationInterface => {
  return {
    ...commonFieldsToQuoteRequest(data.common),
    ...fieldsToQuoteRequest(data.quote),
    phases: phasesToQuotePhases(data),
    presentation: data.presentation,
    products: {
      new: extractNewProducts(data.products)
    }
  }
}

export const quoteContactToContactOption: (contact: QuoteContact) => {
  name: string
  email: string
} = contact => {
  return {
    name: contact.name ? contact.name : contact.email,
    email: contact.name ? contact.email : ''
  }
}
