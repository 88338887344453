import { useMutation } from '@sequencehq/api'
import { useBulkActions } from './useBulkActions'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCallback } from 'react'

export const useMarkCompletedBulkActionsAsReviewed = () => {
  const { bulkActions } = useBulkActions()

  const reviewBulkAction = useMutation(
    dashboard20240730Client.postReviewBulkAction
  )

  const bulkActionsToMarkAsReviewed = bulkActions.filter(
    bulkAction => bulkAction.status === 'COMPLETED' && !bulkAction.reviewedAt
  )

  return useCallback(() => {
    for (const bulkAction of bulkActionsToMarkAsReviewed) {
      reviewBulkAction.mutate({ id: bulkAction.id })
    }
  }, [bulkActionsToMarkAsReviewed, reviewBulkAction])
}
