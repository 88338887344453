import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  ResolvedPhase,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

const editableDuringStatuses = [
  CubeStatus.QuoteTemplate,
  CubeStatus.QuoteDraft,
  CubeStatus.QuotePublished,
  CubeStatus.QuoteReadyToSign,
  CubeStatus.ScheduleUnsaved,
  CubeStatus.ScheduleDraft,
  CubeStatus.SchedulePending,
  CubeStatus.ScheduleActive
]

export const getAddPhaseAvailable =
  (ctx: { orderedPhases: ResolvedPhase[] }) =>
  (prevState: CubeReducerState): VisibleEnabledFeatureAvailable => {
    if (prevState.data.common.isArchived) {
      return {
        available: {
          visible: false,
          enabled: false
        },
        reasons: [
          {
            reasonType: DisabledReasonType.CantChangeWhenArchived,
            metadata: {}
          }
        ]
      }
    }

    if (!editableDuringStatuses.includes(prevState.data.common.status)) {
      return {
        available: {
          visible: false,
          enabled: false
        },
        reasons: [
          {
            reasonType: DisabledReasonType.InvalidStatus,
            metadata: {
              status: prevState.data.common.status
            }
          }
        ]
      }
    }

    const finalPhase = ctx.orderedPhases[ctx.orderedPhases.length - 1]
    const finalPhaseHasPrices = finalPhase?.prices.length > 0

    if (!finalPhaseHasPrices) {
      return {
        available: {
          visible: true,
          enabled: false
        },
        reasons: [
          {
            reasonType: DisabledReasonType.NoValidPrices,
            metadata: {
              phase: ctx.orderedPhases[ctx.orderedPhases.length - 1]
            }
          }
        ]
      }
    }

    const finalPhaseIsOpenEnded = Boolean(
      finalPhase.dates.duration === 'OPEN_ENDED'
    )

    if (finalPhaseIsOpenEnded) {
      return {
        available: {
          visible: true,
          enabled: false
        },
        reasons: [
          {
            reasonType: DisabledReasonType.InvalidDuration,
            metadata: {
              phase: ctx.orderedPhases[ctx.orderedPhases.length - 1]
            }
          }
        ]
      }
    }

    return {
      available: {
        visible: true,
        enabled: true
      },
      reasons: []
    }
  }
