import * as Sentry from '@sentry/react'
import { downloadBlob } from 'lib/downloadBlob.ts'
import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { useCallback } from 'react'
import { ApiResponse } from '@sequencehq/api/src/utils/createBaseApi.ts'

type UseCsvExport = (props: {
  getExport: (queryParams: URLSearchParams) => ApiResponse<Blob>
  fileName: string
}) => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (queryParams: URLSearchParams) => void
  }
}

export const useCsvExport: UseCsvExport = ({ getExport, fileName }) => {
  const notifications = useNotifications()

  /**
   * Toasts
   */
  const showToastPreparing = useCallback(() => {
    notifications.displayNotification('Preparing download', {
      id: 'download-csv',
      type: 'neutral',
      isClosable: false
    })
  }, [notifications])

  const showToastSuccess = useCallback(() => {
    notifications.displayNotification('Download ready', {
      id: 'download-csv-success',
      type: 'success',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const showToastError = useCallback(() => {
    notifications.displayNotification('CSV download failed', {
      id: 'download-csv-error',
      type: 'error',
      isClosable: false,
      duration: 3000
    })
  }, [notifications])

  const downloadCsvFile = useCallback(
    async (queryParams: URLSearchParams) => {
      try {
        const response = await getExport(queryParams)

        if (response.data !== null) {
          downloadBlob(fileName)(
            new Blob([response.data], { type: 'text/csv' })
          )

          showToastSuccess()
        }
      } catch (e) {
        showToastError()
        Sentry.captureException(e)
      }
    },
    [showToastError, showToastSuccess]
  )

  const downloadCsv = useCallback(
    (queryParams: URLSearchParams) => {
      showToastPreparing()

      void downloadCsvFile(queryParams)
    },
    [showToastPreparing, downloadCsvFile]
  )

  return {
    download: {
      available: true,
      disabled: false,
      onClick: queryParams => downloadCsv(queryParams)
    }
  }
}
