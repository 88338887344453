import { useCallback, useEffect, useMemo, useState } from 'react'

import { Text } from '@chakra-ui/react'
import CurrencyPoundIcon from '@heroicons/react/16/solid/CurrencyPoundIcon'
import ViewColumnsIcon from '@heroicons/react/16/solid/ViewColumnsIcon'
import CalendarIcon from '@heroicons/react/16/solid/CalendarIcon'
import IdentificationIcon from '@heroicons/react/16/solid/IdentificationIcon'

import { Badge } from '@sequencehq/core-components'
import {
  InvoicePaymentStatus,
  InvoiceStatus,
  toInvoicePaymentStatusBadgeProps,
  toInvoiceStatusBadgeProps
} from '@sequencehq/core-models'
import {
  ActiveFilter,
  MagicTableFilterConfig,
  magicTableToApiQueryParams,
  useLinkMagicTableWithSearchParams
} from '@sequencehq/tables'
import { formatISODate } from '@sequencehq/formatters'

import { InvoicesFilters } from '../types'
import {
  dueAfterFilterOptions,
  dueBeforeFilterOptions,
  invoiceAfterFilterOptions,
  invoiceBeforeFilterOptions,
  paymentStatusFilterOptions,
  sentFilterOptions,
  statusFilterOptions
} from './filters'
import { useEnabledCurrencies } from 'components/CurrencySettings/useCurrencies'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'

type UseInvoicesMagicTable = ({
  forcedActiveFilters,
  onFiltersApiQueryParamsChanged
}: {
  forcedActiveFilters?: ActiveFilter[]
  onFiltersApiQueryParamsChanged: (newParams: string) => void
}) => {
  isLoading: boolean
  filters: MagicTableFilterConfig<InvoicesFilters>[]
  activeFilters: ActiveFilter[]
  sortBy: string | null
  onChangeActiveFilters: (newActiveFilters: ActiveFilter[]) => void
  onChangeSortBy: (sortBy: string | null) => void
}

export const useInvoicesMagicTable: UseInvoicesMagicTable = ({
  forcedActiveFilters = [],
  onFiltersApiQueryParamsChanged
}) => {
  const enabledCurrencyRes = useEnabledCurrencies()

  const currencyFilterOptions = useMemo(() => {
    if (enabledCurrencyRes.status === 'SUCCESS') {
      return enabledCurrencyRes.enabledCurrencies.map(currency => ({
        value: currency,
        label: currency
      }))
    }
    return []
  }, [enabledCurrencyRes])

  const isLoading = enabledCurrencyRes.status !== 'SUCCESS'

  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()

  const filtersConfig: MagicTableFilterConfig<InvoicesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options: [],
      optionsFunc: fetchCustomerNameOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'invoiceStatus',
      options: statusFilterOptions(),
      format: status => (
        <Badge
          {...toInvoiceStatusBadgeProps({
            status: status as InvoiceStatus
          })}
        />
      ),
      label: 'Status',
      icon: ViewColumnsIcon,
      optionsSortFn: () => 0
    },
    {
      type: 'multiSelect',
      paramName: 'invoicePaymentStatus',
      options: paymentStatusFilterOptions(),
      format: status => (
        <Badge
          {...toInvoicePaymentStatusBadgeProps({
            status: status as InvoicePaymentStatus
          })}
        />
      ),
      label: 'Payment status',
      icon: ViewColumnsIcon,
      optionsSortFn: () => 0
    },
    {
      type: 'multiSelect',
      paramName: 'invoiceCurrency',
      label: 'Currency',
      icon: CurrencyPoundIcon,
      options: currencyFilterOptions,
      format: currency => currency
    },
    {
      type: 'date',
      paramName: 'due',
      paramNameBefore: 'dueBefore',
      paramNameAfter: 'dueAfter',
      optionsBefore: dueBeforeFilterOptions,
      optionsAfter: dueAfterFilterOptions,
      format: (_, label) => label,
      label: 'Due',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'date',
      paramName: 'sent',
      paramNameBefore: 'sentBefore',
      paramNameAfter: 'sentAfter',
      optionsBefore: sentFilterOptions,
      optionsAfter: sentFilterOptions,
      format: (_, label) => label,
      label: 'Sent',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'date',
      paramName: 'invoice',
      paramNameBefore: 'invoiceBefore',
      paramNameAfter: 'invoiceAfter',
      optionsBefore: invoiceBeforeFilterOptions,
      optionsAfter: invoiceAfterFilterOptions,
      format: (_, label) => label,
      label: 'Invoice date',
      stringifyDate: date => formatISODate(date),
      icon: CalendarIcon
    },
    {
      type: 'toggle',
      paramName: 'excludeZeroQuantity',
      label: 'Hide zero-usage invoices'
    }
  ]

  const filters = filtersConfig.map(filter => ({
    ...filter,
    disabled: !!forcedActiveFilters.find(
      forcedFilter => forcedFilter.paramName === filter.paramName
    )
  }))

  const {
    activeFilters: searchActiveFilters,
    onChangeActiveFilters: onChangeSearchActiveFilters,
    sortBy,
    onChangeSortBy
  } = useLinkMagicTableWithSearchParams(filters)

  const activeFilters = useMemo(
    () => [...forcedActiveFilters, ...searchActiveFilters],
    [forcedActiveFilters, searchActiveFilters]
  )

  const onChangeActiveFilters = useCallback(
    (newActiveFilters: ActiveFilter[]) => {
      onChangeSearchActiveFilters(
        newActiveFilters.filter(
          filter =>
            !forcedActiveFilters.find(
              forcedFilter => forcedFilter.paramName === filter.paramName
            )
        )
      )
    },
    [onChangeSearchActiveFilters, forcedActiveFilters]
  )

  const [prevQueryParams, setPrevQueryParams] = useState<string>('')

  useEffect(() => {
    const nextQueryParams = new URLSearchParams(
      magicTableToApiQueryParams(filters, activeFilters, sortBy)
    ).toString()

    if (nextQueryParams === prevQueryParams) {
      return
    }

    setPrevQueryParams(nextQueryParams)
    onFiltersApiQueryParamsChanged(nextQueryParams)
  }, [
    filters,
    activeFilters,
    sortBy,
    onFiltersApiQueryParamsChanged,
    prevQueryParams
  ])

  return {
    isLoading,
    filters,
    activeFilters,
    sortBy,
    onChangeActiveFilters,
    onChangeSortBy
  }
}
