import { Flex, Text } from '@chakra-ui/react'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/16/solid'
import { GreyGrey60, GreyGrey70, Lato13Bold } from '@sequencehq/design-tokens'
import { FC, PropsWithChildren, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

const Title: FC<
  PropsWithChildren<{ isExpanded: boolean; onClick: () => void }>
> = ({ children, isExpanded, onClick }) => {
  const Icon = isExpanded ? ChevronUpIcon : ChevronDownIcon
  return (
    <Flex
      alignItems="center"
      px={2}
      py={1}
      onClick={onClick}
      color={GreyGrey60}
      cursor="pointer"
      _hover={{
        color: GreyGrey70
      }}
    >
      <Text
        {...Lato13Bold}
        color="inherit"
        fontSize="11px"
        textTransform="uppercase"
        flex="1"
      >
        {children}
      </Text>
      <Icon height="16px" width="16px" color={GreyGrey70} />
    </Flex>
  )
}
export const NavSection: FC<
  PropsWithChildren<{
    id: string
    title: string
    expandOnMatchingPath?: (pathname: string) => boolean
  }>
> = ({ id, title, expandOnMatchingPath, children }) => {
  const location = useLocation()
  const [isExpanded, setIsExpanded] = useState(loadExpansionState(id))

  // biome-ignore lint/correctness/useExhaustiveDependencies: check location.pathname only
  useEffect(() => {
    if (!expandOnMatchingPath?.(location.pathname) || isExpanded) {
      return
    }

    setIsExpanded(true)
  }, [location.pathname])

  return (
    <>
      <Title
        isExpanded={isExpanded}
        onClick={() =>
          setIsExpanded(expanded => {
            saveExpansionState(id, !expanded)
            return !expanded
          })
        }
      >
        {title}
      </Title>
      {isExpanded && (
        <Flex flexDir="column" gap="1px" width="100%">
          {children}
        </Flex>
      )}
    </>
  )
}

const loadExpansionState = (id: string): boolean => {
  try {
    return localStorage.getItem(`${id}_expanded`) === 'true'
  } catch {
    return true
  }
}

const saveExpansionState = (id: string, newState: boolean) => {
  try {
    localStorage.setItem(`${id}_expanded`, newState.toString())
    return
  } catch {
    return
  }
}
