import { dequal } from 'dequal'

const filterUndefinedItems = (value: unknown): unknown => {
  if (!value) {
    return value
  }

  if (!Array.isArray(value) && typeof value !== 'object') {
    return value
  }

  if (Array.isArray(value)) {
    return value
      .filter(v => !([undefined, null] as Array<unknown>).includes(v))
      .map(filterUndefinedItems)
  }

  return Object.entries(value as Record<any, any>).reduce((acc, [k, v]) => {
    if (([undefined, null] as Array<unknown>).includes(v)) {
      return acc
    }
    return {
      ...acc,
      [k]: filterUndefinedItems(v)
    }
  }, {})
}

export const objectsAreEqualIgnoreUndefined = (
  a: Record<any, any> | null,
  b: Record<any, any> | null
): boolean => {
  const filteredA = filterUndefinedItems(a)
  const filteredB = filterUndefinedItems(b)

  return dequal(filteredA, filteredB)
}
