import { useCallback } from 'react'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { ApiQuoteTemplate } from '../entityLoaders/useLoadQuoteTemplate'

export type QuoteTemplateUpdateBody = {
  template: {
    title: string
    currency: string
    presentation: Record<string, unknown>
    dealType: string
  }
  phases: Array<DashboardApi20240730.PutUpdateQuoteTemplate.PutPhase>
  attachmentAssetIds: Array<string>
}

type UseSaveQuoteTemplate = () => {
  create: () => Promise<ApiQuoteTemplate | null>
  update: (
    id: string,
    data: QuoteTemplateUpdateBody
  ) => Promise<ApiQuoteTemplate | null>
}

export const useSaveQuoteTemplate: UseSaveQuoteTemplate = () => {
  const create = useCallback(async () => {
    const response = await dashboard20240730Client.postCreateQuoteTemplate()
    if (response.error) {
      return null
    }

    return response.data ?? null
  }, [])

  const update = useCallback(
    async (id: string, data: QuoteTemplateUpdateBody) => {
      const response = await dashboard20240730Client.putUpdateQuoteTemplate({
        id,
        body: data
      })

      if (response.error) {
        return null
      }

      return response.data ?? null
    },
    []
  )

  return {
    create,
    update
  }
}
