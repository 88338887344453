import { Badge } from '@sequencehq/core-components'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellEmpty,
  MagicTable,
  useMagicTableInfiniteQuery,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNavigate } from 'react-router-dom'
import { UsersIcon as UsersIcon24 } from '@heroicons/react/24/solid'

type SeatMetric = DashboardApi20240730.GetSeatMetrics.SeatMetric

export const SeatTypesMagicTable = () => {
  const navigate = useNavigate()

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getSeatMetrics
  )

  return (
    <CurrentUserId>
      {userId => (
        <MagicTable<{ model: SeatMetric }>
          entityNamePlural="seat metrics"
          sequenceUserId={userId}
          infiniteQuery={infiniteQuery}
          columns={[
            {
              id: 'label',
              accessorKey: 'label',
              header: 'Name',
              cell: value => (
                <MagicTableCell text={value.getValue<SeatMetric['label']>()} />
              )
            },
            {
              id: 'seatType',
              accessorKey: 'seatType',
              header: 'Seat type',
              cell: value => (
                <MagicTableCellEmpty>
                  <Badge sentiment="neutral" size="sm" textTransform="none">
                    {value.getValue<SeatMetric['seatType']>()}
                  </Badge>
                </MagicTableCellEmpty>
              )
            },
            {
              id: 'createdAt',
              accessorKey: 'createdAt',
              header: 'Created At (UTC)',
              cell: value => {
                const createdAt = value.getValue<SeatMetric['createdAt']>()

                return (
                  <MagicTableCell
                    text={dateTimeWithFormat(
                      createdAt,
                      'dd-MM-yyyy ∙ HH:mm:ss'
                    )}
                  />
                )
              }
            }
          ]}
          emptyStateUnfiltered={entityNamePlural => (
            <MagicTableEmptyState
              entityNamePlural={entityNamePlural}
              imageSrc="/img/empty/gauge.png"
              learnMoreText="Seat metrics represent the types of seats that exist in your product"
              learnMoreHref="https://docs.sequencehq.com/seats/overview"
              buttonNew={{
                label: 'New seat metric',
                onClick: () => navigate('/seats/new')
              }}
            />
          )}
          emptyStateFiltered={(entityNamePlural, clearFilters) => (
            <MagicTableFilterEmptyState
              entityNamePlural={entityNamePlural}
              entityIcon={UsersIcon24}
              actionClearFilters={clearFilters}
              actionViewAll={() => navigate('/seats')}
              variant="TABLE"
            />
          )}
        />
      )}
    </CurrentUserId>
  )
}
