import { PhaseAnalysis } from 'modules/Cube/domain/postActionStages/queries/getPhaseAnalysis/getPhaseAnalysis'
// eslint-disable-next-line no-restricted-imports
import type { Duration as DateFnsDuration } from '@sequencehq/utils/dates'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import { QuoteBlock, QuotePresentationV2 } from '@sequencehq/quote-content'
import {
  CAProvinces,
  CountriesAlpha2,
  Currency,
  IntegrationServices,
  USStates
} from '@sequencehq/api/commonEnums'
import { CustomerContact } from 'CustomerContacts/domain/CustomerContacts.domain.types'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { ServerAsset } from '@sequencehq/api/dashboard/v20240730'

export type SaveEntity<T> = Omit<
  T,
  'createdAt' | 'updatedAt' | 'sequenceAccountId'
>

export type DisabledField = {
  fieldName: string
  reasons: string[]
}

export type RecursivePartial<T> = {
  [P in keyof T]?: T[P] extends (infer U)[]
    ? RecursivePartial<U>[]
    : T[P] extends object | undefined
      ? RecursivePartial<T[P]>
      : T[P]
}
export type AvailableStandardFrequency =
  | 'ONE_TIME'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'HALF_YEARLY'
  | 'YEARLY'

/**
 * Data: 'real' data - this is used to drive queries and is treated
 * as the ground truth for the editor. It is expected that this data
 * can be loaded/saved.
 *
 * Derived: this is transient state associated with the current editor.
 * For the same version of the application we expect the same data to
 * result in the same derived state.
 *
 * Configuration: all configuration data which can influence access and
 * behaviour of the application.
 */
export type CustomerAlias = {
  value: string
  id: string
  label?: string
}

export type CustomerOrganization = {
  id: string
  owner: {
    id: string
    name: string
  }
  members: {
    id: string
    name: string
  }[]
}

export type Duration = Pick<DateFnsDuration, 'years' | 'months' | 'days'>
/**
 * This data is common to all major 'root' types that cube can load,
 * which at the moment is quote and billing schedule.
 */
export enum CubeStatus {
  QuoteTemplate = 'quoteTemplate',
  QuoteDraft = 'quoteDraft',
  QuotePublished = 'quotePublished',
  QuoteReadyToSign = 'quoteReadyToSign',
  QuoteAccepted = 'quoteAccepted',
  QuoteExecuted = 'quoteExecuted',
  ScheduleUnsaved = 'scheduleUnsaved',
  ScheduleDraft = 'scheduleDraft',
  SchedulePending = 'schedulePending',
  ScheduleActive = 'scheduleActive',
  ScheduleSuspended = 'scheduleSuspended',
  ScheduleCancelled = 'scheduleCancelled',
  ScheduleCompleted = 'scheduleCompleted'
}

export type CustomerTaxStatus = 'TAXED' | 'TAX_EXEMPT' | 'REVERSE_CHARGED'
export type Customer = {
  address: Address
  id: string
  taxStatus: CustomerTaxStatus
  legalName: string
  archivedAt?: string
  aliases: CustomerAlias[]
  integrationIds: {
    service: IntegrationServices
    id: string
    lastSynced?: string
    url?: string
  }[]
  organizations?: CustomerOrganization[]
  contacts?: CustomerContact[]
}

export type TaxRate = {
  id: string
  name: string
  country: CountriesAlpha2
}

export type Product = {
  id: string
  name: string
  label: string
}

export type BillingFrequency =
  | 'ONE_TIME'
  | 'MONTHLY'
  | 'QUARTERLY'
  | 'YEARLY'
  | 'HALF_YEARLY'
  | 'ON_DEMAND'
export type BillingType = 'IN_ARREARS' | 'IN_ADVANCE'

export type Price = Dashboardv20240509Api.GetPrice.Price

export type Minimum = {
  id: string
  value: string
  name: string
  scope: {
    target: 'specific' | 'allUsage'
    priceIds: Price['id'][]
  }
}

export type Discount = {
  id: string
  message: string
  discountCalculationType: 'PERCENTAGE' | 'NOMINAL'
  amount: number
  priceIds: Array<Price['id']>
  applyToAllPrices: boolean
  seatDiscountType: 'INCLUDED_SEATS_ONLY' | 'OVERAGE_SEATS_ONLY' | 'ALL_SEATS'
}

export type PhaseRecurrencePreference = 'CONTINUE_FROM_PREVIOUS_PHASE' | 'RESET'
export type PhaseDuration = Duration | 'OPEN_ENDED' | 'MILESTONE'
export type PhasePriceMetadata = {
  priceId: Price['id']
  arrCalculation: 'INCLUDE' | 'EXCLUDE'
}
export type Phase = {
  id: string
  priceIds: Array<Price['id']>
  discountIds: Array<Discount['id']>
  name: string
  minimumIds: Array<Minimum['id']>
  duration: PhaseDuration
  recurrencePreference: PhaseRecurrencePreference
  phasePriceMetadata: Array<PhasePriceMetadata>
}

export type Common = {
  /** @remarks The entity ID, such as the quote or schedule `id` property */
  id: string
  title: string
  /** @remarks The human readable identifier for the root entity, such as the quote number. */
  alias: string
  customerId: Customer['id']
  /**
   * @remarks Cube always models currency globally - so any indirect currency select (such as
   * via adding a price) should update this value too
   */
  currency?: Currency
  /**
   * @remarks Cube's status is a combination of the statuses across the (currently) two root
   * entities, and is the only point in the data model where we 'fork' between the two in the
   * data model.
   */
  status: CubeStatus
  /**
   * @remarks The start date, if present 'anchors' the calculation of relative dates which allows
   * us to produce absolute values from their relative definitions, including the end date (which
   * is why we don't store the end date explicitly, as that is driven by the final date of the last
   * phase).
   */
  startDate: Date | undefined
  isArchived: boolean
  /**
   * @remarks We only reference the ids of entities, with the actual entity itself being contained within
   * the appropriate data object. This makes lookup and modification of these entities more simple, and
   * we can produce pre-resolved views in the query layer.
   */
  phaseIds: Array<Phase['id']>
  createdAt: Date | undefined
}

export type Schedule = {
  /**
   * @remarks AKA. the billing date. When phases contain multiple billing frequencies, we will set this to
   * match the start date and not allow user modification
   */
  recurrenceDayOfMonth: number
  purchaseOrderNumber: string
  reference: string
  label: string
  rollUpBilling: boolean
  stripePayment: boolean
  stripeAutoCharge: boolean
  taxRateId: TaxRate['id']
  autoIssueInvoices: boolean
  attachmentAssets: ServerAsset[]
}

export type QuoteContact = {
  contactId: string
  name: string | undefined
  email: string
  signedAt: string | undefined
}

export type CounterSigner = {
  name: string
  email: string
  signedAt: string | undefined
}

export type QuotePdfStatus =
  | 'NOT_YET_REQUESTED'
  | 'REQUESTED'
  | 'IN_PROGRESS'
  | 'FAILED'
  | 'GENERATED'

export type QuoteDealType = 'NEW_BUSINESS' | 'RENEWAL'
export type Quote = {
  contractLength?: Duration
  dealType: QuoteDealType
  /** @remarks how long after publishing the quote will expire */
  expiresIn: Duration | undefined
  acceptedAt: Date | undefined
  publishedAt: Date | undefined
  readyToSignAt: Date | undefined
  executedAt: Date | undefined
  billingScheduleId: string | undefined
  expiresAt: Date | undefined
  quoteNumber: string
  createdByEmail: string | undefined
  contacts: QuoteContact[]
  isSignaturesEnabled: boolean
  counterSigners: CounterSigner[]
  customerLegalName: string | undefined
  customerAddress: Address | undefined
  salesforceOpportunityId?: string
  pdfStatus: QuotePdfStatus
  attachmentAssets: ServerAsset[]
  isExpired: boolean
}

export type PresentationV2 = {
  version: number
  blocks: Array<QuoteBlock>
  settings: PresentationV2Settings
}

export type PresentationV2Settings = {
  showTotals: boolean
  groupPricesByPhase: boolean
}

export type Address =
  | {
      line1: string
      line2?: string
      town: string
      postcode: string
      country: CountriesAlpha2
    }
  | {
      line1: string
      line2?: string
      town: string
      state: USStates
      postcode: string
      country: 'US'
    }
  | {
      line1: string
      line2?: string
      town: string
      state: CAProvinces
      postcode: string
      country: 'CA'
    }

export type MerchantBranding = {
  legalCompanyName: string
  address: Address
  email: string
  primaryColour?: string
  logoUrl?: string
  url?: string
}

export type ListPrice = {
  id: string
  name: string
  productId: string
  structure: Price['structure']
  currency: Currency
  integrationIds: Price['integrationIds']
  billingFrequency: BillingFrequency
  usageCalculationPeriod?: Price['usageCalculationPeriod']
  billingType: BillingType
  customMetricParameters: Price['customMetricParameters']
  createdAt: string
}

export type Contact = {
  id: string
  name?: string
  email: string
  customerId: string
}

export type ContactPostBody = {
  name: string
  email: string
  billingPreference: 'PRIMARY' | 'STANDARD' | 'NONE'
}

export type CubeEditorData = {
  common: Common
  schedule: Schedule
  quote: Quote
  presentation: QuotePresentationV2
  phases: Record<Phase['id'], Phase>
  customers: Record<Customer['id'], Customer>
  taxRates: Record<TaxRate['id'], TaxRate>
  products: Record<Product['id'], Product>
  discounts: Record<Discount['id'], Discount>
  prices: Record<Price['id'], Price>
  listPrices: Record<Product['id'], ListPrice[]>
  minimums: Record<Minimum['id'], Minimum>
  merchantBranding: MerchantBranding
  contacts: Record<Contact['id'], Contact>
}

export enum DisabledReasonType {
  CompletedSchedule = 'completedSchedule',
  PhaseNotStarted = 'phaseNotStarted',
  CompletedPhase = 'completedPhase',
  StartedPhase = 'startedPhase',
  TieredPriceTransition = 'tieredPriceTransition',
  PhaseStartTransition = 'phaseStartTransition',
  PhaseEndTransition = 'phaseEndTransition',
  InvalidStatus = 'invalidStatus',
  PhaseHasNoPrices = 'phaseHasNoPrices',
  MaxMinimums = 'maxMinimums',
  NoValidPrices = 'noValidPrices',
  GlobalDiscountExists = 'globalDiscountExists',
  FeatureDisabled = 'featureDisabled',
  NotFirstPhase = 'notFirstPhase',
  TooManyPhases = 'tooManyPhases',
  SavingInProgress = 'savingInProgress',
  NonMonthlyPricesPresent = 'nonMonthlyPricesPresent',
  InvalidDuration = 'noDuration',
  InvalidBillingType = 'invalidBillingType',
  NoProration = 'noProration',
  CantChangeAfterPublish = 'cantChangeAfterPublish',
  CantChangeWhenArchived = 'cantChangeWhenArchived',
  CantChangeWhenAccepted = 'cantChangeWhenAccepted',
  CantChangeWhenExecuted = 'cantChangeWhenExecuted',
  CantChangeWhenExpired = 'cantChangeWhenExpired',
  CantEditPricingModelOnQuotePrice = 'cantEditPricingModelOnQuotePrice',
  NoChangesMade = 'noChangesMade',
  NoCustomerSelected = 'noCustomerSelected',
  NoCustomerSalesforceLink = 'noCustomerSalesforceLink',
  SalesforceOpportunityLinked = 'salesforceOpportunityLinked',
  NotMilestonePhase = 'notMilestonePhase',
  ScheduleNotActive = 'scheduleNotActive',
  IsFirstPhase = 'isFirstPhase'
}

export type DisabledReason = {
  reasonType: DisabledReasonType
  /**
   * The metadata is designed more for debugging than for programmatic access
   * in this case.
   */
  metadata: Record<string, unknown>
}

export type VisibleEnabledFeatureAvailable = {
  available: {
    visible: boolean
    enabled: boolean
  }
  reasons: Array<DisabledReason>
}

export type ResolvedPhase = {
  id: Phase['id']
  name: string
  minimums: Minimum[]
  discounts: Discount[]
  prices: Price[]
  products: Product[]
  dates: {
    absolute: {
      start?: Date
      end?: Date
    }
    duration: PhaseDuration
  }
  globalDiscount: Discount | undefined
  analysis: PhaseAnalysis
  phaseHasStarted: boolean
  recurrencePreference: PhaseRecurrencePreference
  recurrenceDayOfMonth: number
  phasePriceMetadata: Phase['phasePriceMetadata']
}

export type AvailableProductFeatures = {
  edit: VisibleEnabledFeatureAvailable
  delete: VisibleEnabledFeatureAvailable
  editDiscount: VisibleEnabledFeatureAvailable
  deleteDiscount: VisibleEnabledFeatureAvailable
  canEditPricingModel: VisibleEnabledFeatureAvailable
  editPhasePriceMetadata: VisibleEnabledFeatureAvailable
}
export type AvailableFeaturesForPhase = {
  products: Record<Product['id'], AvailableProductFeatures>
  phase: {
    editName: VisibleEnabledFeatureAvailable
    editPhaseRecurrence: VisibleEnabledFeatureAvailable
    completeMilestone: VisibleEnabledFeatureAvailable
    discount: {
      add: VisibleEnabledFeatureAvailable
      edit: VisibleEnabledFeatureAvailable
      delete: VisibleEnabledFeatureAvailable
    }
    product: {
      add: VisibleEnabledFeatureAvailable
    }
    minimum: {
      add: VisibleEnabledFeatureAvailable
      edit: VisibleEnabledFeatureAvailable
      delete: VisibleEnabledFeatureAvailable
    }
    dates: {
      editStart: VisibleEnabledFeatureAvailable
      editDuration: VisibleEnabledFeatureAvailable
      alignDates: VisibleEnabledFeatureAvailable
    }
  }
}

export type ValidationResult = {
  message: string
  metadata?: Record<string, unknown>
}

export type ValidationResults = {
  common: {
    currency: ValidationResult[] | null
    customerId: ValidationResult[] | null
    phaseIds: ValidationResult[] | null
    phases: Record<
      Phase['id'],
      {
        prices: ValidationResult[] | null
        duration: ValidationResult[] | null
      }
    > | null
    startDate: ValidationResult[] | null
    title: ValidationResult[] | null
  }
  schedule: {
    recurrenceDayOfMonth: ValidationResult[] | null
    taxRate: ValidationResult[] | null
  }
  quote: {
    dealType: ValidationResult[] | null
    expiry: ValidationResult[] | null
    presentation: ValidationResult[] | null
    quoteNumber: ValidationResult[] | null
    contactIds: ValidationResult[] | null
  }
}

export type CubeReducerState = {
  data: CubeEditorData
  queries: {
    resolvedPhases: Record<Phase['id'], ResolvedPhase>
    orderedPhases: ResolvedPhase[]
    selectedCurrency?: Currency
    pricingDates: {
      start: Date | undefined
      end: Date | undefined
    }
    totalPricingDuration: Duration | undefined
    cubeDataUpdated: boolean
    availableFrequencies: AvailableStandardFrequency[]
    availableTaxRates: Record<TaxRate['id'], TaxRate>
    availableListPrices: Record<Product['id'], ListPrice[]>
    validation: {
      allValidationResults: {
        save: ValidationResults
        publish: ValidationResults
        accept: ValidationResults
        execute: ValidationResults
      }
      validationErrorsPresent: {
        save: boolean
        publish: boolean
        accept: boolean
        execute: boolean
        deleteLatestDraft: boolean
      }
      activeValidationResults: ValidationResults | null
    }
    availableFeatures: {
      phases: Record<Phase['id'], AvailableFeaturesForPhase>
      schedule: {
        publish: VisibleEnabledFeatureAvailable
        save: VisibleEnabledFeatureAvailable
        stripePayment: VisibleEnabledFeatureAvailable
        stripeAutoCharge: VisibleEnabledFeatureAvailable
        rollUpBilling: VisibleEnabledFeatureAvailable
        recurrenceDayOfMonth: VisibleEnabledFeatureAvailable
        taxRate: VisibleEnabledFeatureAvailable
        label: VisibleEnabledFeatureAvailable
        reference: VisibleEnabledFeatureAvailable
        purchaseOrderNumber: VisibleEnabledFeatureAvailable
        autoIssueInvoices: VisibleEnabledFeatureAvailable
      }
      quote: {
        contractLengthEditing: VisibleEnabledFeatureAvailable
        publish: VisibleEnabledFeatureAvailable
        accept: VisibleEnabledFeatureAvailable
        execute: VisibleEnabledFeatureAvailable
        preview: VisibleEnabledFeatureAvailable
        archive: VisibleEnabledFeatureAvailable
        deleteLatestDraft: VisibleEnabledFeatureAvailable
        edit: VisibleEnabledFeatureAvailable
        setRecipients: VisibleEnabledFeatureAvailable
        duplicate: VisibleEnabledFeatureAvailable
        revoke: VisibleEnabledFeatureAvailable
        integrations: VisibleEnabledFeatureAvailable
        saveTemplate: VisibleEnabledFeatureAvailable
        createQuoteFromTemplate: VisibleEnabledFeatureAvailable
      }
      common: {
        editCustomer: VisibleEnabledFeatureAvailable
        editStartDate: VisibleEnabledFeatureAvailable
        milestoneBilling: VisibleEnabledFeatureAvailable
        addPhase: VisibleEnabledFeatureAvailable
        customPhaseDurations: VisibleEnabledFeatureAvailable
        inspector: {
          unlinkSalesforceCustomer: VisibleEnabledFeatureAvailable
          linkSalesforceCustomer: VisibleEnabledFeatureAvailable
        }
      }
    }
    quote: {
      isATemplate: boolean
    }
  }
  configuration: {
    currency: {
      default: Currency
      enabled: Currency[]
    }
    permissions: Record<string, boolean>
    availableFrequencies: AvailableStandardFrequency[]
    features: {
      showNewTaxManagement: boolean
      stripeIntegrationAvailable: boolean
      showReferenceField: boolean
      showLabelField: boolean
      showAutoIssueInvoicesField: boolean
      useMilestoneBilling: boolean
      phaseRecurrenceEditing: boolean
      phasePriceMetadataEditing: boolean
    }
  }
  editor: {
    loaded: boolean
    lastLoadedAt?: Date
    activeValidationSet:
      | 'save'
      | 'publish'
      | 'accept'
      | 'execute'
      | 'deleteLatestDraft'
      | undefined
    savingDraft: boolean
    savingSchedule: boolean
  }
  /**
   * This is used for some niche queries related to the initial state of
   * the editor, such as whether or not the version initial had a price on
   * it. It is only to be accessed, exclusively, by queries, and set by the
   * load action.
   */
  initialData: CubeEditorData
  initialQueries: {
    resolvedPhases: Record<Phase['id'], ResolvedPhase>
  }
}

export type Action<T extends string, P> = {
  type: T
  payload: P
}

export enum CorePortErrors {
  NotFound = 'notFound',
  Other = 'other'
}

export type CubeDomainInput = Pick<CubeReducerState, 'data' | 'configuration'>

export type LoadAction = Action<'load', CubeDomainInput>

export type ResetAction = Action<'reset', undefined>

export type AlignPhaseDurationAction = Action<
  'alignPhaseDuration',
  {
    phaseId: Phase['id']
  }
>

export type UpdateAction = Action<
  'updateData',
  RecursivePartial<CubeReducerState['data']>
>

export type DeleteAction = Action<
  'deleteData',
  {
    type: keyof CubeEditorData
    ids: string[]
  }
>

export type UpdateConfigurationAction = Action<
  'updateConfiguration',
  RecursivePartial<CubeReducerState['configuration']>
>

export type UpdateEditorAction = Action<
  'updateEditor',
  Partial<CubeReducerState['editor']>
>

export type CubeActions =
  | UpdateConfigurationAction
  | UpdateAction
  | UpdateEditorAction
  | AlignPhaseDurationAction
  | LoadAction
  | DeleteAction
  | ResetAction

/**
 * =============================
 **/

export type ActionHandler<A extends CubeActions> = (
  prevState: CubeReducerState
) => (action: A) => CubeReducerState

export type PostActionContext = {
  preActionState: CubeReducerState
  action: CubeActions
}
export type PostActionStage = (
  ctx: PostActionContext
) => (prevState: CubeReducerState) => CubeReducerState

export type SchedulePreviewArguments = {
  startDate: string
  endDate: string
  recurrenceDayOfMonth: number
  prices: Array<{
    billingFrequency: BillingFrequency
    billingType: BillingType
  }>
}

export type SchedulePreviewResponse = Array<{
  start: string
  end: string
}>

/**
 * Port implementation type
 */
export type ScheduleCubePorts = {
  in: {
    core: () => Promise<{
      data: CubeDomainInput | null
      error: CorePortErrors | null
    }>
    customer: (args: { customerId: Customer['id'] }) => Promise<Customer | null>
    schedule: {
      preview: (
        args: SchedulePreviewArguments
      ) => Promise<SchedulePreviewResponse>
    }
  }
  out: {
    save: (data: CubeDomainInterface['queries']) => Promise<boolean>
    archive: () => Promise<boolean>
    schedule: {
      activate: (data: CubeDomainInterface['queries']) => Promise<boolean>
    }
  }
}

export type QuoteCubePorts = {
  in: {
    core: () => Promise<{
      data: CubeDomainInput | null
      error: CorePortErrors | null
    }>
    customer: (args: { customerId: Customer['id'] }) => Promise<Customer | null>
    contacts: {
      reloadContacts: (args: { customerId: string }) => Promise<Contact[]>
    }
  }
  out: {
    save: (data: CubeDomainInterface['queries']) => Promise<boolean>
    archive: () => Promise<boolean>
    quote: {
      publish: (data: {
        sendRecipientsEmail: boolean
        emailMessage: string | undefined
      }) => Promise<boolean>
      accept: () => Promise<boolean>
      execute: () => Promise<boolean>
      deleteLatestDraft: () => Promise<boolean>
      duplicate: () => Promise<{ newQuoteId: string } | null>
    }
    contacts: {
      createContact: (args: {
        customerId: string
        body: ContactPostBody
      }) => Promise<Contact | null>
    }
  }
}

export type QuoteTemplateCubePorts = {
  in: {
    core: () => Promise<{
      data: CubeDomainInput | null
      error: CorePortErrors | null
    }>
    customer: (args: { customerId: Customer['id'] }) => Promise<Customer | null>
  }
  out: {
    save: (data: CubeDomainInterface['queries']) => Promise<boolean>
    archive: () => Promise<boolean>
  }
}

export type CubePorts =
  | ScheduleCubePorts
  | QuoteCubePorts
  | QuoteTemplateCubePorts

export type CubePortImplementationProp = {
  configuration: CubeReducerState['configuration']
  defaultValues?: {
    customerId?: string
    salesforceOpportunityId?: string
  }
}

export type CubePortImplementation = (
  ctx: CubePortImplementationProp
) => CubePorts
