import { Flex, Box, Center } from '@chakra-ui/react'
import { FC, ReactNode } from 'react'
import { useDispatch } from 'features/store'
import { useNavigate } from 'react-router-dom'
import { OverlayPayload, openOverlay } from 'features/overlay'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { PlusIcon } from '@heroicons/react/16/solid'
import {
  Button,
  SequenceKebabMenu,
  SequenceMenuItemType
} from '@sequencehq/core-components'

export type AddNewConfig =
  | {
      buttonText?: string
      href: string
    }
  | {
      buttonText?: string
      overlayPayload: OverlayPayload
    }

const isHrefConfig = (
  addNewConfig: AddNewConfig
): addNewConfig is { href: string } => {
  return 'href' in addNewConfig
}

type TopNavigationProps = {
  children: ReactNode
  url?: string
  modalAction?: OverlayPayload
  actionElement?: ReactNode
  addNewConfig?: AddNewConfig
}

const TopNavigation: FC<TopNavigationProps> = ({
  children,
  addNewConfig,
  actionElement
}) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const handleClick = () => {
    if (!addNewConfig) {
      return
    }

    if (isHrefConfig(addNewConfig)) {
      navigate(addNewConfig.href)
      return
    }

    dispatch(openOverlay(addNewConfig.overlayPayload))
  }

  const style = {
    height: '48px',
    borderBottom: `${GreyGrey30} 1px solid`,
    px: '16px',
    py: '16px'
  }

  return (
    <Flex
      w="100%"
      backgroundColor="transparent"
      justify="space-between"
      align="center"
      position="static"
      sx={style}
    >
      {children}
      {(actionElement || addNewConfig) && <Box width={'8px'} />}
      {actionElement ? (
        actionElement
      ) : addNewConfig ? (
        <Button
          variant="primary"
          leadingIcon={<PlusIcon width="16px" height="16px" color="inherit" />}
          onClick={handleClick}
          data-testid="TopNavigation.add-new"
        >
          {addNewConfig.buttonText ?? 'Add new'}
        </Button>
      ) : null}
    </Flex>
  )
}

export default TopNavigation
