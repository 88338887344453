import {
  AvailableStandardFrequency,
  CubeReducerState,
  CubeStatus
} from 'modules/Cube/domain/cube.domain.types'

export const NEW_PRICE_PATTERN = /^new-price::/
export const NEW_DISCOUNT_PATTERN = /^new-discount::/
export const NEW_PRODUCT_PATTERN = /^new-product::/
export const NEW_ROOT_ENTITY_PATTERN = /^new-entity::/
export const NEW_PHASE_PATTERN = /^new-phase::/
export const NEW_MINIMUM_PATTERN = /^new-minimum::/

export const NEW_ROOT_ENTITY_PREFIX = 'new-entity::'
export const NEW_PRICE_PREFIX = 'new-price::'
export const NEW_DISCOUNT_PREFIX = 'new-discount::'
export const NEW_PRODUCT_PREFIX = 'new-product::'
export const NEW_PHASE_PREFIX = 'new-phase::'
export const NEW_MINIMUM_PREFIX = 'new-minimum::'

const DEFAULT_AVAILABLE_FEATURE = {
  available: {
    visible: false,
    enabled: false
  },
  reasons: []
}

const INITIAL_DATA: CubeReducerState['data'] = {
  common: {
    id: 'uninitialized',
    title: '',
    alias: '',
    customerId: '',
    currency: undefined,
    status: CubeStatus.ScheduleUnsaved,
    startDate: undefined,
    isArchived: false,
    phaseIds: [`${NEW_PHASE_PREFIX}12345`],
    createdAt: new Date()
  },
  schedule: {
    recurrenceDayOfMonth: 1,
    purchaseOrderNumber: '',
    reference: '',
    label: '',
    rollUpBilling: false,
    stripePayment: false,
    stripeAutoCharge: false,
    taxRateId: '',
    autoIssueInvoices: false,
    attachmentAssets: []
  },
  quote: {
    attachmentAssets: [],
    contractLength: { years: 1, days: -1 },
    dealType: 'NEW_BUSINESS',
    expiresIn: { days: 30 },
    publishedAt: undefined,
    readyToSignAt: undefined,
    acceptedAt: undefined,
    executedAt: undefined,
    billingScheduleId: undefined,
    expiresAt: undefined,
    quoteNumber: '',
    createdByEmail: '',
    contacts: [],
    counterSigners: [],
    isSignaturesEnabled: false,
    customerLegalName: undefined,
    customerAddress: undefined,
    pdfStatus: 'NOT_YET_REQUESTED',
    isExpired: false
  },
  presentation: {
    version: 2,
    blocks: [],
    settings: {
      showTotals: false,
      groupPricesByPhase: false
    }
  },
  phases: {
    [`${NEW_PHASE_PREFIX}12345`]: {
      id: `${NEW_PHASE_PREFIX}12345`,
      name: '',
      priceIds: [],
      discountIds: [],
      minimumIds: [],
      duration: { years: 1, days: -1 },
      recurrencePreference: 'CONTINUE_FROM_PREVIOUS_PHASE',
      phasePriceMetadata: []
    }
  },
  customers: {},
  taxRates: {},
  products: {},
  discounts: {},
  prices: {},
  listPrices: {},
  minimums: {},
  merchantBranding: {
    legalCompanyName: '',
    address: { line1: '', town: '', postcode: '', country: 'GB' },
    email: ''
  },
  contacts: {}
}

export const STANDARD_AVAILABLE_FREQUENCIES: AvailableStandardFrequency[] = [
  'ONE_TIME',
  'MONTHLY',
  'QUARTERLY',
  'HALF_YEARLY',
  'YEARLY'
]

export const DEFAULT_VALIDATION_SET = {
  common: {
    currency: null,
    customerId: null,
    phaseIds: null,
    phases: null,
    startDate: null,
    title: null
  },
  schedule: {
    recurrenceDayOfMonth: null,
    taxRate: null
  },
  quote: {
    dealType: null,
    expiry: null,
    presentation: null,
    quoteNumber: null,
    contactIds: null
  }
}

export const INITIAL_CUBE_STATE: CubeReducerState = {
  data: INITIAL_DATA,
  queries: {
    totalPricingDuration: { years: 1, days: -1 },
    cubeDataUpdated: false,
    selectedCurrency: undefined,
    availableFrequencies: STANDARD_AVAILABLE_FREQUENCIES,
    availableTaxRates: {},
    resolvedPhases: {},
    orderedPhases: [],
    availableListPrices: {},
    pricingDates: {
      start: undefined,
      end: undefined
    },
    availableFeatures: {
      quote: {
        contractLengthEditing: DEFAULT_AVAILABLE_FEATURE,
        publish: DEFAULT_AVAILABLE_FEATURE,
        accept: DEFAULT_AVAILABLE_FEATURE,
        execute: DEFAULT_AVAILABLE_FEATURE,
        preview: DEFAULT_AVAILABLE_FEATURE,
        archive: DEFAULT_AVAILABLE_FEATURE,
        deleteLatestDraft: DEFAULT_AVAILABLE_FEATURE,
        edit: DEFAULT_AVAILABLE_FEATURE,
        setRecipients: DEFAULT_AVAILABLE_FEATURE,
        duplicate: DEFAULT_AVAILABLE_FEATURE,
        revoke: DEFAULT_AVAILABLE_FEATURE,
        integrations: DEFAULT_AVAILABLE_FEATURE,
        saveTemplate: DEFAULT_AVAILABLE_FEATURE,
        createQuoteFromTemplate: DEFAULT_AVAILABLE_FEATURE
      },
      schedule: {
        rollUpBilling: DEFAULT_AVAILABLE_FEATURE,
        stripePayment: DEFAULT_AVAILABLE_FEATURE,
        stripeAutoCharge: DEFAULT_AVAILABLE_FEATURE,
        publish: DEFAULT_AVAILABLE_FEATURE,
        save: DEFAULT_AVAILABLE_FEATURE,
        recurrenceDayOfMonth: DEFAULT_AVAILABLE_FEATURE,
        taxRate: DEFAULT_AVAILABLE_FEATURE,
        label: DEFAULT_AVAILABLE_FEATURE,
        reference: DEFAULT_AVAILABLE_FEATURE,
        purchaseOrderNumber: DEFAULT_AVAILABLE_FEATURE,
        autoIssueInvoices: DEFAULT_AVAILABLE_FEATURE
      },
      common: {
        milestoneBilling: DEFAULT_AVAILABLE_FEATURE,
        editCustomer: DEFAULT_AVAILABLE_FEATURE,
        editStartDate: DEFAULT_AVAILABLE_FEATURE,
        addPhase: DEFAULT_AVAILABLE_FEATURE,
        customPhaseDurations: DEFAULT_AVAILABLE_FEATURE,
        inspector: {
          linkSalesforceCustomer: DEFAULT_AVAILABLE_FEATURE,
          unlinkSalesforceCustomer: DEFAULT_AVAILABLE_FEATURE
        }
      },
      phases: {}
    },
    validation: {
      allValidationResults: {
        save: DEFAULT_VALIDATION_SET,
        publish: DEFAULT_VALIDATION_SET,
        accept: DEFAULT_VALIDATION_SET,
        execute: DEFAULT_VALIDATION_SET
      },
      activeValidationResults: null,
      validationErrorsPresent: {
        save: false,
        publish: false,
        accept: false,
        execute: false,
        deleteLatestDraft: false
      }
    },
    quote: {
      isATemplate: false
    }
  },
  configuration: {
    currency: {
      default: 'GBP',
      enabled: ['GBP']
    },
    availableFrequencies: STANDARD_AVAILABLE_FREQUENCIES,
    permissions: {},
    features: {
      useMilestoneBilling: false,
      showNewTaxManagement: false,
      stripeIntegrationAvailable: false,
      showLabelField: false,
      showReferenceField: false,
      showAutoIssueInvoicesField: false,
      phaseRecurrenceEditing: false,
      phasePriceMetadataEditing: false
    }
  },
  editor: {
    loaded: false,
    activeValidationSet: undefined,
    savingDraft: false,
    savingSchedule: false,
    lastLoadedAt: undefined
  },
  initialData: INITIAL_DATA,
  initialQueries: {
    resolvedPhases: {}
  }
}
