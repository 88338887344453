import { useEffect, useState } from 'react'
import { isBlobOrObjectUrl } from 'lib/utils'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { PdfViewer, PdfViewerHeightWorkaround } from '@sequencehq/pdf-viewer'

type Props = {
  url: string
  fileName?: string
  useHeightWorkaround?: boolean
}

export const PdfPreview = ({
  url,
  fileName,
  useHeightWorkaround = false
}: Props) => {
  const flags = useFlags()

  const [objectURL, setObjectURL] = useState<string | null>(null)

  useEffect(() => {
    const fetchUrl = async () => {
      const res = await fetch(url)
      const blob = await res.blob()
      const pdfBlob = blob.slice(0, blob.size, 'application/pdf')
      setObjectURL(URL.createObjectURL(pdfBlob))
    }

    if (isBlobOrObjectUrl(url)) {
      setObjectURL(url)
    } else {
      void fetchUrl()
    }

    return () => {
      if (objectURL) {
        URL.revokeObjectURL(objectURL)
      }
    }
  }, [url])

  if (!objectURL) {
    return null
  }

  // Check browser compatibility with the new PDF viewer too
  if (flags?.useCustomPdfViewer && 'withResolvers' in Promise) {
    const pdfViewer = (
      <PdfViewer data={url}>
        <PdfViewer.StandardControls fileName={fileName} />
      </PdfViewer>
    )

    return useHeightWorkaround ? (
      <PdfViewerHeightWorkaround>{pdfViewer}</PdfViewerHeightWorkaround>
    ) : (
      pdfViewer
    )
  }

  return (
    <embed src={objectURL} style={{ width: '100%', height: '100%' }}></embed>
  )
}
