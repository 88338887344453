import { useAtom } from 'jotai'
import { FC, useEffect } from 'react'
import { loadableExtractionResultAtom } from '../atoms'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { billingScheduleAdapter } from 'Cube/communication/external/billingSchedule.api.v1/adapters/billingSchedule.adapters'

export const BillingSchedulerExtractionAdapter: FC = () => {
  useUpdateCubeWithExtractionResult()

  return null
}

const useUpdateCubeWithExtractionResult = () => {
  const [extractionResult] = useAtom(loadableExtractionResultAtom)
  const cubeContext = useCubeContext()

  useEffect(() => {
    if (extractionResult.state !== 'hasData' || !extractionResult.data) {
      return
    }

    const { billingSchedule } = extractionResult.data

    const phases = billingSchedule.phases.map(phase => ({
      ...phase,
      discountIds: [],
      minimumIds: [],
      phasePriceMetadata: phase.priceIds.map(priceId => ({
        priceId,
        arrCalculation: 'INCLUDE' as const
      }))
    }))

    const adapted = billingScheduleAdapter.in(
      {
        billingSchedule: {
          ...billingSchedule,
          status: 'DRAFT',
          id: cubeContext.queries.initialData.common.id,
          phases
        },
        listPrices: [],
        taxRates: [],
        enabledCurrencies: ['GBP'],
        customers: [],
        integrations: [],
        products: [],
        prices: []
      },
      cubeContext.queries.rawData.configuration
    )

    cubeContext.mutators.updateData(adapted.data)
  }, [extractionResult.state])
}
