import { useCallback } from 'react'
import { useSaveQuoteTemplate } from './entitySaving'
import { ApiQuoteTemplate } from './entityLoaders/useLoadQuoteTemplate'
import { QuoteTemplateUpdateBody } from './entitySaving/useSaveQuoteTemplate'

type UseSaveQuoteTemplateEditor = () => {
  createQuoteTemplate: () => Promise<{
    success: boolean
    quoteTemplate: ApiQuoteTemplate | null
  }>
  updateQuoteTemplate: (id: string) => (
    data: QuoteTemplateUpdateBody
  ) => Promise<{
    success: boolean
    quoteTemplate: ApiQuoteTemplate | null
  }>
}

export const useSaveQuoteTemplateEditor: UseSaveQuoteTemplateEditor = () => {
  const quoteTemplateMutator = useSaveQuoteTemplate()

  const createQuoteTemplate = useCallback(async () => {
    const newQuoteTemplate = await quoteTemplateMutator.create()

    if (newQuoteTemplate === null) {
      return {
        success: false,
        quoteTemplate: null
      }
    }

    return {
      success: true,
      quoteTemplate: newQuoteTemplate
    }
  }, [quoteTemplateMutator])

  const updateQuoteTemplate = useCallback(
    (id: string) => async (data: QuoteTemplateUpdateBody) => {
      const updatedQuoteTemplate = await quoteTemplateMutator.update(id, data)

      if (updatedQuoteTemplate === null) {
        return {
          success: false,
          quoteTemplate: null
        }
      }

      return {
        success: true,
        quoteTemplate: updatedQuoteTemplate
      }
    },
    [quoteTemplateMutator]
  )

  return {
    createQuoteTemplate,
    updateQuoteTemplate
  }
}
