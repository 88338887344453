import { FC, useEffect } from 'react'
import { BulkActionsAction, BulkActions } from '@sequencehq/core-components'
import { match } from 'ts-pattern'
import { BulkAction, InvoiceLite } from './types'
import { allCompletedIds, allIds } from './utils'
import { Link } from '@chakra-ui/react'
import { IndigoIndigo50, Lato13Bold } from '@sequencehq/design-tokens'
import { apiQueryClient } from 'features/api/apiQueryClient'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'

function actionList(
  bulkAction: BulkAction,
  invoices: Record<string, InvoiceLite>
) {
  const ids = allIds(bulkAction)

  return ids
    .map(id => {
      const invoice = invoices[id]
      const isComplete = bulkAction.completedIds.includes(id)
      const isErrored = bulkAction.failedIds.includes(id)
      return toAction(invoice, isComplete, isErrored)
    })
    .filter(Boolean) as BulkActionsAction[]
}

function titleForAction(invoice: InvoiceLite) {
  /**
   * This can happen in the niche scenario the enrichment fails.
   * The invoice is still, probably, accessible via the ID, but
   * we don't know anything else about it yet.
   */
  if (!invoice.success) {
    return 'Invoice'
  }

  if (invoice.data.invoiceNumber) {
    return invoice.data.invoiceNumber
  }

  return invoice.data.status === 'IN_PROGRESS'
    ? 'Upcoming invoice'
    : 'Draft invoice'
}

function toAction(
  invoice: InvoiceLite,
  isComplete: boolean,
  isErrored: boolean
): BulkActionsAction | null {
  if (!invoice.success) {
    return null
  }

  return {
    id: invoice.id,
    title: (
      <Link
        target="_blank"
        href={`/invoices/${invoice.data.id}`}
        {...Lato13Bold}
        color={IndigoIndigo50}
      >
        {titleForAction(invoice)}
      </Link>
    ),
    status: isErrored ? 'failure' : isComplete ? 'success' : 'pending'
  }
}

function titleForBulkAction(
  bulkAction: Pick<
    BulkAction,
    'type' | 'status' | 'requestedIds' | 'completedIds' | 'failedIds'
  >
) {
  const progress = `${allCompletedIds(bulkAction).length}/${allIds(bulkAction).length}`
  return match(bulkAction)
    .with(
      { type: 'UPDATE_INVOICES_STATUS', status: 'PROCESSING' },
      () => `Updating status for ${progress} invoices`
    )
    .with(
      { type: 'UPDATE_INVOICES_STATUS' },
      () => `Updating status for invoices complete`
    )
    .with(
      { type: 'VOID_INVOICES', status: 'PROCESSING' },
      () => `Voiding ${progress} invoices`
    )
    .with({ type: 'VOID_INVOICES' }, () => 'Voiding invoices complete')
    .with(
      { type: 'RECALCULATE_INVOICES', status: 'PROCESSING' },
      () => `Recalculating ${progress} invoices`
    )
    .with(
      { type: 'RECALCULATE_INVOICES' },
      () => 'Recalculating invoices complete'
    )
    .with(
      { type: 'DOWNLOAD_INVOICES', status: 'PROCESSING' },
      () => `Downloading ${progress} invoices`
    )
    .with({ type: 'DOWNLOAD_INVOICES' }, () => 'Downloading invoices complete')
    .with(
      { type: 'FINALISE_INVOICES', status: 'PROCESSING' },
      () => `Finalizing ${progress} invoices`
    )
    .with({ type: 'FINALISE_INVOICES' }, () => 'Finalizing invoices complete')
    .with(
      { type: 'FINALISE_AND_SEND_INVOICES', status: 'PROCESSING' },
      () => `Finalizing and sending ${progress} invoices`
    )
    .with(
      { type: 'FINALISE_AND_SEND_INVOICES' },
      () => 'Finalizing and sending invoices complete'
    )
    .with(
      { type: 'SEND_INVOICES', status: 'PROCESSING' },
      () => `Sending ${progress} invoices`
    )
    .with({ type: 'SEND_INVOICES' }, () => 'Sending invoices complete')
    .exhaustive()
}

const useInvalidateInvoicesCacheOnProgress = (props: {
  bulkAction: BulkAction
}) => {
  const completedInvoiceCount = allCompletedIds(props.bulkAction).length

  useEffect(() => {
    if (completedInvoiceCount > 0) {
      apiQueryClient.invalidateQueries({
        queryKey: dashboardv20240509Client.getInvoices.invalidateQueries
      })
    }
  }, [completedInvoiceCount])
}

export const BulkActionInFlight: FC<{
  bulkAction: BulkAction
  onDismiss: () => void
  invoices: Record<string, InvoiceLite>
  isReviewing: boolean
  onChangeReviewing: (isReviewing: boolean) => void
}> = ({ bulkAction, onDismiss, invoices, isReviewing, onChangeReviewing }) => {
  const actions = actionList(bulkAction, invoices)
  useInvalidateInvoicesCacheOnProgress({ bulkAction })

  return (
    <BulkActions
      title={titleForBulkAction(bulkAction)}
      actions={actions}
      listTitle="Invoices"
      onDismiss={onDismiss}
      isReviewing={isReviewing}
      onChangeReviewing={onChangeReviewing}
    />
  )
}
