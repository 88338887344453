import { PricingStructure } from '@sequencehq/api/src/clients/dashboard/v20240730/commonModels/pricingStructure'
import {
  BillingFrequency,
  DiscountCalculationType,
  PriceModel
} from '@sequencehq/core-models'
import { match } from 'ts-pattern'
import {
  applyDiscountToAmount,
  getPriceStructureAmount
} from '../prices/calculateDiscountedPrice'

export type Discount = {
  restrictToPrices: string[]
  amount: string
  discountCalculationType: DiscountCalculationType
}

export type Price = {
  structure: PricingStructure
  billingFrequency: BillingFrequency
  id?: string
  temporaryId?: string
}

export type Minimum = {
  id: string
  amount: string
  restrictToPrices: string[]
}

export type Phase = {
  discounts?: Discount[]
  prices: Price[]
  duration?: {
    months?: number
    years?: number
  }
  minimums: Minimum[]
}

const getDiscountPriceIdMap = (discounts: Discount[]) => {
  if (discounts[0]?.restrictToPrices.length === 0) {
    return {
      applyToAllPrices: discounts[0]
    }
  }

  return discounts.reduce((discountPriceIdMap, discount) => {
    return {
      ...discountPriceIdMap,
      ...discount.restrictToPrices.reduce(
        (curr, priceId) => ({
          ...curr,
          [priceId]: discount
        }),
        {}
      )
    }
  }, {})
}

const getSeatBasedPriceRate = (price: Price, discount?: Discount) => {
  if (price.structure.pricingType !== 'SEAT_BASED') {
    throw new Error('using getSeatBasedPrice with invalid price')
  }

  // Seat-based, linear
  if (!price.structure.tiers?.length) {
    const linearPricePerSeat = getPriceStructureAmount(
      price as Pick<PriceModel, 'structure'>
    )

    const linearContractedMinimumTotal =
      linearPricePerSeat * price.structure.contractedMinimumSeats

    return discount
      ? applyDiscountToAmount({
          amount: linearContractedMinimumTotal,
          discount: {
            amount: Number(discount.amount),
            discountCalculationType: discount.discountCalculationType
          }
        })
      : linearContractedMinimumTotal
  }

  // Seat-based, graduated
  const { contractedMinimumSeats, tiers } = price.structure

  const total = tiers.reduce((acc, tier, tierIndex) => {
    // Apply the fee from the first tier if no contracted minimum seats
    if (contractedMinimumSeats === 0) {
      if (tierIndex === 0 && tier.fee) {
        const firstTierFeeFull = Number(tier.fee)
        const firstTierFee = discount
          ? applyDiscountToAmount({
              amount: firstTierFeeFull,
              discount: {
                amount: Number(discount.amount),
                discountCalculationType: discount.discountCalculationType
              }
            })
          : firstTierFeeFull

        return acc + firstTierFee
      }

      return acc
    }

    const minInclusive: number =
      tierIndex === 0 ? 1 : Number(tiers[tierIndex - 1].upperBound) + 1
    const maxInclusive: number = tier.upperBound
      ? Number(tier.upperBound)
      : Infinity

    const seatsConsumed: number = Array.from({
      length: contractedMinimumSeats
    }).reduce((acc: number, _, seatsConsumedIndex: number) => {
      const seatNumber = seatsConsumedIndex + 1

      if (seatNumber >= minInclusive && seatNumber <= maxInclusive) {
        return acc + 1
      }

      return acc
    }, 0)

    const pricePerSeat = Number(tier.price)

    const totalForSeatsFull = seatsConsumed * pricePerSeat
    const totalForSeats = discount
      ? applyDiscountToAmount({
          amount: totalForSeatsFull,
          discount: {
            amount: Number(discount.amount),
            discountCalculationType: discount.discountCalculationType
          }
        })
      : totalForSeatsFull

    const totalForFixedFeeFull =
      seatsConsumed > 0 && tier.fee ? Number(tier.fee) : 0
    const totalForFixedFee = discount
      ? applyDiscountToAmount({
          amount: totalForFixedFeeFull,
          discount: {
            amount: Number(discount.amount),
            discountCalculationType: discount.discountCalculationType
          }
        })
      : totalForFixedFeeFull

    return acc + totalForSeats + totalForFixedFee
  }, 0)

  return total
}

const getPriceFullRate = (price: Price) => {
  if (price.structure.pricingType === 'SEAT_BASED') {
    return getSeatBasedPriceRate(price)
  }

  if (
    price.structure.pricingType === 'FIXED' ||
    price.structure.pricingType === 'ONE_TIME' ||
    price.structure.pricingType === 'GRADUATED'
  ) {
    const amount = getPriceStructureAmount(
      price as Pick<PriceModel, 'structure'>
    )

    return amount
  }
}

const TOTALS_SUPPORTED_PRICING_TYPES: PricingStructure['pricingType'][] = [
  'ONE_TIME',
  'FIXED',
  'SEAT_BASED',
  'GRADUATED'
]

const MINIMUMS_SUPPORTED_PRICING_TYPES: PricingStructure['pricingType'][] = [
  'LINEAR',
  'VOLUME',
  'GRADUATED',
  'PACKAGE'
]

export const calculatePhaseTotalAmount = (
  phases: Phase[]
): { totalFullRate: number; totalDiscountedRate: number } => {
  return phases.reduce(
    (accum, phase) => {
      if (!phase.duration) {
        return accum
      }

      const discountPriceIdMap: Record<string, Discount> =
        phase.discounts && phase.discounts.length > 0
          ? getDiscountPriceIdMap(phase.discounts)
          : {}

      const durationInMonths =
        (phase.duration?.months ?? 0) + (phase.duration?.years ?? 0) * 12

      const price = phase.prices.reduce(
        (acc, price) => {
          const isPricingTypeSupported =
            TOTALS_SUPPORTED_PRICING_TYPES.includes(price.structure.pricingType)

          if (!isPricingTypeSupported) {
            return acc
          }

          const amountMultiplier = getAmountMultiplier(
            price.billingFrequency,
            durationInMonths
          )

          const priceFullRate = getPriceFullRate(price)

          /**
           * HACK: it's unlikely for there to be both a flat fee and a minimum for a graduated product. To avoid the
           * complexity of adjusting the true-up fees on minimums to deal with graduated flat fees, and the interaction
           * with discounts, this function doesn't add flat fees to the total if there is a global minimum, or a minimum
           * that involves a graduated price.
           *
           * Source: https://sequenceslack.slack.com/archives/C05SSSNC487/p1738862037700999?thread_ts=1738851049.283639&cid=C05SSSNC487
           */
          const ignoreGraduatedFlatFees: boolean = checkIgnoreGraduatedFlatFees(
            phase,
            price
          )

          if (!priceFullRate || ignoreGraduatedFlatFees) {
            return acc
          }

          const priceId = price.temporaryId ?? price.id

          // Apply discounts to selected prices
          const discount = discountPriceIdMap[priceId!] ?? undefined

          const priceDiscountedRate = getPriceDiscountedRate({
            discount,
            priceFullRate,
            price
          })

          return {
            priceFullRate: acc.priceFullRate + priceFullRate * amountMultiplier,
            priceDiscountedRate:
              acc.priceDiscountedRate + priceDiscountedRate * amountMultiplier
          }
        },
        {
          priceFullRate: 0,
          priceDiscountedRate: 0
        }
      )

      const totalMinimum = getTotalMinimum(phase, durationInMonths)

      const totalFullRate =
        accum.totalFullRate + price.priceFullRate + totalMinimum

      const preGlobalDiscountRate =
        accum.totalDiscountedRate + price.priceDiscountedRate + totalMinimum

      // Apply any global discounts
      const globalDiscount = discountPriceIdMap['applyToAllPrices']
      const totalDiscountedRate = globalDiscount
        ? applyGlobalDiscount({
            globalDiscount,
            preGlobalDiscountRate,
            durationInMonths
          })
        : preGlobalDiscountRate

      return { totalFullRate, totalDiscountedRate }
    },
    { totalFullRate: 0, totalDiscountedRate: 0 }
  )
}

const checkIgnoreGraduatedFlatFees = (phase: Phase, price: Price): boolean => {
  const minimum = phase.minimums.at(0)

  // don't ignore if this price isn't graduated, or if there is no minimums
  if (price.structure.pricingType !== 'GRADUATED' || !minimum) {
    return false
  }

  // ignore if minimum applies to all prices
  if (minimum.restrictToPrices.length === 0) {
    return true
  }

  // don't ignore if the minimum doesn't apply to a graduated price
  const hasGraduatedPriceInMinimum: boolean = minimum.restrictToPrices.some(
    minimumPriceId =>
      phase.prices.find(p => {
        const id = p.temporaryId ?? p.id
        return id === minimumPriceId
      })?.structure.pricingType === 'GRADUATED'
  )

  return hasGraduatedPriceInMinimum
}

const getAmountMultiplier = (
  billingFrequency: BillingFrequency,
  durationInMonths: number
): number => {
  return (
    match(billingFrequency)
      .with('MONTHLY', () => durationInMonths)
      .with('QUARTERLY', () => durationInMonths / 3)
      .with('YEARLY', () => durationInMonths / 12)
      .with('HALF_YEARLY', () => durationInMonths / 6)
      .with('ONE_TIME', () => 1)
      /**
       * ON_DEMAND billing frequency is supported by the GRADUATED billing frequency, ignore the flat fee if set
       */
      .with('ON_DEMAND', () => 0)
      .exhaustive()
  )
}

const getTotalMinimum = (phase: Phase, durationInMonths: number): number => {
  if (phase.minimums.length === 0) {
    return 0
  }

  const minimumsSupportedPrices = phase.prices.filter(price =>
    MINIMUMS_SUPPORTED_PRICING_TYPES.includes(price.structure.pricingType)
  )

  const firstMinimumsSupportedPrice = minimumsSupportedPrices.at(0)

  if (!firstMinimumsSupportedPrice) {
    return 0
  }

  const amountMultiplier = getAmountMultiplier(
    firstMinimumsSupportedPrice.billingFrequency,
    durationInMonths
  )

  return amountMultiplier * Number(phase.minimums[0].amount)
}

const applyGlobalDiscount = ({
  globalDiscount,
  preGlobalDiscountRate,
  durationInMonths
}: {
  globalDiscount: Discount
  preGlobalDiscountRate: number
  durationInMonths: number
}): number => {
  const postGlobalDiscountRate = match(globalDiscount.discountCalculationType)
    .with('PERCENTAGE', () => {
      return (
        preGlobalDiscountRate * ((100 - Number(globalDiscount.amount)) / 100)
      )
    })
    .with('NOMINAL', () => {
      return (
        preGlobalDiscountRate - Number(globalDiscount.amount) * durationInMonths
      )
    })
    .exhaustive()

  return Math.max(0, postGlobalDiscountRate)
}

const getPriceDiscountedRate = ({
  discount,
  priceFullRate,
  price
}: { discount: Discount; priceFullRate: number; price: Price }): number => {
  if (!discount) {
    return priceFullRate
  }

  /**
   * The discounted rate for seat-based prices will be incorrect for fixed discounts if we use the priceFullRate value,
   * as the discount will have been applied to the sum of minimum seats and flat fees, rather than on these individual
   * parts of the total
   */
  if (price.structure.pricingType === 'SEAT_BASED') {
    return getSeatBasedPriceRate(price, discount)
  }

  return applyDiscountToAmount({
    amount: priceFullRate,
    discount: {
      amount: Number(discount.amount),
      discountCalculationType: discount.discountCalculationType
    }
  })
}
