import { useCallback } from 'react'
import { Currency } from '@sequencehq/core-models'
import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { useMutation } from '@sequencehq/api/utils'

export const useSaveCreditNote = () => {
  const { mutateAsync: postCreditNote } = useMutation(
    dashboardv99990101Client.postCreditNote
  )

  const createFromInvoice = useCallback(
    async (
      invoiceId: string,
      currency: Currency,
      customerId: string,
      billingPeriodStart: string | undefined,
      billingPeriodEndInclusive: string | undefined,
      customerTaxId?: string
    ) => {
      try {
        const creditNote = await postCreditNote({
          body: {
            currency,
            customerId,
            invoiceId,
            billingPeriodStart: billingPeriodStart
              ? `${billingPeriodStart}T00:00:00Z`
              : undefined,
            billingPeriodEnd: billingPeriodEndInclusive
              ? `${billingPeriodEndInclusive}T00:00:00Z`
              : undefined,
            customerTaxId
          }
        })
        if (!creditNote) {
          return { creditNote: null, success: false }
        }
        return { creditNote, success: true }
      } catch {
        return { creditNote: null, success: false }
      }
    },
    [postCreditNote]
  )

  const createLineItems = useCallback(
    (creditNoteId: string) =>
      async (
        lineItemIds: string[]
      ): Promise<{
        creditNote: { id: string } | null
        success: boolean
      }> => {
        const response = await dashboardv20240509Client.postCreditNoteLineItems(
          {
            id: creditNoteId,
            body: {
              lineItemIds
            }
          }
        )

        if (response.error) {
          return { creditNote: null, success: false }
        }

        // Request doesn't return a response body
        return { creditNote: { id: creditNoteId }, success: true }
      },
    []
  )

  return {
    createFromInvoice,
    createLineItems
  }
}
