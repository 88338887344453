import { useGetInvoicesQuery } from 'features/api'
import { format } from '@sequencehq/utils/dates'
import { useQuery } from '@sequencehq/api'
import {
  CalendarDaysIcon,
  IdentificationIcon,
  RectangleStackIcon
} from '@heroicons/react/16/solid'
import { OverviewCard } from '@sequencehq/core-components'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { dashboardv20240509Client } from '@sequencehq/api/dist/clients/dashboard/v20240509'

const humanReadableQuantity = (quantity: number = 0) => {
  if (quantity > 999) return '999+'
  return quantity
}

export const DraftBillingSchedulesCard = () => {
  const { data, isLoading } = useQuery(
    dashboard20240730Client.getBillingSchedules,
    {
      billingScheduleStatus: 'DRAFT',
      limit: 1
    }
  )

  return (
    <OverviewCard
      isLoading={isLoading}
      href="/billing-schedules?billingScheduleStatus=DRAFT"
      quantity={humanReadableQuantity(data?.pagination.totalResultSize)}
      title="Draft schedules"
      description={
        !isLoading && data?.pagination.totalResultSize === 0
          ? 'No draft schedules to review.'
          : 'Activate draft schedules to start billing cycles.'
      }
      icon={<CalendarDaysIcon />}
    />
  )
}

export const DraftInvoicesCard = () => {
  const { data, isLoading } = useGetInvoicesQuery({
    limit: 1,
    invoiceStatus: 'DRAFT'
  })

  const invoicesTotal = data?.value()?.pagination.totalResultSize

  return (
    <OverviewCard
      isLoading={isLoading}
      href="/invoices?invoiceStatus=DRAFT"
      quantity={humanReadableQuantity(invoicesTotal)}
      title="Draft invoices"
      description={
        !isLoading && invoicesTotal === 0
          ? 'No draft invoices to review.'
          : 'Review and send invoices to customers.'
      }
      icon={<RectangleStackIcon />}
    />
  )
}

export const OverdueInvoicesCard = () => {
  const today = format(new Date(), 'yyyy-MM-dd')

  const { data, isLoading } = useQuery(dashboardv20240509Client.getInvoices, {
    invoiceStatus: 'SENT',
    invoicePaymentStatus: 'UNPAID',
    dueBefore: today,
    limit: 1
  })

  return (
    <OverviewCard
      isLoading={isLoading}
      href={`/invoices?invoiceStatus=SENT&invoicePaymentStatus=UNPAID&dueBefore=${today}`}
      variant="red"
      quantity={humanReadableQuantity(data?.pagination.totalResultSize)}
      title="Overdue invoices"
      description={
        !isLoading && data?.pagination.totalResultSize === 0
          ? 'No overdue invoices.'
          : 'Send reminders for overdue invoices.'
      }
      icon={<RectangleStackIcon />}
    />
  )
}

export const UnbilledCustomersCard = () => {
  const { data, isLoading } = useQuery(dashboard20240730Client.getCustomers, {
    billingScheduleStatus: 'DRAFT',
    limit: 1
  })

  return (
    <OverviewCard
      isLoading={isLoading}
      href="/customers"
      quantity={data?.pagination.totalResultSize ?? 0}
      title="Total customers"
      description={
        !isLoading && data?.pagination.totalResultSize === 0
          ? 'Start creating customers.'
          : 'Create new schedules for unbilled customers.'
      }
      icon={<IdentificationIcon />}
    />
  )
}
