import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { SequenceMenuItem, SimpleDialog } from '@sequencehq/core-components'
import { RedRed50 } from '@sequencehq/design-tokens'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ReactNode, useState } from 'react'

const useArchiveQuoteTemplate = (props: {
  quoteTemplateId: string
  onSuccess?: () => void
}) => {
  const { displayNotification } = useNotifications()
  const archiveQuoteTemplateMutation = useMutation(
    dashboard20240730Client.postArchiveQuoteTemplate
  )

  const archiveQuoteTemplate = async () => {
    const archivedTemplateResponse =
      await archiveQuoteTemplateMutation.mutateAsync({
        id: props.quoteTemplateId
      })

    if (!archivedTemplateResponse) {
      displayNotification('Failed to archive template', {
        type: 'error'
      })
      return false
    }

    displayNotification('Template archived', {
      type: 'success'
    })
    props.onSuccess?.()
    return true
  }
  return {
    isSubmitting: archiveQuoteTemplateMutation.isPending,
    archiveQuoteTemplate
  }
}

const ArchiveQuoteTemplateModal = (props: {
  trigger: ReactNode
  quoteTemplateId: string
  onSuccess?: () => void
}) => {
  const { isSubmitting, archiveQuoteTemplate } = useArchiveQuoteTemplate({
    quoteTemplateId: props.quoteTemplateId,
    onSuccess: props.onSuccess
  })

  return (
    <SimpleDialog
      trigger={props.trigger}
      title="Archive template"
      confirmButtonText="Archive"
      submittingButtonText="Archiving..."
      onConfirm={archiveQuoteTemplate}
      isSubmitting={isSubmitting}
      destructive
    >
      This will archive the template immediately. This action cannot be undone.
    </SimpleDialog>
  )
}

export const ArchiveQuoteTemplateMenuItem = (props: {
  quoteTemplateId: string
  disabled?: boolean
  onSuccess?: () => void
}) => {
  return (
    <ArchiveQuoteTemplateModal
      quoteTemplateId={props.quoteTemplateId}
      trigger={
        <SequenceMenuItem
          uuid="archive-quote-template"
          label="Archive template"
          isDisabled={props.disabled}
          style={{ color: RedRed50 }}
        />
      }
    />
  )
}
