import { FC } from 'react'
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalContent,
  ModalOverlay
} from '@chakra-ui/react'
import {
  GreyGrey0,
  GreyGrey100,
  GreyGrey80,
  Lato14ExtendedRegular,
  Lato16Bold
} from '@sequencehq/design-tokens'

type ConfirmDraftInvoiceModalProps = {
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmDraftInvoiceModal: FC<ConfirmDraftInvoiceModalProps> = ({
  onConfirm,
  onClose
}) => {
  return (
    <Modal isOpen onClose={onClose} isCentered>
      <ModalOverlay>
        <ModalContent
          backgroundColor={GreyGrey0}
          padding="16px"
          borderRadius="8px"
        >
          <Box color={GreyGrey100} {...Lato16Bold} marginBottom="4px">
            Convert upcoming invoice to draft
          </Box>
          <Box
            color={GreyGrey80}
            {...Lato14ExtendedRegular}
            marginBottom="24px"
          >
            You will be able to finalize and send the invoice once it is
            drafted. You cannot convert this invoice back to upcoming.
          </Box>
          <Flex gap="8px" justifyContent="space-between">
            <Button
              variant="secondary2"
              onClick={onClose}
              height="32px"
              width="100%"
            >
              Cancel
            </Button>
            <Button
              variant="primary2"
              onClick={onConfirm}
              height="32px"
              width="100%"
              data-testid="convert-to-draft.confirm"
            >
              Convert to draft
            </Button>
          </Flex>
        </ModalContent>
      </ModalOverlay>
    </Modal>
  )
}
