import { useState } from 'react'
import {
  InputSelectControlField,
  SimpleModalUI,
  withUnmountOnModalClose
} from '@sequencehq/core-components'
import { Flex } from '@chakra-ui/react'
import {
  GreyGrey70,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { useNotifications } from 'lib/hooks/useNotifications.tsx'
import { useForm } from '@sequencehq/utils'
import { required } from '@sequencehq/validation'
import { useQuery } from '@sequencehq/api'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import Spinner from 'components/Loading'
import { QBO_ACCOUNT_TYPES_ALLOWING_ITEM_PUSH } from 'Integrations/integrationsConfig/quickBooks/constants'

interface Props {
  onConfirm?: () => void
  productId: string
}

export const LinkQuickbooksItemModal = withUnmountOnModalClose(
  ({ onConfirm, productId }: Props) => {
    const [isCreating, setIsCreating] = useState(false)
    const { displayNotification } = useNotifications()

    const { data, isLoading } = useQuery(
      dashboardv99990101Client.getQuickbooksAccounts
    )

    const quickbooksAccounts = data?.items ?? []

    const quickbooksAccountsOptions = quickbooksAccounts
      .filter(account =>
        QBO_ACCOUNT_TYPES_ALLOWING_ITEM_PUSH.includes(account.type ?? '')
      )
      .map(account => ({
        label: account.name ?? account.id,
        value: account.id
      }))

    const { fields, queries } = useForm<{ quickbooksAccountId: string }>({
      value: {
        quickbooksAccountId: ''
      },
      fieldConfiguration: [
        {
          property: 'quickbooksAccountId',
          validation: [required]
        }
      ]
    })

    if (isLoading) {
      return (
        <SimpleModalUI
          title={`Create item in QuickBooks`}
          cancelButtonText="Cancel"
          submitButtonText="Create item"
          submitDisabled
          onSubmit={() => null}
          contentProps={{
            minHeight: '0px'
          }}
        >
          <Flex
            height="56px"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Spinner color={GreyGrey70} />
          </Flex>
        </SimpleModalUI>
      )
    }

    const linkProductToQuickbooksAccount = async () => {
      const result = await dashboardv99990101Client.postPushProductToQuickbooks(
        {
          productId,
          accountId: fields.quickbooksAccountId.value
        }
      )

      return result
    }

    const handleSubmit = async () => {
      setIsCreating(true)

      const { error } = await linkProductToQuickbooksAccount()

      if (error) {
        displayNotification(`Could not create item in QuickBooks`, {
          type: 'error'
        })
        setIsCreating(false)
        return
      }

      displayNotification(`Product created in QuickBooks`, {
        type: 'success'
      })

      setIsCreating(false)
      onConfirm?.()
    }

    return (
      <SimpleModalUI
        title={`Create item in QuickBooks`}
        cancelButtonText="Cancel"
        submitButtonText="Create item"
        submitDisabled={!queries.isValid || isCreating}
        onSubmit={handleSubmit}
        contentProps={{
          minHeight: '0px'
        }}
      >
        <Flex
          color={GreyGrey80}
          {...Lato13Regular}
          flexDirection="column"
          gap="16px"
        >
          <InputSelectControlField
            {...fields.quickbooksAccountId}
            label="Account code"
            data-testid="linkToAccount"
            placeholder="Select an account code..."
            options={quickbooksAccountsOptions}
            styles={{
              wrapper: { marginBottom: '0' }
            }}
          />
        </Flex>
      </SimpleModalUI>
    )
  }
)
