import { Box, Image } from '@chakra-ui/react'
import {
  GreyGrey60,
  GreyGrey80,
  GreyWhite,
  Lato12Regular,
  ShadowM,
  ShadowXs
} from '@sequencehq/design-tokens'
import placeholderImage from './placeholderImage.png'
import {
  SequenceKebabMenu,
  SequenceMenuDivider,
  Skeleton,
  TextOverflow
} from '@sequencehq/core-components'
import {
  CreateQuoteFromTemplateMenuItem,
  useCreateQuoteFromTemplate
} from 'common/menuItems/quotes/CreateQuoteFromTemplateMenuItem'
import { ViewQuoteTemplateMenuItem } from './ViewQuoteTemplateMenuItem'
import { ArchiveQuoteTemplateMenuItem } from 'common/menuItems/quotes/ArchiveQuoteTemplateMenuItem'
import { formatDistanceToNow } from '@sequencehq/utils/dist/dates'
import { DuplicateQuoteTemplateMenuItem } from 'common/menuItems/quotes/DuplicateQuoteTemplateMenuItem'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNavigate } from 'react-router-dom'

const useQuoteTemplateItem = (props: {
  quoteTemplateId: string
}) => {
  const navigate = useNavigate()
  const quoteTemplateQuery = useQuery(
    dashboard20240730Client.getQuoteTemplate,
    {
      id: props.quoteTemplateId
    },
    {
      select: res => {
        if (!res?.template) {
          return
        }

        return {
          title: res?.template.title,
          updatedAt: formatDistanceToNow(
            new Date(res?.template.updatedAt ?? res?.template.createdAt)
          )
        }
      }
    }
  )

  const onQuoteCreation = (newQuoteId: string) => {
    navigate(`/quotes/${newQuoteId}`)
  }

  const onQuoteTemplateCreation = (newQuoteTemplateId: string) => {
    navigate(`/quote-templates/${newQuoteTemplateId}`)
  }

  const { createQuoteFromTemplate, creationInProgress } =
    useCreateQuoteFromTemplate({
      quoteTemplateId: props.quoteTemplateId,
      onSuccess: onQuoteCreation
    })

  return {
    title: quoteTemplateQuery.data?.title ?? 'Untitled',
    updatedAt: quoteTemplateQuery.data?.updatedAt ?? 'Unknown',
    isLoading: quoteTemplateQuery.isLoading,
    previewImageSrc: placeholderImage,
    actions: {
      createQuoteFromTemplate: () => {
        if (creationInProgress) {
          return
        }
        createQuoteFromTemplate()
      }
    },
    onQuoteCreation,
    onQuoteTemplateCreation
  }
}

export const TemplateItemSkeleton = () => {
  return (
    <Box width="144px">
      <Box marginBottom="8px">
        <Skeleton width="100%" height="180px" />
      </Box>
      <Box marginBottom="4px">
        <Skeleton width="50%" height="16px" />
      </Box>
      <Skeleton width="100%" height="16px" />
    </Box>
  )
}

export const QuoteTemplateItem = (props: {
  quoteTemplateId: string
}) => {
  const hook = useQuoteTemplateItem({
    quoteTemplateId: props.quoteTemplateId
  })

  if (hook.isLoading) {
    return <TemplateItemSkeleton />
  }

  return (
    <Box width="144px">
      <Box
        marginBottom="8px"
        height="180px"
        borderRadius="6px"
        border={`1px solid rgba(0,0,0,0.08)`}
        boxShadow={ShadowXs}
        cursor="pointer"
        backgroundColor={GreyWhite}
        transition={`box-shadow 0.25s`}
        role="group"
        _hover={{
          boxShadow: ShadowM
        }}
        overflow="hidden"
        position="relative"
        onClick={() => hook.actions.createQuoteFromTemplate()}
      >
        <Box
          position="absolute"
          top="8px"
          right="8px"
          color={GreyWhite}
          boxShadow={ShadowXs}
          borderRadius="4px"
          zIndex="2"
          visibility="hidden"
          _groupHover={{
            visibility: 'visible'
          }}
          onClick={e => e.stopPropagation()}
        >
          <SequenceKebabMenu
            buttonSize="small"
            items={[
              <CreateQuoteFromTemplateMenuItem
                key="create-quote-from-template"
                quoteTemplateId={props.quoteTemplateId}
                onSuccess={hook.onQuoteCreation}
              />,
              <ViewQuoteTemplateMenuItem
                key="view-quote-template"
                quoteTemplateId={props.quoteTemplateId}
              />,
              <>
                <SequenceMenuDivider key="duplicate-divider" />
                <DuplicateQuoteTemplateMenuItem
                  key="duplicate-quote-template"
                  quoteTemplateId={props.quoteTemplateId}
                  onSuccess={hook.onQuoteTemplateCreation}
                />
              </>,
              <>
                <SequenceMenuDivider key="archive-quote-divider" />
                <ArchiveQuoteTemplateMenuItem
                  key="archive-quote-template"
                  quoteTemplateId={props.quoteTemplateId}
                />
              </>
            ]}
          />
        </Box>
        <Box
          height="100%"
          width="100%"
          overflow="hidden"
          transition="transform 0.25s"
          _groupHover={{
            transform: 'scale(1.05)'
          }}
          zIndex="1"
        >
          <Image src={hook.previewImageSrc} width="100%" />
        </Box>
      </Box>
      <Box marginBottom="4px" {...Lato12Regular} color={GreyGrey80}>
        <TextOverflow splitPercentage={100}>{hook.title}</TextOverflow>
      </Box>
      <Box {...Lato12Regular} color={GreyGrey60}>
        <TextOverflow splitPercentage={100}>
          Last edited {hook.updatedAt} ago
        </TextOverflow>
      </Box>
    </Box>
  )
}
