import React, { FC, useEffect } from 'react'
import {
  Popover,
  PopoverAnchor,
  PopoverBody,
  PopoverContent,
  useDisclosure
} from '@chakra-ui/react'
import {
  InvoicePaymentStatus,
  toInvoicePaymentStatusBadgeProps
} from '@sequencehq/core-models'
import {
  FinalizeInvoicesDialog,
  InvoiceBulkActionBar,
  MergeInvoicesDialog,
  RecalculateInvoicesDialog,
  SendInvoicesDialog,
  VoidInvoicesDialog
} from '@sequencehq/core-components'
import {
  Dashboardv20240509Api,
  dashboardv20240509Client
} from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { ShadowL } from '@sequencehq/design-tokens'
import { useMutation } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import invariant from 'tiny-invariant'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useNotifications } from 'lib/hooks/useNotifications'
import { apiQueryClient } from 'features/api/apiQueryClient'

type Invoice = Dashboardv20240509Api.GetInvoices.Invoice

export type InvoiceBulkActionsProps = {
  children: React.ReactNode
  selectedRows: Invoice[]
  clearSelectedRows: () => void
  popoverOffset?: [number, number]
}

type Action =
  | 'finalize'
  | 'send'
  | 'void'
  | 'merge'
  | 'markAs'
  | 'finalizeAndSend'
  | 'recalculate'

export const InvoiceBulkActions: FC<InvoiceBulkActionsProps> = ({
  children,
  selectedRows,
  clearSelectedRows,
  popoverOffset
}) => {
  const { isOpen, onToggle, onClose } = useDisclosure()
  const { displayNotification } = useNotifications()
  const mergeInvoices = useMutation(dashboard20240730Client.postMergeInvoices)
  const flags = useFlags()

  //['finalize', 'send', 'send', 'markAs']

  // if selected row is draft - posible actions are finalize and send
  // if selected row is final - posible actions are send
  // if selected row is sent - possible actions are mark as

  // filter out actions that are not possible

  // if action is finalize filter selected rows to only include draft
  // if action is send filter selected rows to only include draft and final

  // if selectedRows is empty, close the popover if it's open

  const filterSelectedRows = (action: Action) => {
    switch (action) {
      case 'finalize':
        return selectedRows.filter(row => row.status === 'DRAFT')

      case 'recalculate':
        return selectedRows.filter(
          row =>
            ['IN_PROGRESS', 'DRAFT'].includes(row.status) &&
            !!row.billingScheduleId
        )

      case 'send':
        return selectedRows.filter(
          row => row.status === 'DRAFT' || row.status === 'FINAL'
        )

      case 'markAs':
        return selectedRows.filter(row => row.status === 'SENT')

      case 'void':
        return selectedRows.filter(
          row => row.status === 'DRAFT' || row.status === 'FINAL'
        )

      case 'merge':
        return selectedRows.filter(row => row.status !== 'DRAFT').length ===
          0 &&
          new Set(selectedRows.map(row => row.customerId)).size === 1 &&
          new Set(selectedRows.map(row => row.currency)).size === 1 &&
          selectedRows.length > 1
          ? selectedRows
          : []
    }

    return selectedRows
  }

  const getSelectedRowsIds = (selected: Invoice[]) => {
    return selected.map(row => row.id)
  }

  useEffect(() => {
    if (selectedRows.length > 0 && !isOpen) {
      onToggle()
    }
    if (selectedRows.length === 0 && isOpen) {
      onClose()
    }
  }, [selectedRows, isOpen, onToggle, onClose])

  const finalizeSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const finalizeBulkActionRes =
      await dashboard20240730Client.postFinalizeMultipleInvoices({
        invoiceIds: filteredIds
      })

    if (finalizeBulkActionRes.error) {
      return displayNotification('Unable to finalize invoices', {
        type: 'error'
      })
    }

    onClose()
    clearSelectedRows()

    if (flags.useBulkActionsV2Frontend) return

    return displayNotification('Finalizing started', { type: 'success' })
  }

  const recalculateSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const recalculateBulkActionRes =
      await dashboard20240730Client.postRecalculateMultipleInvoices({
        invoiceIds: filteredIds
      })

    if (recalculateBulkActionRes.error) {
      clearSelectedRows()
      return displayNotification('Unable to recalculate invoices', {
        type: 'error'
      })
    }

    onClose()
    clearSelectedRows()

    if (flags.useBulkActionsV2Frontend) return

    return displayNotification('Recalculation started', {
      type: 'success'
    })
  }

  const finalizeAndSendSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const finalizeAndSendBulkActionRes =
      await dashboard20240730Client.postFinalizeAndSendMultipleInvoices({
        invoiceIds: filteredIds
      })

    if (finalizeAndSendBulkActionRes.error) {
      return displayNotification('Unable to finalize and send invoices', {
        type: 'error'
      })
    }

    clearSelectedRows()
    onClose()

    if (flags.useBulkActionsV2Frontend) return

    return displayNotification('Finalizing and sending started', {
      type: 'success'
    })
  }

  const sendSelectedInvoices = async (
    filtered: Invoice[],
    { showToast = true }: { showToast?: boolean } = {}
  ) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const sendBulkActionRes =
      await dashboard20240730Client.postSendMultipleInvoices({
        invoiceIds: filteredIds
      })

    if (sendBulkActionRes.error) {
      displayNotification('Unable to send invoices', { type: 'error' })
      return
    }

    clearSelectedRows()
    onClose()

    if (flags.useBulkActionsV2Frontend) return

    /**
     * We use this to prevent toast spam when used alongside finalizeAndSend
     */
    if (showToast) {
      displayNotification('Sending started', { type: 'success' })
    }
  }

  const voidSelectedInvoices = async (filtered: Invoice[]) => {
    const filteredIds = getSelectedRowsIds(filtered)

    const voidMultipleInvoicesBulkActionRes =
      await dashboard20240730Client.postVoidMultipleInvoices({
        invoiceIds: filteredIds
      })

    if (voidMultipleInvoicesBulkActionRes.error) {
      return displayNotification('Unable to void invoices', { type: 'error' })
    }

    clearSelectedRows()
    onClose()

    if (flags.useBulkActionsV2Frontend) return

    displayNotification('Voiding started', { type: 'success' })
  }

  const mergeSelectedInvoices = async (filtered: Invoice[]) => {
    const invoiceIds = filtered.map(invoice => invoice.id)

    try {
      const response = await mergeInvoices.mutateAsync({
        invoiceIds: invoiceIds
      })

      invariant(response, 'Failed to merge invoices')

      if (flags.useBulkActionsV2) return

      displayNotification('Invoices merged', { type: 'success' })

      clearSelectedRows()
    } catch {
      displayNotification('Unable to merge invoices', { type: 'error' })
    }
  }

  const markAsSelectedInvoices = async (
    status: 'UNPAID' | 'PARTIALLY_PAID' | 'PAID' | 'UNCOLLECTIBLE',
    filtered: Invoice[]
  ) => {
    const filteredIds: string[] = getSelectedRowsIds(filtered)

    const updateStatusRes =
      await dashboard20240730Client.putUpdatePaymentStatusMultipleInvoices({
        invoiceIds: filteredIds,
        paymentStatus: status
      })

    if (updateStatusRes.error) {
      return displayNotification('Unable to update invoice payment statuses', {
        type: 'error'
      })
    }

    clearSelectedRows()
    onClose()

    const humanReadableStatus = toInvoicePaymentStatusBadgeProps({
      status
    }).children.toLowerCase()

    if (flags.useBulkActionsV2) return

    displayNotification(`Marking invoices as ${humanReadableStatus}`, {
      type: 'success'
    })
  }

  const handleSubmitRows = (action: Action) => {
    const filteredSelectedRows = filterSelectedRows(action)

    if (filteredSelectedRows.length > 0) {
      switch (action) {
        case 'finalize':
          return finalizeSelectedInvoices(filteredSelectedRows)

        case 'recalculate':
          return recalculateSelectedInvoices(filteredSelectedRows)

        case 'send': {
          const draftSelectedRows = filteredSelectedRows.filter(
            row => row.status === 'DRAFT'
          )

          const sendSelectedRows = filteredSelectedRows.filter(
            row => row.status !== 'DRAFT'
          )

          if (!sendSelectedRows.length) {
            return finalizeAndSendSelectedInvoices(draftSelectedRows)
          }

          if (draftSelectedRows.length && sendSelectedRows.length) {
            return Promise.all([
              finalizeAndSendSelectedInvoices(draftSelectedRows),
              sendSelectedInvoices(sendSelectedRows, {
                showToast: false
              })
            ])
          }

          return sendSelectedInvoices(filteredSelectedRows)
        }

        case 'void':
          return voidSelectedInvoices(filteredSelectedRows)

        case 'merge':
          return mergeSelectedInvoices(filteredSelectedRows)
      }
    }
    return
  }

  const handleMarkAsRows = (status: InvoicePaymentStatus) => {
    const filtered = filterSelectedRows('markAs')

    if (getSelectedRowsIds(selectedRows).length > 0) {
      return markAsSelectedInvoices(status, filtered)
    }
    return
  }

  const handleCloseBulkActions = () => {
    clearSelectedRows()
    onClose()
  }

  const sortActions = () => {
    const actions: Action[] = []
    if (selectedRows.some(row => row.status === 'DRAFT')) {
      actions.push('finalize', 'send', 'void')
    }
    if (
      selectedRows.some(
        row =>
          ['IN_PROGRESS', 'DRAFT'].includes(row.status) &&
          !!row.billingScheduleId
      )
    ) {
      actions.push('recalculate')
    }
    if (selectedRows.some(row => row.status === 'FINAL')) {
      if (actions.includes('finalize')) {
        actions.filter(action => action !== 'finalize')
        actions.push('send', 'void')
      }
      actions.push('send', 'void')
    }
    if (selectedRows.some(row => row.status === 'SENT')) {
      actions.push('markAs')
    }

    const uniqueArray = [...new Set(actions)]
    return uniqueArray
  }

  const possibleActions = sortActions()

  const countFor = (action: Action) => {
    return filterSelectedRows(action).length
  }

  const countForButton = (action: Action) => {
    const count = countFor(action)
    if (count === selectedRows.length) {
      return undefined
    }
    return count
  }

  return (
    <>
      <Popover
        isOpen={isOpen}
        onClose={onClose}
        placement="top"
        closeOnBlur={false}
        offset={popoverOffset}
      >
        <PopoverAnchor>{children}</PopoverAnchor>
        <PopoverContent
          width="100%"
          py={1}
          px={2}
          borderRadius="8px"
          boxShadow={ShadowL}
        >
          <PopoverBody width="100%" height="100%" p="0">
            <InvoiceBulkActionBar
              count={selectedRows.length}
              onDismiss={handleCloseBulkActions}
            >
              {(possibleActions.includes('finalize') ||
                possibleActions.includes('send') ||
                possibleActions.includes('recalculate')) && (
                <>
                  <RecalculateInvoicesDialog
                    count={countFor('recalculate')}
                    onConfirm={() => void handleSubmitRows('recalculate')}
                    trigger={
                      <InvoiceBulkActionBar.RecalculateButton
                        count={countForButton('recalculate')}
                      />
                    }
                  />
                  <FinalizeInvoicesDialog
                    count={countFor('finalize')}
                    onConfirm={() => void handleSubmitRows('finalize')}
                    trigger={
                      <InvoiceBulkActionBar.FinalizeButton
                        count={countForButton('finalize')}
                      />
                    }
                  />
                  <SendInvoicesDialog
                    count={countFor('send')}
                    onConfirm={() => void handleSubmitRows('send')}
                    trigger={
                      <InvoiceBulkActionBar.SendButton
                        count={countForButton('send')}
                      />
                    }
                  />
                </>
              )}

              {possibleActions.includes('markAs') && (
                <InvoiceBulkActionBar.MarkAsDropdown
                  count={countForButton('markAs')}
                  onSelect={status => handleMarkAsRows(status)}
                />
              )}

              {isOpen && (
                <InvoiceBulkActionBar.KebabMenu
                  items={[
                    <MergeInvoicesDialog
                      key="merge"
                      count={countFor('merge')}
                      onConfirm={() => void handleSubmitRows('merge')}
                      trigger={
                        <InvoiceBulkActionBar.KebabMenu.Merge
                          count={countForButton('merge')}
                        />
                      }
                    />,
                    <VoidInvoicesDialog
                      key="void"
                      count={countFor('void')}
                      onConfirm={() => void handleSubmitRows('void')}
                      trigger={
                        <InvoiceBulkActionBar.KebabMenu.Void
                          count={countForButton('void')}
                        />
                      }
                    />
                  ]}
                />
              )}
            </InvoiceBulkActionBar>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  )
}
