import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  SequenceMenuDivider,
  SequenceMenuItem
} from '@sequencehq/core-components'
import { useNotifications } from 'lib/hooks/useNotifications'

const useDuplicateQuoteTemplate = (props: {
  quoteTemplateId: string
  onSuccess?: (newQuoteTemplateId: string) => void
}) => {
  const { displayNotification, hideNotification } = useNotifications()

  return {
    duplicateQuoteTemplate: async () => {
      const pendingDuplicationNotificationId = displayNotification(
        'Duplicating template'
      )
      const duplicatedQuoteTemplateRes =
        await dashboard20240730Client.postDuplicateQuoteTemplate({
          id: props.quoteTemplateId
        })

      hideNotification(pendingDuplicationNotificationId)

      if (
        duplicatedQuoteTemplateRes.error ||
        !duplicatedQuoteTemplateRes.data
      ) {
        displayNotification('Failed to duplicate template', {
          type: 'error'
        })
        return false
      }

      hideNotification(pendingDuplicationNotificationId)
      displayNotification('Template duplicated', {
        type: 'success'
      })
      props.onSuccess?.(duplicatedQuoteTemplateRes.data.template.id)
      return true
    }
  }
}

export const DuplicateQuoteTemplateMenuItem = (props: {
  quoteTemplateId: string
  disabled?: boolean
  onSuccess?: (newQuoteTemplateId: string) => void
}) => {
  const { duplicateQuoteTemplate } = useDuplicateQuoteTemplate({
    quoteTemplateId: props.quoteTemplateId,
    onSuccess: props.onSuccess
  })
  return (
    <SequenceMenuItem
      uuid="duplicate-quote-template"
      label="Duplicate template"
      isDisabled={props.disabled}
      onClick={duplicateQuoteTemplate}
    />
  )
}
