import { FC, memo } from 'react'

import { Box, Flex, Text } from '@chakra-ui/react'
import {
  IdentificationIcon,
  TagIcon,
  ViewColumnsIcon
} from '@heroicons/react/16/solid'
import CalendarDateRangeIcon from '@heroicons/react/24/solid/CalendarDateRangeIcon'
import {
  Badge,
  KebabMenu,
  KebabMenuItem,
  MenuItemBuilder
} from '@sequencehq/core-components'
import {
  BillingScheduleStatus,
  billingScheduleStatus,
  PriceModel,
  toBillingScheduleStatusBadgeProps
} from '@sequencehq/core-models'
import { GreyGrey60, RedRed60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellScheduleStatus,
  MagicTableFilterConfig,
  MagicTable,
  useMagicTableInfiniteQuery,
  useLinkMagicTableWithSearchParams,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { BillingSchedulesSegmentedFilters } from 'components/BillingSchedules/components/BillingSchedulesSegmentedFilters'
import {
  BillingSchedulesFilters,
  BillingSchedulesView
} from 'components/BillingSchedules/types'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import Page from 'components/Page'
import { useGetApiPlansPlanIdQuery } from 'features/api'
import { openOverlay } from 'features/overlay'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { ExtractQueryParams } from 'lib/types'
import { ExtractAttributeTypeFromUnion } from 'lib/types/extractAttributeTypeFromUnion'
import {
  type DashboardApi20240730,
  dashboard20240730Client
} from '@sequencehq/api/dashboard/v20240730'
import { apiQueryClient } from 'features/api/apiQueryClient'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { BillingSchedulesExportToCsv } from 'components/BillingSchedules/components/BillingSchedulesExportToCsv.tsx'
import { formatScheduleEndDate } from 'Cube/index'
import { useNavigate } from 'react-router-dom'

type BillingSchedule = DashboardApi20240730.GetBillingSchedules.BillingSchedule

// TODO: in the future when we use the api hooks everywhere, we should get this for free
// and shouldn't need to manually invalidate things like this.
const invalidateCache = () => {
  void apiQueryClient.invalidateQueries({
    queryKey: dashboard20240730Client.getBillingSchedules.queryKey
  })
}

const viewBuilder: MenuItemBuilder<BillingSchedule> = (
  schedule: BillingSchedule
) => {
  return {
    status: 'LIVE',
    action: ({ navigate }) => navigate(`/billing-schedules/${schedule.id}`),
    label: 'View/Edit schedule'
  }
}

const archiveBuilder: MenuItemBuilder<BillingSchedule> = (
  schedule: BillingSchedule,
  options
) => {
  return {
    status: !schedule.archivedAt ? 'LIVE' : 'DISABLED',
    action: ({ dispatch }) =>
      dispatch(
        openOverlay({
          content: 'archiveBillingScheduleModal',
          data: {
            billingScheduleId: schedule.id,
            status: schedule.status,
            onSuccess: options?.onSuccess
          }
        })
      ),
    label: (
      <KebabMenuItem>
        <Box color={RedRed60}>Archive schedule</Box>
      </KebabMenuItem>
    )
  }
}

export const menuItemBuilders = [viewBuilder, archiveBuilder]

export const statusFilterOptions = () => {
  const statuses: BillingScheduleStatus[] = Object.keys(
    billingScheduleStatus.keys
  ) as BillingScheduleStatus[]
  const options = []

  for (const status of statuses) {
    // PENDING is stored as ACTIVE in the database,
    // so we need to do some backend work to make it possible to filter by PENDING
    if (status === 'PENDING') {
      continue
    } else {
      options.push({
        label: toBillingScheduleStatusBadgeProps({ status }).children,
        value: status
      })
    }
  }

  return options
}

const BillingSchedules: FC = memo(() => {
  const flags = useFlags()
  const navigate = useNavigate()

  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()

  const filters: MagicTableFilterConfig<BillingSchedulesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options: [],
      optionsFunc: fetchCustomerNameOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      type: 'multiSelect',
      paramName: 'billingScheduleStatus',
      options: statusFilterOptions(),
      format: status => (
        <Badge
          {...toBillingScheduleStatusBadgeProps({
            status: status as BillingScheduleStatus
          })}
        />
      ),
      label: 'Status',
      optionsSortFn: () => 0,
      icon: ViewColumnsIcon
    },
    {
      paramName: 'includeArchivedSchedules',

      type: 'toggle',
      label: 'Show archived schedules'
    }
  ]

  if (flags?.showBillingScheduleLabelField) {
    filters.push({
      type: 'text',
      paramName: 'label',
      label: 'Label',
      icon: TagIcon
    })
  }

  const { activeFilters, onChangeActiveFilters, sortBy, onChangeSortBy } =
    useLinkMagicTableWithSearchParams(filters)

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getBillingSchedules,
    filters,
    activeFilters,
    sortBy
  )

  const additionalToolbarComponentsV3 = {
    additionalToolbarComponentsV3: {
      start: <BillingSchedulesSegmentedFilters />
    }
  }

  const addNewConfig = {
    buttonText: 'New schedule',
    href: '/billing-schedules/new'
  }

  return (
    <Flex flexDirection="column" flex={1}>
      <Flex position="sticky" top={0} height="100vh">
        <Page
          title="Schedules"
          addNewConfig={addNewConfig}
          paddingBottom={0}
          menuButtons={<BillingSchedulesExportToCsv />}
        >
          <CurrentUserId>
            {userId => (
              <MagicTable<BillingSchedulesView>
                activeFilters={activeFilters}
                onChangeActiveFilters={onChangeActiveFilters}
                sortBy={sortBy}
                onChangeSortBy={onChangeSortBy}
                entityNamePlural="schedules"
                sequenceUserId={userId}
                infiniteQuery={infiniteQuery}
                rowPath={(row: BillingSchedulesView['model']) =>
                  `/billing-schedules/${row.id}`
                }
                columns={[
                  {
                    id: 'customer',
                    accessorFn: row => ({
                      customerId: row.customerId
                    }),
                    header: 'Customer',
                    cell: value => {
                      const { customerId } = value.getValue<{
                        customerId: BillingSchedule['customerId']
                      }>()

                      return (
                        <Flex
                          alignItems="center"
                          justifyContent="space-between"
                          height="40px"
                          pl="6px"
                        >
                          <CustomerPreviewCardPill
                            customerId={customerId}
                            variant="ghost"
                          />
                        </Flex>
                      )
                    }
                  },
                  {
                    id: 'status',
                    header: 'Status',
                    accessorFn: (row: BillingSchedule) => ({
                      status: row.status,
                      archivedAt: row.archivedAt
                    }),
                    cell: value => {
                      const { status, archivedAt } = value.getValue<{
                        status: BillingSchedule['status']
                        archivedAt: BillingSchedule['archivedAt']
                      }>()

                      return (
                        <MagicTableCellScheduleStatus
                          status={status}
                          isArchived={!!archivedAt}
                        />
                      )
                    }
                  },

                  {
                    id: 'products',
                    header: 'Products',
                    accessorFn: (model: BillingSchedule) => ({
                      planId: undefined,
                      prices: model.prices,
                      archivedAt: model.archivedAt
                    }),
                    cell: value => {
                      const { planId, prices, archivedAt } = value.getValue<{
                        planId: ExtractAttributeTypeFromUnion<
                          BillingSchedule,
                          'planId'
                        >
                        prices: ExtractAttributeTypeFromUnion<
                          BillingSchedule,
                          'prices'
                        >
                        archivedAt: ExtractAttributeTypeFromUnion<
                          BillingSchedule,
                          'archivedAt'
                        >
                      }>()

                      if (prices) {
                        return (
                          <MagicTableCell
                            textColor={archivedAt && GreyGrey60}
                            text={prices.map(p => p.name).join(', ')}
                          />
                        )
                      }

                      if (planId) {
                        return (
                          <MagicTableAutoLoader<
                            ExtractQueryParams<
                              typeof useGetApiPlansPlanIdQuery
                            >,
                            { prices: PriceModel[] }
                          >
                            queryParams={{ planId }}
                            useQuery={useGetApiPlansPlanIdQuery}
                            extract={plan => ({ prices: plan?.prices || [] })}
                          >
                            {plan => {
                              if (plan.prices.length) {
                                return (
                                  <MagicTableCell
                                    textColor={archivedAt && GreyGrey60}
                                    text={plan.prices
                                      .map(p => p.name)
                                      .join(', ')}
                                  />
                                )
                              } else {
                                return null
                              }
                            }}
                          </MagicTableAutoLoader>
                        )
                      }

                      return null
                    }
                  },
                  {
                    id: 'startDate',
                    accessorFn: (row: BillingSchedule) => ({
                      archivedAt: row.archivedAt,
                      startDate: row.startDate
                    }),
                    header: 'Start date',
                    cell: value => {
                      const { startDate, archivedAt } = value.getValue<{
                        startDate: BillingSchedule['startDate']
                        archivedAt: BillingSchedule['archivedAt']
                      }>()

                      return (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={dateTimeWithFormat(startDate, 'd MMM yyyy')}
                        />
                      )
                    }
                  },
                  {
                    id: 'endDate',
                    accessorFn: (row: BillingSchedule) => ({
                      archivedAt: row.archivedAt,
                      endDate: row.endDate
                    }),
                    header: 'End date',
                    cell: value => {
                      const { endDate, archivedAt } = value.getValue<{
                        endDate: BillingSchedule['endDate']
                        archivedAt: BillingSchedule['archivedAt']
                      }>()

                      return (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={formatScheduleEndDate(endDate)}
                        />
                      )
                    }
                  }
                ]}
                filters={filters}
                emptyStateUnfiltered={entityNamePlural => (
                  <MagicTableEmptyState
                    entityNamePlural={entityNamePlural}
                    imageSrc="/img/empty/schedules.png"
                    learnMoreText="Create a new billing schedule for a customer and put invoicing on autopilot"
                    learnMoreHref="https://docs.sequencehq.com/billing/create-billing-schedules"
                    buttonNew={{
                      label: addNewConfig.buttonText,
                      onClick: () => navigate(addNewConfig.href)
                    }}
                  />
                )}
                emptyStateFiltered={(entityNamePlural, clearFilters) => (
                  <MagicTableFilterEmptyState
                    entityNamePlural={entityNamePlural}
                    entityIcon={CalendarDateRangeIcon}
                    actionClearFilters={clearFilters}
                    actionViewAll={() => navigate('/billing-schedules')}
                    variant="TABLE"
                  />
                )}
                kebabMenu={(model: BillingSchedule, props) => (
                  <KebabMenu
                    menuItems={menuItemBuilders.map(builder =>
                      builder(model, { onSuccess: invalidateCache })
                    )}
                    flags={flags}
                    {...props}
                  />
                )}
                {...additionalToolbarComponentsV3}
              />
            )}
          </CurrentUserId>
        </Page>
      </Flex>
    </Flex>
  )
})

export default BillingSchedules
