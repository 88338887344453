import { getIsAQuoteTemplate } from 'Cube/domain/postActionStages/utils/getIsQuoteATemplate'
import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getAcceptQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  const isArchived: boolean = prevState.data.common.isArchived
  const isSignaturesEnabled: boolean = prevState.data.quote.isSignaturesEnabled
  const isPublished: boolean =
    prevState.data.common.status === CubeStatus.QuotePublished

  const isExpired = prevState.data.quote.isExpired

  if (
    isArchived ||
    isSignaturesEnabled ||
    !isPublished ||
    getIsAQuoteTemplate(prevState)
  ) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: []
    }
  }

  if (isExpired) {
    return {
      available: {
        visible: true,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.CantChangeWhenExpired,
          metadata: { quote: prevState.data.quote }
        }
      ]
    }
  }

  return {
    available: {
      visible: true,
      enabled: true
    },
    reasons: []
  }
}
