import { getIsAQuoteTemplate } from 'Cube/domain/postActionStages/utils/getIsQuoteATemplate'
import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getSetRecipientsAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (getIsAQuoteTemplate(prevState)) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: { status: prevState.data.common.status }
        }
      ]
    }
  }

  const isQuoteAccepted: boolean = Boolean(prevState.data.quote.acceptedAt)

  if (isQuoteAccepted) {
    return {
      available: { visible: true, enabled: false },
      reasons: [
        { reasonType: DisabledReasonType.CantChangeWhenAccepted, metadata: {} }
      ]
    }
  }

  const isCustomerSelected: boolean = Boolean(prevState.data.common.customerId)

  if (!isCustomerSelected) {
    return {
      available: { visible: false, enabled: false },
      reasons: [
        { reasonType: DisabledReasonType.NoCustomerSelected, metadata: {} }
      ]
    }
  }

  return {
    available: { visible: true, enabled: true },
    reasons: []
  }
}
