import { PDFPreviewDrawer } from 'components/PDFPreviewDrawer/PDFPreviewDrawer'
import { downloadBlob } from 'lib/downloadBlob.ts'
import { CreditNote } from 'CreditNotes/types.ts'
import { useGeneratePDF } from 'CreditNotes/hooks/useGeneratePDF.tsx'
import { useEffect, useState } from 'react'

type Props = {
  creditNote: Pick<CreditNote, 'id' | 'creditNoteNumber' | 'status'>
  isOpen: boolean
  onClose: () => void
}

const useCreditNotePDFPreview = ({
  creditNote,
  isOpen
}: {
  creditNote: Pick<CreditNote, 'id' | 'creditNoteNumber' | 'status'>
  isOpen?: boolean
}) => {
  const [pdfData, setPDFData] = useState<
    { blob: Blob; objectUrl: string; fileName: string } | undefined
  >()

  const { generateLocalPDF, generateRemotePDF } = useGeneratePDF()

  // biome-ignore lint/correctness/useExhaustiveDependencies: only need to rerender on visibility and id change
  useEffect(() => {
    if (!isOpen) {
      setPDFData(undefined)
      return
    }

    async function generatePDF() {
      const pdfBlob =
        creditNote.status === 'DRAFT'
          ? await generateLocalPDF({ id: creditNote.id })
          : await generateRemotePDF({ id: creditNote.id })

      if (!pdfBlob) {
        return
      }

      setPDFData({
        blob: pdfBlob,
        objectUrl: URL.createObjectURL(pdfBlob),
        fileName: creditNote.creditNoteNumber
          ? `${creditNote.creditNoteNumber}.pdf`
          : 'Credit Note.pdf'
      })
    }

    void generatePDF()
  }, [isOpen, creditNote.id])

  const downloadPDF = () => {
    if (!pdfData) {
      return
    }

    const fileName = creditNote.creditNoteNumber
      ? `${creditNote.creditNoteNumber}.pdf`
      : 'Credit Note.pdf'
    return downloadBlob(fileName)(pdfData.blob)
  }

  return {
    previewDataUrl: pdfData?.objectUrl,
    download: pdfData?.objectUrl ? downloadPDF : undefined
  }
}

export const CreditNotePDFPreview = ({
  isOpen,
  onClose,
  creditNote
}: Props) => {
  const { previewDataUrl, download } = useCreditNotePDFPreview({
    creditNote,
    isOpen
  })

  return (
    <PDFPreviewDrawer
      title="Preview credit note PDF"
      fileName={
        creditNote.creditNoteNumber
          ? `${creditNote.creditNoteNumber}.pdf`
          : undefined
      }
      fileUrl={`${previewDataUrl}`}
      isLoading={!previewDataUrl}
      onDownload={download}
      isOpen={isOpen}
      onClose={onClose}
    />
  )
}
