import { Box, Flex } from '@chakra-ui/react'
import {
  GreyGrey10,
  GreyGrey70,
  Lato14Bold,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Card, InputSelectControlField } from '@sequencehq/core-components'
import { Dashboardv99990101CommonModels } from '@sequencehq/api/dist/clients/dashboard/v99990101'

type QuickbooksAccount =
  Dashboardv99990101CommonModels.QuickbooksAccount.QuickbooksAccount

interface Props {
  fieldsConfig: {
    defaultAccount: {
      value: string
      onChange: (value: string) => void
    }
  }
  quickbooksAccounts: QuickbooksAccount[]
}

export const DefaultQuickBooksAccountCard = ({
  fieldsConfig,
  quickbooksAccounts
}: Props) => {
  const quickbooksAccountsOptions = quickbooksAccounts.map(account => ({
    value: account.id,
    label: account.name ?? account.id
  }))

  return (
    <Card>
      <Flex
        data-testid="quickbooksIntegration.defaultAccount"
        gap={4}
        flexDirection="column"
      >
        <Flex gap={1} flexDirection="column">
          <Box {...Lato14Bold}>QuickBooks accounts</Box>
          <Box {...Lato13Regular} color={GreyGrey70}>
            Set a default account for unmapped invoice line items or products.
            To map products to QuickBooks Items, edit the product directly.
          </Box>
        </Flex>
        <Flex
          backgroundColor={GreyGrey10}
          borderRadius={6}
          alignItems="center"
          alignContent="center"
          width="100%"
          p={4}
          // Input select control fields have a bottom 16px padding to account for possible validation errors
          // this pb={0} mitigates that in the layout.
          pb={0}
          gap={4}
        >
          <InputSelectControlField
            width="400px"
            label="Default account name"
            data-testid="quickbooksIntegration.defaultAccount.account"
            placeholder="Select default account"
            options={quickbooksAccountsOptions}
            initialValue={fieldsConfig.defaultAccount.value}
            onChange={fieldsConfig.defaultAccount.onChange}
          />
        </Flex>
      </Flex>
    </Card>
  )
}
