import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCsvExport } from 'lib/hooks/useCsvExport.tsx'

type UseBillingSchedulesCsvExport = () => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (queryParams: URLSearchParams) => void
  }
}

export const useBillingSchedulesCsvExport: UseBillingSchedulesCsvExport =
  () => {
    return useCsvExport({
      getExport: queryParams =>
        dashboard20240730Client.getBillingSchedulesCsvDownload({
          customerId: queryParams.get('customerId') ?? undefined,
          includeArchivedSchedules:
            queryParams.get('includeArchivedSchedules') === 'true',
          billingScheduleStatus:
            queryParams.get('billingScheduleStatus') ?? undefined,
          label: queryParams.get('label') ?? undefined
        }),
      fileName: 'billing-schedules-export.csv'
    })
  }
