import { useMemo } from 'react'
import { useSearchParams } from 'react-router-dom'
import invariant from 'tiny-invariant'

/**
 * A simple hook to grab the dates being covered by this view.
 * @returns
 */
export const useSelectedDates = (): {
  startDate: Date
  endDate: Date
} => {
  const [searchParams] = useSearchParams()
  const startDate = searchParams.get('startDate')
  const endDate = searchParams.get('endDate')

  invariant(startDate && endDate, 'Accounting period dates are required')

  return {
    startDate: new Date(startDate),
    endDate: new Date(endDate)
  }
}

const loadExpandedFromLocalStorage = (): boolean => {
  try {
    return localStorage.getItem('rev-rec-report-detail-expanded') === 'true'
  } catch {
    return false
  }
}

const saveExpandedToLocalStorage = (newState: boolean) => {
  try {
    localStorage.setItem('rev-rec-report-detail-expanded', newState.toString())
  } catch {
    // biome-ignore lint/suspicious/noConsoleLog: expected console
    console.error('Failed to save expanded state to local storage')
  }
  return
}

export const useExpanded = (): {
  expanded: boolean
  toggleExpanded: () => void
} => {
  const [searchParams, setSearchParams] = useSearchParams()

  const paramExpanded = searchParams.get('expanded') === 'true'
  const localExpanded = loadExpandedFromLocalStorage()

  const expanded = useMemo(() => {
    if (searchParams.get('expanded') === null) {
      return localExpanded ?? false
    }

    return paramExpanded
  }, [paramExpanded, localExpanded, searchParams])

  const toggleExpanded = () => {
    const currentSearchParams = [...searchParams.entries()].reduce(
      (acc, [key, value]) => ({
        ...acc,
        [key]: value
      }),
      {}
    )

    const newValue = !expanded

    saveExpandedToLocalStorage(newValue)
    setSearchParams({
      ...currentSearchParams,
      expanded: expanded ? 'false' : 'true'
    })
  }

  return {
    expanded,
    toggleExpanded
  }
}
