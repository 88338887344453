import { Flex, Text } from '@chakra-ui/react'
import { PageTitle, SequenceMenuItemType } from '@sequencehq/core-components'
import { GreyGrey90, Lato16Bold } from '@sequencehq/design-tokens'
import TopNavigation, { AddNewConfig } from 'components/Page/TopNavigation'
import { FC, ReactNode } from 'react'

type PageProps = {
  title?: string
  richTitle?: ReactNode
  children: ReactNode
  menuButtons?: ReactNode
  addNewConfig?: AddNewConfig
  actionElement?: ReactNode
  paddingBottom?: number
}

const Page: FC<PageProps> = ({
  title,
  richTitle,
  children,
  menuButtons,
  actionElement,
  paddingBottom = 6,
  addNewConfig
}) => {
  const richTitleProps = {
    ...Lato16Bold,
    color: GreyGrey90
  }

  return (
    <Flex
      bg="white"
      direction="column"
      pb={paddingBottom}
      flex={1}
      width="100%"
      height="100vh"
    >
      <TopNavigation addNewConfig={addNewConfig} actionElement={actionElement}>
        <PageTitle title={title ? [title] : undefined} />
        {richTitle ? richTitle : <Text {...richTitleProps}>{title}</Text>}
        <Flex grow="1"></Flex>
        {menuButtons}
      </TopNavigation>
      {children}
    </Flex>
  )
}

export default Page

export type PageDetails = {
  label: string
  path: string
  addNewConfig?: AddNewConfig
}
