import { useInvoiceEditorRoot } from 'InvoiceEditor/components/InvoiceEditorRoot/useInvoiceEditorRoot.ts'
import SpinnerContainer from 'components/Loading/SpinnerContainer.tsx'
import { InvoiceEditorProvider } from 'InvoiceEditor/hooks/useInvoiceEditorContext.ts'
import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { Header } from 'InvoiceEditor/components/Header/Header'
import { ConfirmSendInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmSendInvoiceModal.tsx'
import { ConfirmFinaliseInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmFinaliseInvoiceModal.tsx'
import { ConfirmRecalculateInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmRecalculateInvoiceModal.tsx'
import { ConfirmVoidInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmVoidInvoiceModal.tsx'
import { ConfirmSendAndFinaliseInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmSendAndFinaliseInvoiceModal.tsx'
import { ConfirmSendPaymentReminderModal } from 'InvoiceEditor/components/modals/ConfirmSendPaymentReminderModal.tsx'
import { Totals } from 'InvoiceEditor/components/Summary/Totals'
import { LineItems } from 'InvoiceEditor/components/LineItems/LineItems'
import { InvoiceEditorOutletProvider } from 'InvoiceEditor/InvoiceEditorOutletProvider'
import { PaymentDetails } from 'InvoiceEditor/components/drawers/PaymentDetails'
import { ConfirmCreateCreditNoteModal } from 'InvoiceEditor/components/modals/ConfirmCreateCreditNoteModal'
import { ConfirmSendTestInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmSendTestInvoiceModal/ConfirmSendTestInvoiceModal'
import { InvoiceEditorSummary } from 'InvoiceEditor/components/Summary/Summary'
import { CustomerIntegrationLinkModal } from 'InvoiceEditor/components/modals/CustomerIntegrationLinkModal/CustomerIntegrationLinkModal'
import { InvoicePdfPreview } from 'InvoiceEditor/components/drawers/InvoicePdfPreview/InvoicePdfPreview'
import NotFound from 'components/ErrorPages/NotFound'
import PageError from 'components/ErrorPages/PageError'
import { CreditNoteLineItemsModal } from 'InvoiceEditor/components/modals/CreditNoteLineItemsModal'
import { CustomerContactsRoot } from 'CustomerContacts/index'
import { CustomerContextProvider } from 'Customer/hooks/useCustomerContext'
import { EditCustomerForm } from 'Customer/drawers/EditCustomer/EditCustomerForm'
import { Banner, StandardLayout } from '@sequencehq/core-components'
import { InvoiceInspector } from '../Inspector/InvoiceInspector'
import { InvoiceIntegrationWarningBanners } from '../InvoiceIntegrationWarningBanners'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { InvoiceConfirmationModal } from 'InvoiceEditor/components/modals/InvoiceConfirmationModal'
import { ApiLineItemGroupTaxCategory } from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'
import { ConfirmDraftInvoiceModal } from 'InvoiceEditor/components/modals/ConfirmDraftInvoiceModal.tsx'

export const InvoiceEditorRoot = () => {
  const {
    context,
    loading,
    errors,
    confirmCreateCreditNoteModal,
    confirmSendInvoiceModal,
    confirmFinaliseInvoiceModal,
    confirmRecalculateInvoiceModal,
    confirmVoidInvoiceModal,
    confirmDraftInvoiceModal,
    confirmSendAndFinaliseInvoiceModal,
    confirmSendPaymentReminderModal,
    confirmSendTestInvoiceModal,
    creditNoteLineItemsModal,
    paymentDetailsDrawerState,
    editCustomerDrawerState,
    customerIntegrationLinkModal,
    invoicePdfPreviewDrawerState,
    reloadInvoice,
    recalculateInvoice,
    warnings
  } = useInvoiceEditorRoot()

  const flags = useFlags()

  const taxCategoriesPresentOnInvoice = Object.values(
    context.data.lineItemGroups
  )
    .map(group => group.taxCategory)
    .filter(Boolean) as ApiLineItemGroupTaxCategory[]

  if (errors.invoice) {
    if ('status' in errors.invoice && errors.invoice.status === 404) {
      return <NotFound />
    } else {
      return <PageError />
    }
  }

  if (loading) {
    return <SpinnerContainer />
  }

  return (
    <InvoiceEditorProvider value={context}>
      <StandardLayout
        header={<Header />}
        content={
          <>
            <Grid
              gridTemplate={`"warnings warnings" auto
              "summary detail" / 350px 1fr`}
              padding="24px"
              width="100%"
              maxWidth="1537px"
              gridColumnGap="24px"
              mx="auto"
            >
              <GridItem gridArea="warnings">
                <Flex flexDirection="column" gap="8px" paddingBottom="8px">
                  {warnings.map(warning => (
                    <Banner
                      variant="warning"
                      alignIcon="center"
                      key={warning.id}
                      data-testid={`invoice.warning.${warning.id}`}
                    >
                      <Grid
                        templateColumns="1fr auto"
                        width="100%"
                        alignItems="center"
                      >
                        {warning.message}
                      </Grid>
                    </Banner>
                  ))}
                </Flex>
                <InvoiceIntegrationWarningBanners />
              </GridItem>
              <Flex
                flexDirection="column"
                gap="20px"
                width="100%"
                flexShrink="0"
                gridArea="summary"
              >
                <Totals />
              </Flex>
              <Flex
                gridArea="detail"
                width="100%"
                flexDirection="column"
                gap="24px"
              >
                <InvoiceEditorSummary />
                <LineItems />
              </Flex>
            </Grid>
            <InvoiceEditorOutletProvider />
            {confirmSendInvoiceModal.active && (
              <ConfirmSendInvoiceModal
                onClose={confirmSendInvoiceModal.onClose}
                onConfirm={confirmSendInvoiceModal.onConfirm}
              />
            )}
            {!flags.showNewTaxManagement &&
              confirmFinaliseInvoiceModal.active && (
                <ConfirmFinaliseInvoiceModal
                  onClose={confirmFinaliseInvoiceModal.onClose}
                  onConfirm={confirmFinaliseInvoiceModal.onConfirm}
                />
              )}
            {flags.showNewTaxManagement &&
              confirmFinaliseInvoiceModal.active && (
                <InvoiceConfirmationModal
                  submitting={false}
                  onClose={confirmFinaliseInvoiceModal.onClose}
                  onConfirm={() =>
                    Promise.resolve(confirmFinaliseInvoiceModal.onConfirm(true))
                  }
                  recalculateInvoice={async () => {
                    await recalculateInvoice()
                    reloadInvoice()
                  }}
                  taxCategoriesOnInvoice={taxCategoriesPresentOnInvoice}
                  customerTaxStatus={context.data.customer.taxStatus}
                  customerCountry={context.data.customer.address?.country}
                  customerState={context.data.customer.address?.state}
                  validationSummaryText="You’re missing some information we need before finalizing the invoice."
                  confirmationSummaryText={`Lock this invoice and prevent editing. Once finalized, you can send this invoice to ${context.data.recipient.customerLegalName}.`}
                  confirmTitleText="Finalize invoice"
                  confirmActionText="Finalize invoice"
                />
              )}
            {confirmRecalculateInvoiceModal.active && (
              <ConfirmRecalculateInvoiceModal
                onClose={confirmRecalculateInvoiceModal.onClose}
                onConfirm={confirmRecalculateInvoiceModal.onConfirm}
              />
            )}
            {confirmVoidInvoiceModal.active && (
              <ConfirmVoidInvoiceModal
                onClose={confirmVoidInvoiceModal.onClose}
                onConfirm={confirmVoidInvoiceModal.onConfirm}
              />
            )}
            {confirmDraftInvoiceModal.active && (
              <ConfirmDraftInvoiceModal
                onClose={confirmDraftInvoiceModal.onClose}
                onConfirm={confirmDraftInvoiceModal.onConfirm}
              />
            )}
            {!flags.showNewTaxManagement &&
              confirmSendAndFinaliseInvoiceModal.active && (
                <ConfirmSendAndFinaliseInvoiceModal
                  onClose={confirmSendAndFinaliseInvoiceModal.onClose}
                  onConfirm={confirmSendAndFinaliseInvoiceModal.onConfirm}
                />
              )}
            {flags.showNewTaxManagement &&
              confirmSendAndFinaliseInvoiceModal.active && (
                <InvoiceConfirmationModal
                  submitting={false}
                  onClose={confirmSendAndFinaliseInvoiceModal.onClose}
                  onConfirm={() =>
                    Promise.resolve(
                      confirmSendAndFinaliseInvoiceModal.onConfirm(true)
                    )
                  }
                  recalculateInvoice={async () => {
                    await recalculateInvoice()
                    reloadInvoice()
                  }}
                  taxCategoriesOnInvoice={taxCategoriesPresentOnInvoice}
                  customerTaxStatus={context.data.customer.taxStatus}
                  customerCountry={context.data.customer.address?.country}
                  customerState={context.data.customer.address?.state}
                  validationSummaryText="You’re missing some information we need before finalizing and sending the invoice."
                  confirmationSummaryText={`Email this invoice to  ${context.data.recipient.customerLegalName}? After sending you can no longer edit the invoice.`}
                  confirmTitleText="Finalize and send invoice"
                  confirmActionText="Finalize and send invoice"
                />
              )}
            {confirmSendPaymentReminderModal.active && (
              <ConfirmSendPaymentReminderModal
                onClose={confirmSendPaymentReminderModal.onClose}
                onConfirm={confirmSendPaymentReminderModal.onConfirm}
              />
            )}
            {confirmCreateCreditNoteModal.active && (
              <ConfirmCreateCreditNoteModal
                onClose={confirmCreateCreditNoteModal.onClose}
                onConfirm={confirmCreateCreditNoteModal.onConfirm}
              />
            )}
            {creditNoteLineItemsModal.active && (
              <CreditNoteLineItemsModal
                onClose={creditNoteLineItemsModal.onClose}
                onConfirm={creditNoteLineItemsModal.onConfirm}
              />
            )}
            {confirmSendTestInvoiceModal.active && (
              <ConfirmSendTestInvoiceModal
                onClose={confirmSendTestInvoiceModal.onClose}
                onConfirm={confirmSendTestInvoiceModal.onConfirm}
              />
            )}
            <PaymentDetails
              isOpen={paymentDetailsDrawerState.active}
              onClose={paymentDetailsDrawerState.onClose}
            />
            <InvoicePdfPreview
              invoiceNumber={context.data.invoice.invoiceNumber}
              isOpen={invoicePdfPreviewDrawerState.active}
              onClose={invoicePdfPreviewDrawerState.onClose}
            />
            {editCustomerDrawerState.active && (
              <CustomerContextProvider
                customerId={context.data.recipient.customerId}
              >
                <CustomerContactsRoot
                  customerId={context.data.recipient.customerId}
                >
                  <EditCustomerForm
                    onClose={editCustomerDrawerState.onClose}
                    onSuccess={() => void reloadInvoice()}
                  />
                </CustomerContactsRoot>
              </CustomerContextProvider>
            )}
            {customerIntegrationLinkModal.active &&
              customerIntegrationLinkModal.integrationService && (
                <CustomerIntegrationLinkModal
                  integrationService={
                    customerIntegrationLinkModal.integrationService
                  }
                  onClose={customerIntegrationLinkModal.onClose}
                  onConfirm={customerIntegrationLinkModal.onConfirm}
                />
              )}
          </>
        }
        inspector={<InvoiceInspector />}
      />
    </InvoiceEditorProvider>
  )
}
