import { Center, Link } from '@chakra-ui/react'
import { Link as RouterLink } from 'react-router-dom'
import { IndigoIndigo50 } from '@sequencehq/design-tokens'
import QuestionMarkCircleIcon from '@heroicons/react/16/solid/QuestionMarkCircleIcon'
import { ShareFeedbackModal } from 'components/ShareFeedbackModal/ShareFeedbackModal'
import { FC, useMemo, useState } from 'react'
import { match } from 'ts-pattern'
import {
  Button,
  ErrorMessage as ErrorMessageComponent
} from '@sequencehq/core-components'

type ErrorMessageProps = {
  errorType: 'notFound' | 'general'
}

export const ErrorMessage: FC<ErrorMessageProps> = ({ errorType }) => {
  const [showShareFeedbackModal, setShowShareFeedbackModal] = useState(false)

  const title = useMemo(() => {
    return match(errorType)
      .with('notFound', () => 'Oops! Page not found')
      .with('general', () => undefined)
      .exhaustive()
  }, [errorType])

  const message = useMemo(() => {
    return match(errorType)
      .with('notFound', () => (
        <>
          We can't find the page you're looking for. Try going back to{' '}
          <Link
            as={RouterLink}
            to={'/home'}
            color={IndigoIndigo50}
            variant="inline"
          >
            home
          </Link>
          .
        </>
      ))
      .with(
        'general',
        () =>
          'Try reloading the page if you believe this is an error, or send us feedback.'
      )
      .exhaustive()
  }, [errorType])

  return (
    <>
      <Center width="100%" height="100%" padding="20px">
        <ErrorMessageComponent
          data-testid={`errorPage.${errorType}`}
          title={title}
          message={message}
          button={
            <Button
              variant="secondary"
              onClick={() => setShowShareFeedbackModal(true)}
              leadingIcon={
                <QuestionMarkCircleIcon height="16px" width="16px" />
              }
            >
              Submit feedback
            </Button>
          }
        />
      </Center>

      {showShareFeedbackModal && (
        <ShareFeedbackModal onClose={() => setShowShareFeedbackModal(false)} />
      )}
    </>
  )
}
