import { Flex, Grid, GridItem } from '@chakra-ui/react'
import { Skeleton } from '@sequencehq/core-components'
import { GreyGrey30 } from '@sequencehq/design-tokens'

export const ScheduleLoadingSkeleton = () => {
  return (
    <Grid
      data-testid="cube.loadingLayout"
      templateAreas={`
  "header header"
  "content inspector"
  `}
      templateRows={`48px 1fr`}
      templateColumns={`1fr 48px`}
      width="100%"
      height="100vh"
      overflow="auto"
    >
      <GridItem
        gridArea="header"
        borderBottom={`1px solid ${GreyGrey30}`}
        display="flex"
        alignItems="center"
        paddingLeft="16px"
      >
        <Skeleton height="24px" width="220px" />
      </GridItem>
      <GridItem
        gridArea="inspector"
        borderLeft={`1px solid ${GreyGrey30}`}
        display="flex"
        flexDirection="column"
        alignItems="center"
        padding="8px"
        gap="2px"
      >
        <Skeleton height="32px" width="32px" />
        <Skeleton height="32px" width="32px" />
        <Skeleton height="32px" width="32px" />
      </GridItem>
      <GridItem
        gridArea="content"
        display="flex"
        alignItems="center"
        flexDirection="column"
        padding="24px"
      >
        <Flex
          flexDirection="column"
          alignContent="flex-start"
          width="1168px"
          gap="16px"
        >
          <Skeleton height="214px" width="100%" />
          <Skeleton height="448px" width="100%" />
          <Skeleton height="260px" width="100%" />
        </Flex>
      </GridItem>
    </Grid>
  )
}
