import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { ActiveFilter } from '@sequencehq/tables'
import { SavedView, SavedViewBody } from './savedViews.types'

export type GetSavedViewsResponse =
  DashboardApi20240730.GetSavedViews.GetSavedViewsResponse

type ApiSavedViewActiveFilters = {
  version: number
  activeFilters: ActiveFilter[]
}

const isSavedViewActiveFilters = (
  apiActiveFilters: unknown
): apiActiveFilters is ApiSavedViewActiveFilters => {
  return (
    typeof apiActiveFilters === 'object' &&
    apiActiveFilters !== null &&
    'version' in apiActiveFilters &&
    'activeFilters' in apiActiveFilters &&
    Array.isArray(apiActiveFilters.activeFilters)
  )
}

const apiActiveFiltersToSavedViewActiveFilters = (
  activeFiltersApiAttribute: unknown
): ActiveFilter[] => {
  if (!isSavedViewActiveFilters(activeFiltersApiAttribute)) {
    return []
  }

  return activeFiltersApiAttribute.activeFilters
}

const savedViews = {
  fromServer: (response: GetSavedViewsResponse): SavedView[] => {
    return response.items.map(savedView => ({
      ...savedView,
      activeFilters: apiActiveFiltersToSavedViewActiveFilters(
        savedView.activeFilters
      )
    }))
  }
}

const savedView = {
  toServer: (savedView: SavedViewBody) => {
    return {
      ...savedView,
      activeFilters: {
        version: 1,
        activeFilters: savedView.activeFilters
      }
    }
  }
}

export const savedViewsPorts = {
  savedView,
  savedViews
}
