import {
  SequenceKebabMenu,
  SequenceMenuDivider
} from '@sequencehq/core-components'
import { ArchiveQuoteTemplateMenuItem } from 'common/menuItems/quotes/ArchiveQuoteTemplateMenuItem'
import { CreateQuoteFromTemplateMenuItem } from 'common/menuItems/quotes/CreateQuoteFromTemplateMenuItem'
import { DuplicateQuoteTemplateMenuItem } from 'common/menuItems/quotes/DuplicateQuoteTemplateMenuItem'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { useNavigate } from 'react-router-dom'

const useQuoteTemplateEditorKebabMenu = () => {
  const navigate = useNavigate()
  const cubeContext = useCubeContext()
  return {
    quoteTemplateId: cubeContext.queries.rawData.data.common.id,
    archiveTemplate: {
      visible:
        cubeContext.queries.availableFeatures.quote.archive.available.visible,
      disabled:
        !cubeContext.queries.availableFeatures.quote.archive.available.enabled,
      onSuccess: () => {
        cubeContext.mutators.external.in.core()
      }
    },
    duplicateTemplate: {
      visible: true,
      disabled: false,
      onSuccess: (newTemplateId: string) => {
        navigate(`/quote-templates/${newTemplateId}`)
      }
    },
    createQuoteFromTemplate: {
      visible:
        cubeContext.queries.availableFeatures.quote.createQuoteFromTemplate
          .available.visible,
      disabled:
        !cubeContext.queries.availableFeatures.quote.createQuoteFromTemplate
          .available.enabled,
      onSuccess: (newQuoteId: string) => {
        navigate(`/quotes/${newQuoteId}`)
      }
    }
  }
}

/**
 * This kebab menu will use the newer style of modal, which is triggerable from the
 * component. These modals will be self sufficient, and have their own API interactions
 * defined. An 'onComplete' handler will be used to keep Cube state in sync.
 * @returns
 */
export const QuoteTemplateEditorKebabMenu = () => {
  const {
    archiveTemplate,
    createQuoteFromTemplate,
    duplicateTemplate,
    quoteTemplateId
  } = useQuoteTemplateEditorKebabMenu()

  return (
    <>
      <SequenceKebabMenu
        data-testid="quoteTemplates.menu"
        variant="ghost"
        alignment="bottom-right"
        items={[
          ...(createQuoteFromTemplate.visible
            ? [
                <CreateQuoteFromTemplateMenuItem
                  key="create-quote"
                  quoteTemplateId={quoteTemplateId}
                  disabled={createQuoteFromTemplate.disabled}
                  onSuccess={createQuoteFromTemplate.onSuccess}
                />
              ]
            : []),
          ...(duplicateTemplate.visible
            ? [
                <>
                  {createQuoteFromTemplate.visible && (
                    <SequenceMenuDivider key="duplicate-quote-divider" />
                  )}
                  <DuplicateQuoteTemplateMenuItem
                    key="duplicate-quote"
                    quoteTemplateId={quoteTemplateId}
                    disabled={duplicateTemplate.disabled}
                    onSuccess={duplicateTemplate.onSuccess}
                  />
                </>
              ]
            : []),
          ...(archiveTemplate.visible
            ? [
                <>
                  {(createQuoteFromTemplate.visible ||
                    duplicateTemplate.visible) && (
                    <SequenceMenuDivider key="archive-quote-divider" />
                  )}
                  <ArchiveQuoteTemplateMenuItem
                    key="archive-quote"
                    quoteTemplateId={quoteTemplateId}
                    disabled={archiveTemplate.disabled}
                    onSuccess={archiveTemplate.onSuccess}
                  />
                </>
              ]
            : [])
        ]}
      />
    </>
  )
}
