import {
  CubeReducerState,
  CubeStatus,
  MerchantBranding,
  Phase,
  Price,
  QuoteDealType
} from 'modules/Cube/domain/cube.domain.types'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import { Currency } from '@sequencehq/core-models'
import { v1ApiIntegration } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadIntegrations'
import { v1ApiPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadPrices'
import { v1ApiProduct } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadProducts'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { apiPresentationToQuotePresentation } from '@sequencehq/quote-content'
import { v1ApiListPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadListPrices'
import { v1ApiUser } from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders/useLoadUser'
import { ApiCustomer } from '../../common.api.v1/ports/entityLoaders/useLoadSingleCustomer'
/**
 * In order to reduce adapter surface area, where possible, we share these sub-adapters between quote and quote templates.
 */
import {
  calculateContractLength,
  pricesToDomainPrices,
  quotePhasesToDiscounts,
  quotePhasesToMinimums,
  quotePhasesToPhases,
  transformListPrices
} from '../../common.api.v1/adapters/common.adapters.in'
import { ApiQuoteTemplate } from '../ports/entityLoaders/useLoadQuoteTemplate'

export type ApiData = {
  quoteTemplate: ApiQuoteTemplate
  presentation: any
  customers: ApiCustomer[]
  integrations: v1ApiIntegration[]
  prices: v1ApiPrice[]
  listPrices: v1ApiListPrice[]
  products: v1ApiProduct[]
  currencies: Currency[]
  merchantBranding: MerchantBranding | null
  createdByUser: v1ApiUser | undefined
}

export const quoteTemplateResponseToCommonFields = (
  quoteTemplate: ApiQuoteTemplate,
  enabledCurrencies: Currency[],
  phases: Phase[]
): CubeReducerState['data']['common'] => ({
  id: quoteTemplate.template.id,
  title: quoteTemplate.template.title ?? '',
  alias: '',
  customerId: '',
  status: CubeStatus.QuoteTemplate,
  currency: quoteTemplate.template.currency ?? enabledCurrencies[0],
  startDate: undefined,
  isArchived: !!quoteTemplate.template.archivedAt,
  phaseIds: phases.map(phase => phase.id),
  createdAt: new Date(quoteTemplate.template.createdAt)
})

export const quoteTemplateResponseToQuoteFields = (
  quoteTemplate: ApiQuoteTemplate,
  user: v1ApiUser | undefined
): CubeReducerState['data']['quote'] => ({
  quoteNumber: '',
  contractLength: calculateContractLength(
    quoteTemplate.phases?.map(({ duration }) => duration) ?? []
  ),
  dealType: (quoteTemplate.template.dealType ??
    'NEW_BUSINESS') as QuoteDealType,
  attachmentAssets: quoteTemplate.attachmentAssets,
  createdByEmail: user?.email,
  counterSigners: [],
  contacts: [],
  isSignaturesEnabled: false,
  pdfStatus: 'NOT_YET_REQUESTED',
  isExpired: false,
  expiresIn: undefined,
  publishedAt: undefined,
  acceptedAt: undefined,
  readyToSignAt: undefined,
  executedAt: undefined,
  expiresAt: undefined,
  billingScheduleId: undefined,
  customerLegalName: undefined,
  customerAddress: undefined,
  salesforceOpportunityId: undefined
})

export const apiResponseToFields = (
  apiData: ApiData
): Pick<CubeReducerState, 'data'> => {
  /**
   * We use the quote adapters for the phase parsing, as the quote and quote
   * template will share the same structure here. If this assumption is ever invalidated,
   * then that will:
   *
   * a) make templates a bit awkward
   * b) we'd add the 'fork' of that parsing to this file.
   */
  const transformedPhases = quotePhasesToPhases(apiData.quoteTemplate.phases)
  const currency =
    apiData.quoteTemplate.template.currency ?? apiData.currencies[0]

  return {
    data: {
      common: quoteTemplateResponseToCommonFields(
        apiData.quoteTemplate,
        apiData.currencies,
        Object.values(transformedPhases)
      ),
      quote: quoteTemplateResponseToQuoteFields(
        apiData.quoteTemplate,
        apiData.createdByUser
      ),
      presentation: apiPresentationToQuotePresentation(apiData.presentation),
      phases: transformedPhases,
      schedule: INITIAL_CUBE_STATE.data.schedule,
      customers: {},
      taxRates: {},
      products: arrayToIdKeyedObject(apiData.products),
      discounts: quotePhasesToDiscounts(apiData.quoteTemplate.phases),
      minimums: quotePhasesToMinimums(apiData.quoteTemplate.phases),
      prices: arrayToIdKeyedObject<Price>(
        pricesToDomainPrices(apiData.prices, currency)
      ),
      listPrices: transformListPrices(apiData.listPrices),
      merchantBranding: {
        legalCompanyName: 'Not defined for template',
        address: {
          line1: 'Not defined for template',
          town: 'Not defined for template',
          postcode: 'Not defined for template',
          country: 'GB' as const
        },
        email: 'Not defined for template'
      },
      contacts: {}
    }
  }
}
