import {
  Contact,
  CubeReducerState,
  MerchantBranding,
  Phase,
  Price,
  QuoteContact
} from 'modules/Cube/domain/cube.domain.types'
import { v1ApiQuote } from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders/useLoadQuote'
import { quoteStatusAdapter } from 'modules/Cube/utils/status.adapter'
import { INITIAL_CUBE_STATE } from 'modules/Cube/domain/cube.constants'
import { Currency } from '@sequencehq/core-models'
import { differenceInDays, type Duration } from '@sequencehq/utils/dates'
import { v1ApiIntegration } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadIntegrations'
import { v1ApiPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadPrices'
import { v1ApiProduct } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadProducts'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { apiPresentationToQuotePresentation } from '@sequencehq/quote-content'
import { v1ApiListPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadListPrices'
import { v1ApiUser } from 'modules/Cube/communication/external/quotes.api.v1/ports/entityLoaders/useLoadUser'
import { v1ApiContact } from '../ports/entityLoaders/useLoadContacts'
import { ApiCustomer } from '../../common.api.v1/ports/entityLoaders/useLoadSingleCustomer'
import {
  calculateContractLength,
  pricesToDomainPrices,
  quotePhasesToDiscounts,
  quotePhasesToMinimums,
  quotePhasesToPhases,
  transformListPrices
} from '../../common.api.v1/adapters/common.adapters.in'

export type ApiData = {
  quote: v1ApiQuote
  presentation: any
  customers: ApiCustomer[]
  integrations: v1ApiIntegration[]
  prices: v1ApiPrice[]
  listPrices: v1ApiListPrice[]
  products: v1ApiProduct[]
  currencies: Currency[]
  merchantBranding: MerchantBranding
  createdByUser: v1ApiUser | undefined
  contacts: v1ApiContact[]
}

export const quoteResponseToCommonFields = (
  quote: v1ApiQuote,
  enabledCurrencies: Currency[],
  phases: Phase[]
): CubeReducerState['data']['common'] => ({
  id: quote.id,
  title: quote.title ?? '',
  alias: quote.quoteNumber,
  customerId: quote.customerId ?? '',
  status: quoteStatusAdapter.in(quote.status),
  currency: quote.currency ?? enabledCurrencies[0],
  startDate: quote.preliminaryBillingStartDate
    ? new Date(quote.preliminaryBillingStartDate)
    : undefined,
  isArchived: !!quote.archivedAt,
  phaseIds: phases.map(phase => phase.id),
  createdAt: new Date(quote.createdAt)
})

export const calculateExpiryDays = (
  quote: v1ApiQuote
): Duration | undefined => {
  if ('expiryDays' in quote) {
    return { days: quote.expiryDays }
  }

  if (quote.expiresAt) {
    const quoteExpiresAt = new Date(quote.expiresAt)
    const now = new Date()

    return { days: differenceInDays(quoteExpiresAt, now) }
  }

  return {
    days: 30
  }
}

export const quoteResponseToFields = (
  quote: v1ApiQuote,
  user: v1ApiUser | undefined
): CubeReducerState['data']['quote'] => ({
  quoteNumber: quote.quoteNumber,
  contractLength: calculateContractLength(
    quote.phases?.map(({ duration }) => duration) ?? []
  ),
  dealType: (quote.dealType ?? 'NEW_BUSINESS') as 'NEW_BUSINESS' | 'RENEWAL',
  expiresIn: calculateExpiryDays(quote),
  publishedAt: quote.publishedAt ? new Date(quote.publishedAt) : undefined,
  acceptedAt: quote.acceptedAt ? new Date(quote.acceptedAt) : undefined,
  attachmentAssets: quote.attachmentAssets,
  readyToSignAt:
    'readyToSignAt' in quote && quote.readyToSignAt
      ? new Date(quote.readyToSignAt)
      : undefined,
  executedAt:
    'executedAt' in quote && quote.executedAt
      ? new Date(quote.executedAt)
      : undefined,
  expiresAt:
    'expiresAt' in quote && quote.expiresAt
      ? new Date(quote.expiresAt)
      : undefined,
  billingScheduleId: quote.billingScheduleId,
  createdByEmail: user?.email,
  contacts: quote.contacts,
  isSignaturesEnabled: quote.isSignaturesEnabled,
  counterSigners: quote.counterSigners,
  customerLegalName: quote.customerLegalName,
  customerAddress: quote.customerAddress,
  salesforceOpportunityId: quote.salesforceOpportunityId,
  pdfStatus: quote.pdfStatus,
  isExpired: quote.isExpired
})

export const transformContacts = (
  contacts: ApiData['contacts'],
  customerId: ApiData['quote']['customerId']
): Record<string, Contact> => {
  const contactsWithCustomerId = contacts.map(contact => ({
    ...contact,
    customerId: customerId ?? ''
  }))

  return arrayToIdKeyedObject(contactsWithCustomerId)
}

export const apiResponseToFields = (
  apiData: ApiData
): Pick<CubeReducerState, 'data'> => {
  const transformedPhases = quotePhasesToPhases(apiData.quote.phases)
  const currency = apiData.quote.currency ?? apiData.currencies[0]

  return {
    data: {
      common: quoteResponseToCommonFields(
        apiData.quote,
        apiData.currencies,
        Object.values(transformedPhases)
      ),
      quote: quoteResponseToFields(apiData.quote, apiData.createdByUser),
      presentation: apiPresentationToQuotePresentation(apiData.presentation),
      phases: transformedPhases,
      schedule: INITIAL_CUBE_STATE.data.schedule,
      customers: arrayToIdKeyedObject(apiData.customers),
      taxRates: {},
      products: arrayToIdKeyedObject(apiData.products),
      discounts: quotePhasesToDiscounts(apiData.quote.phases),
      minimums: quotePhasesToMinimums(apiData.quote.phases),
      prices: arrayToIdKeyedObject<Price>(
        pricesToDomainPrices(apiData.prices, currency)
      ),
      listPrices: transformListPrices(apiData.listPrices),
      merchantBranding: apiData.merchantBranding,
      contacts: transformContacts(apiData.contacts, apiData.quote.customerId)
    }
  }
}

export const contactToQuoteContact: (contact: Contact) => QuoteContact =
  contact => {
    return {
      contactId: contact.id,
      name: contact.name,
      email: contact.email,
      signedAt: undefined
    }
  }
