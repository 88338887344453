import { Box, Grid, GridItem } from '@chakra-ui/react'
import { GreyGrey0 } from '@sequencehq/design-tokens'
import { BillingScheduleHeader } from 'modules/Cube/view/layouts/billingSchedule/BillingScheduleHeader/BillingScheduleHeader'
import { PricingWidget } from 'Cube/view/common/widgets/Pricing/PhasesWidget'
import { AdditionalInformationWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/AdditionalInformation/AdditionalInformationWidget'
import { CustomerWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/Customer/CustomerWidget'
import { BillingScheduleInspector } from './BillingScheduleInspector'
import { BillingSchedulerExtractionAdapter } from 'ContractExtraction/components/BillingSchedulerExtractionAdapter'

export const BillingScheduleLayout = () => {
  return (
    <Grid
      data-testid="schedule.layout"
      templateAreas={`
    "header"
    "content"
    `}
      templateRows={`auto 1fr`}
      columnGap="20px"
      width="100%"
      height="100vh"
      backgroundColor={GreyGrey0}
      overflow="hidden"
    >
      <GridItem gridArea="header">
        <BillingScheduleHeader />
      </GridItem>
      <Grid
        templateColumns="1fr auto"
        templateAreas={`"main sidebar"`}
        gridArea="content"
        height="100%"
        overflow="hidden"
      >
        <GridItem gridArea="main" width="100%" overflow="auto">
          <Grid
            padding="24px"
            templateAreas={`"left widgets right"`}
            templateColumns={`1fr 1168px 1fr`}
            justifyItems="center"
          >
            <GridItem
              gridArea="widgets"
              width="100%"
              display="flex"
              flexDirection="column"
              gap="20px"
            >
              <BillingSchedulerExtractionAdapter />
              <CustomerWidget />
              <PricingWidget wideLayout />
              <AdditionalInformationWidget />
            </GridItem>
          </Grid>
        </GridItem>

        <GridItem gridArea="sidebar" zIndex="10">
          <BillingScheduleInspector />
        </GridItem>
      </Grid>
    </Grid>
  )
}
