import { Reducer } from 'react'
import { match } from 'ts-pattern'
import { loadInvoiceEditorData } from 'InvoiceEditor/domainManagement/actionHandlers/loadInvoiceEditorData.ts'
import { updateInvoiceEditorData } from 'InvoiceEditor/domainManagement/actionHandlers/updateInvoiceEditorData.ts'
import { resetInvoiceEditorData } from 'InvoiceEditor/domainManagement/actionHandlers/resetInvoiceEditorData'
import {
  InvoiceEditorReducerActions,
  InvoiceEditorReducerState,
  PostActionStages
} from 'InvoiceEditor/domainManagement/invoiceEditor.types.ts'
import { INITIAL_REDUCER_STATE } from 'InvoiceEditor/domainManagement/invoiceEditor.constants.ts'
import * as postActionStages from 'InvoiceEditor/domainManagement/postActionStages'

const log = (...args: unknown[]) => {
  if (localStorage.getItem('enable-invoice-detail-logs')) {
    // biome-ignore lint/suspicious/noConsoleLog: expected console
    return console.info(...args)
  }
  return
}

const handleAction =
  (prevState: InvoiceEditorReducerState) =>
  (action: InvoiceEditorReducerActions) => {
    return match(action)
      .with({ type: 'loadInvoiceEditorData' }, matchedAction =>
        loadInvoiceEditorData(prevState)(matchedAction)
      )
      .with({ type: 'updateInvoiceEditorData' }, matchedAction =>
        updateInvoiceEditorData(prevState)(matchedAction)
      )
      .with({ type: 'resetInvoiceEditorData' }, matchedAction =>
        resetInvoiceEditorData(prevState)(matchedAction)
      )
      .exhaustive()
  }

const handlePostActionStages: PostActionStages = () => prevState => {
  return postActionStages.queries()(prevState)
}

export const invoiceEditorReducer: Reducer<
  InvoiceEditorReducerState,
  InvoiceEditorReducerActions
> = (prevState = INITIAL_REDUCER_STATE, action) => {
  log(
    `%c[Invoice detail]======== Start of reducer for action ${action.type} =========`,
    'color: cyan;',
    {
      initialState: prevState,
      action
    }
  )

  const postActionState = handleAction(prevState)(action)

  log(
    `%c[Invoice detail]======== beginning of post action functionality for action ${action.type} =========`,
    'color: lime;',
    {
      postActionState
    }
  )

  const finalState = handlePostActionStages({
    preActionState: prevState,
    action
  })(postActionState)

  log(
    `%c[Invoice detail]======== End of reducer for action ${action.type} =========`,
    'color: hotpink;',
    {
      finalState
    },
    `
    `
  )

  return finalState
}
