import { Flex, Text } from '@chakra-ui/react'
import { IdentificationIcon } from '@heroicons/react/16/solid'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { SequenceKebabMenu } from '@sequencehq/core-components'
import { GreyGrey60 } from '@sequencehq/design-tokens'
import { dateTimeWithFormat } from '@sequencehq/formatters'
import {
  MagicTableCell,
  MagicTableCellBasic,
  MagicTableCellQuoteStatus,
  MagicTableFilterConfig,
  MagicTable,
  useMagicTableInfiniteQuery,
  useLinkMagicTableWithSearchParams,
  MagicTableEmptyState,
  MagicTableFilterEmptyState
} from '@sequencehq/tables'
import MagicTableAutoLoader from 'components/AutoLoader/MagicTableAutoLoader'
import { CurrentUserId } from 'components/CurrentUserId/CurrentUserId'
import { QuoteView, QuotesFilters } from 'modules/Quotes/types'
import { CustomerPreviewCardPill } from 'Customer/components/CustomerPreviewCard'
import { useGetUsersByIdQuery } from 'features/api'
import { useGetMagicTableFilterOptions } from 'lib/magicTableSupport/useGetMagicTableFilterOptions'
import { ExtractQueryParams } from 'lib/types'
import { useNavigate } from 'react-router-dom'
import DocumentCheckIcon from '@heroicons/react/24/solid/DocumentCheckIcon'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { QuoteTemplatesList } from './quoteTemplates/QuoteTemplatesList'
import { CreateTemplateFromQuoteMenuItem } from 'common/menuItems/quotes/CreateTemplateFromQuoteMenuItem'
import { DuplicateQuoteMenuItem } from 'common/menuItems/quotes/DuplicateQuoteMenuItem'
import { ArchiveQuoteMenuItem } from 'common/menuItems/quotes/ArchiveQuoteMenuItem'

export const contractLengthOptions = [
  {
    label: '6 months',
    value: '180'
  },
  {
    label: '1 year',
    value: '365'
  },
  {
    label: '2 years',
    value: '730'
  },
  {
    label: '3 years',
    value: '1095'
  }
]

const QuotesMagicTable = () => {
  const navigate = useNavigate()
  const { fetchCustomerNameOptions } = useGetMagicTableFilterOptions()
  const flags = useFlags()

  const filters: MagicTableFilterConfig<QuotesFilters>[] = [
    {
      type: 'multiSelect',
      paramName: 'customerId',
      label: 'Customer',
      icon: IdentificationIcon,
      options: [],
      optionsFunc: fetchCustomerNameOptions,
      format: (value: string) => <Text>{value}</Text>
    },
    {
      paramName: 'includeArchived',
      type: 'toggle',
      label: 'Show archived quotes'
    }
  ]

  const { activeFilters, onChangeActiveFilters, sortBy, onChangeSortBy } =
    useLinkMagicTableWithSearchParams(filters)

  const { infiniteQuery } = useMagicTableInfiniteQuery(
    dashboard20240730Client.getQuotes,
    filters,
    activeFilters,
    sortBy
  )

  type ListQuoteModel = DashboardApi20240730.GetQuotes.Quote

  return (
    <CurrentUserId>
      {userId => (
        <>
          <MagicTable<QuoteView>
            activeFilters={activeFilters}
            onChangeActiveFilters={onChangeActiveFilters}
            sortBy={sortBy}
            onChangeSortBy={onChangeSortBy}
            entityNamePlural="quotes"
            sequenceUserId={userId}
            infiniteQuery={infiniteQuery}
            rowPath={quote => `/quotes/${quote.id}`}
            additionalHeaderRows={
              flags.useQuoteTemplates ? <QuoteTemplatesList /> : null
            }
            columns={[
              {
                id: 'title',
                accessorFn: row => ({
                  title: row.title,
                  archivedAt: row.archivedAt
                }),
                header: 'Title',
                cell: value => {
                  const { archivedAt, title } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    title: ListQuoteModel['title']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={`${title || 'Untitled'}${
                        archivedAt ? ' (archived)' : ''
                      }`}
                    />
                  )
                }
              },
              {
                id: 'quoteNumber',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  quoteNumber: row.quoteNumber
                }),
                header: 'Number',
                cell: value => {
                  const { archivedAt, quoteNumber } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    quoteNumber: ListQuoteModel['quoteNumber']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={quoteNumber}
                    />
                  )
                }
              },
              {
                id: 'customer',
                accessorKey: 'customerId',
                header: 'Customer',
                hasLink: true,
                cell: value => {
                  const customerId =
                    value.getValue<ListQuoteModel['customerId']>()
                  return customerId ? (
                    <Flex
                      alignItems="center"
                      justifyContent="space-between"
                      height="40px"
                      pl="6px"
                    >
                      <CustomerPreviewCardPill
                        customerId={customerId}
                        variant="ghost"
                      />
                    </Flex>
                  ) : (
                    <MagicTableCellBasic>
                      <Text padding="3px 6px">Unknown</Text>
                    </MagicTableCellBasic>
                  )
                }
              },
              {
                id: 'status',
                header: 'Status',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  publishedAt: row.publishedAt,
                  isExpired: row.isExpired,
                  status: row.status
                }),
                cell: value => {
                  const { archivedAt, status, publishedAt, isExpired } =
                    value.getValue<
                      Pick<
                        ListQuoteModel,
                        'archivedAt' | 'status' | 'publishedAt' | 'isExpired'
                      >
                    >()

                  return (
                    <MagicTableCellQuoteStatus
                      status={status}
                      isArchived={Boolean(archivedAt)}
                      isPublished={Boolean(publishedAt)}
                      isExpired={Boolean(isExpired)}
                    />
                  )
                }
              },
              {
                id: 'user',
                accessorFn: row => ({
                  archivedAt: row.archivedAt,
                  createdBy: row.createdBy
                }),
                header: 'Owner',
                cell: value => {
                  const { archivedAt, createdBy } = value.getValue<{
                    archivedAt: ListQuoteModel['archivedAt']
                    createdBy: ListQuoteModel['createdBy']
                  }>()
                  return (
                    <MagicTableAutoLoader<
                      ExtractQueryParams<typeof useGetUsersByIdQuery>,
                      { email: string }
                    >
                      queryParams={{
                        id: createdBy
                      }}
                      useQuery={useGetUsersByIdQuery}
                      extract={user => ({
                        email: user?.email ?? '-'
                      })}
                    >
                      {({ email }) => (
                        <MagicTableCell
                          textColor={archivedAt && GreyGrey60}
                          text={email}
                        />
                      )}
                    </MagicTableAutoLoader>
                  )
                }
              },
              {
                id: 'expiresAt',
                accessorFn: row => ({
                  expiresAt: row.expiresAt,
                  expiryDays: row.expiryDays,
                  archivedAt: row.archivedAt
                }),
                header: 'Expires',
                cell: value => {
                  const { expiresAt, expiryDays, archivedAt } = value.getValue<{
                    expiresAt: ListQuoteModel['expiresAt']
                    expiryDays: ListQuoteModel['expiryDays']
                    archivedAt: ListQuoteModel['archivedAt']
                  }>()
                  return (
                    <MagicTableCell
                      textColor={archivedAt && GreyGrey60}
                      text={
                        expiresAt
                          ? dateTimeWithFormat(expiresAt, 'd MMM yyyy')
                          : expiryDays
                            ? `${expiryDays} days after publishing`
                            : ''
                      }
                    />
                  )
                }
              }
            ]}
            kebabMenu={(model: ListQuoteModel, props) => (
              <SequenceKebabMenu
                data-testid={`quote.${model.id}.menu`}
                buttonSize="small"
                alignment="bottom-right"
                items={[
                  <DuplicateQuoteMenuItem
                    key="duplicate-quote"
                    quoteId={model.id}
                    onSuccess={(newQuoteId: string) =>
                      navigate(`/quotes/${newQuoteId}`)
                    }
                  />,
                  ...(flags.useQuoteTemplates
                    ? [
                        <CreateTemplateFromQuoteMenuItem
                          key="create-template-from-quote"
                          quoteId={model.id}
                          onSuccess={(newQuoteTemplateId: string) =>
                            navigate(`/quote-templates/${newQuoteTemplateId}`)
                          }
                        />
                      ]
                    : []),
                  ({ setIsOpen }) =>
                    !model.archivedAt && (
                      <ArchiveQuoteMenuItem
                        key="archive-quote"
                        data-testid={`quote.${model.id}.archive`}
                        quoteId={model.id}
                        onSuccess={() => {
                          setIsOpen?.(false)
                        }}
                      />
                    )
                ]}
                {...props}
              />
            )}
            emptyStateUnfiltered={entityNamePlural => (
              <MagicTableEmptyState
                entityNamePlural={entityNamePlural}
                imageSrc="/img/empty/quotes.png"
                learnMoreText="Create order forms, proposals and sales quotes with custom deal terms in minutes"
                learnMoreHref="https://docs.sequencehq.com/cpq/create-quotes"
                buttonNew={{
                  label: 'New quote',
                  onClick: () => navigate('/quotes/new')
                }}
              />
            )}
            emptyStateFiltered={(entityNamePlural, clearFilters) => (
              <MagicTableFilterEmptyState
                entityNamePlural={entityNamePlural}
                entityIcon={DocumentCheckIcon}
                actionClearFilters={clearFilters}
                actionViewAll={() => navigate('/quotes')}
                variant="TABLE"
              />
            )}
            filters={filters}
          />
        </>
      )}
    </CurrentUserId>
  )
}

export default QuotesMagicTable
