import { useComplexQuery, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { keyBy } from 'lodash'
import { useMemo } from 'react'
import { allIds } from '../utils'
import { useBulkActions } from './useBulkActions'
import { chunk } from 'lodash/fp'

export const useEnrichedBulkActions = (
  refetchInterval?: number | undefined
) => {
  const { infiniteQuery, bulkActions } = useBulkActions({
    refetchInterval
  })

  // Right now this is coupled to the expectation that the bulk actions always apply to invoices
  const invoiceIds = useMemo(
    () => new Set(bulkActions.map(allIds).flat()),
    [bulkActions]
  )

  /**
   * The enrichment endpoint cannot receive more than 100 invoices, and it is not a paginated endpoint.
   * We therefore need to be a little more fancy here, and chunk the requests into batches of 100.
   */
  const invoicesLite = useComplexQuery({
    queryKey: [
      dashboard20240730Client.listInvoicesLite.queryKey,
      /**
       * This ensures that this cached data is uniquely cached for this use case, but by prefixing with
       * the queryKey from the request we benefit from invalidation, too.
       */
      'CUSTOM:useEnrichedBulkActions',
      JSON.stringify(Array.from(invoiceIds))
    ],
    queryFn: async () => {
      const batches = chunk(100, Array.from(invoiceIds))
      const resolvedBatches = await Promise.all(
        batches.map(async batch => {
          const res = await dashboard20240730Client.listInvoicesLite.request({
            invoiceIds: batch
          })

          return res.data?.items ?? []
        })
      )

      return resolvedBatches.flat()
    }
  })

  const invoices = keyBy(invoicesLite.data, 'id')

  const { hasNextPage, fetchNextPage } = infiniteQuery

  const isLoading = infiniteQuery.isLoading || invoicesLite.isLoading

  return { hasNextPage, fetchNextPage, isLoading, bulkActions, invoices }
}
