import { Flex, Text } from '@chakra-ui/react'
import {
  SequenceKebabMenu,
  SequenceMenuItemType
} from '@sequencehq/core-components'
import { Lato16Bold } from '@sequencehq/design-tokens'

export const SavedViewTitle = ({
  title,
  kebabMenuItems
}: { title: string; kebabMenuItems: SequenceMenuItemType[] | undefined }) => {
  return (
    <Flex gap="8px" alignItems="center">
      <Text {...Lato16Bold}>{title}</Text>
      {kebabMenuItems && (
        <SequenceKebabMenu
          variant="ghost"
          items={kebabMenuItems}
          width={195}
          alignment="bottom-right"
        />
      )}
    </Flex>
  )
}
