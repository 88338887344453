import { Box, Flex, Grid, GridItem } from '@chakra-ui/react'
import { useIntegrationsNavigation } from 'modules/Integrations/view/integrationsNavigation/useIntegrationsNavigation'
import {
  GreyGrey60,
  GreyGrey70,
  IndigoIndigo50,
  Lato12Bold,
  Lato13Regular,
  YellowYellow50
} from '@sequencehq/design-tokens'
import {
  ArrowPathIcon,
  ChevronDownIcon,
  ChevronUpIcon,
  ExclamationTriangleIcon,
  SquaresPlusIcon
} from '@heroicons/react/16/solid'
import { IntegrationsBrowserModal } from 'modules/Integrations/view/integrationsBrowserModal/IntegrationsBrowserModal'
import { IntegrationsNavLink } from 'modules/Integrations/view/integrationsNavigation/IntegrationsNavLink'
import { NavLinksLoading } from 'components/NavigationV3/components/NavLinks/NavLinksLoading'

/**
 * TODO: The sublist is subtly different to the existing pattern seen in the navbar, but the
 * items are otherwise super similar. It would be good to tweak the NavigationV3 components to
 * account for this various and reuse them here. DAVIN-579.
 * @returns
 */
export const IntegrationsNavigation = () => {
  const { integrationsList, loading, error, reloadIntegrations } =
    useIntegrationsNavigation()

  return (
    <>
      <Box padding="8px 8px" userSelect="none">
        <Grid
          padding="0 8px"
          templateColumns="1fr auto"
          onClick={integrationsList.toggleExpand}
          height="24px"
          alignContent="center"
          cursor="pointer"
          borderRadius="6px"
          color={GreyGrey60}
          _hover={{
            color: GreyGrey70
          }}
        >
          <GridItem
            {...Lato12Bold}
            fontSize="11px"
            textTransform="uppercase"
            data-testid="navgroup.integrations"
          >
            Integrations
          </GridItem>
          <GridItem>
            {integrationsList.isExpanded ? (
              <ChevronUpIcon height="16px" width="16px" color={GreyGrey70} />
            ) : (
              <ChevronDownIcon height="16px" width="16px" color={GreyGrey70} />
            )}
          </GridItem>
        </Grid>
        {integrationsList.isExpanded && (
          <Flex flexDirection="column" gap="1px">
            {loading && <NavLinksLoading />}

            {error && (
              <Grid
                alignItems="center"
                height="32px"
                padding="0 8px"
                gridColumnGap="8px"
                gridTemplateColumns="auto 1fr auto"
                {...Lato13Regular}
                color={IndigoIndigo50}
                onClick={reloadIntegrations}
                cursor="pointer"
              >
                <ArrowPathIcon height="16px" width="16px" />
                Try again
                <ExclamationTriangleIcon
                  height="16px"
                  width="16px"
                  style={{
                    position: 'relative',
                    top: '1px'
                  }}
                  color={YellowYellow50}
                />
              </Grid>
            )}

            {!loading && !error && (
              <>
                {integrationsList.integrations.map(integration => (
                  <IntegrationsNavLink
                    key={integration.id}
                    active={integration.active}
                    logo={integration.logo}
                    label={integration.label}
                    onClick={integration.onClick}
                    status={integration.status}
                  />
                ))}
                <IntegrationsBrowserModal
                  trigger={
                    <IntegrationsNavLink
                      logo={(props: { height: string; width: string }) => (
                        <SquaresPlusIcon {...props} />
                      )}
                      label="Browse integrations"
                    />
                  }
                />
              </>
            )}
          </Flex>
        )}
      </Box>
    </>
  )
}
