export const formatQuoteTitle = (args: {
  domainTitle: string
  isArchived: boolean
}) => {
  const titleValue = args.domainTitle || 'Untitled'
  if (args.isArchived) {
    return `(archived) ${titleValue}`
  } else {
    return titleValue
  }
}
