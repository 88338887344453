import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { PriceModel } from '@sequencehq/core-models'

export type DiscountCalculationType = 'NOMINAL' | 'PERCENTAGE'
export type ApplyDiscountTo = 'SPECIFIC' | 'ALL'
export type SeatDiscountType =
  | 'INCLUDED_SEATS_ONLY'
  | 'OVERAGE_SEATS_ONLY'
  | 'ALL_SEATS'

export type DiscountEditorValidationError = {
  fieldName: string
  message: string
  metadata?: Record<string, unknown>
  value: unknown
}

export type DiscountEditorDiscount = {
  id: string
  name: string
  currency: Currency
  discountCalculationType: DiscountCalculationType
  nominalValue: string
  percentageValue: string
  priceIds: string[]
  applyTo: ApplyDiscountTo
  seatDiscountType: SeatDiscountType
}

export enum DiscountEditorMode {
  VIEW = 'view',
  EDIT = 'edit',
  CREATE = 'create'
}

export type DiscountEditorPrice = Omit<
  PriceModel,
  'sequenceAccountId' | 'createdAt' | 'updatedAt'
>
export type DiscountEditorRootProps = {
  existingDiscount?: DiscountEditorDiscount
  mode: DiscountEditorMode
  onClose: () => void
  onSave: (newDiscount: DiscountEditorDiscount) => void
  allPrices: DiscountEditorPrice[]
  availablePrices: string[]
  defaultApplyTo?: ApplyDiscountTo
  applyToAllAvailable: boolean
  currency: Currency
}

export type DiscountEditorEditorState = {
  mode: DiscountEditorMode
  showErrors: boolean
}

export type DiscountEditorDomainInterface = {
  data: {
    discount: DiscountEditorDiscount
    prices: Record<string, DiscountEditorPrice>
  }
  queries: {
    allValidationErrors: DiscountEditorValidationError[]
    validation: DiscountEditorValidationError[]
    canSave: boolean
    changesMade: boolean
  }
  configuration: {
    availablePrices: string[]
    applyToAllAvailable: boolean
  }
  editor: DiscountEditorEditorState
  functions: {
    updateDiscount: (newDiscountData: Partial<DiscountEditorDiscount>) => void
    saveDiscount: () => void
    closeEditor: () => void
  }
}
