import { Box } from '@chakra-ui/react'
import { BookmarkIcon } from '@heroicons/react/16/solid'
import { NavLinkButton } from 'components/NavigationV3/components/NavLinks/NavLink'
import { NavSection } from 'components/NavigationV3/components/NavSection'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useLocation } from 'react-router-dom'
import { useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { NavLinksLoading } from 'components/NavigationV3/components/NavLinks/NavLinksLoading'
import { savedViewsPorts } from 'SavedViews/savedViews.ports'
import { SAVED_VIEWS_STALE_TIME } from 'SavedViews/savedViews.constants'

export const SavedViewsNavigation = () => {
  const { pathname } = useLocation()
  const flags = useFlags()

  const { data: savedViews, isLoading } = useQuery(
    dashboard20240730Client.getSavedViews,
    undefined,
    {
      select: data => {
        if (!data) {
          return null
        }

        return savedViewsPorts.savedViews.fromServer(data)
      },
      staleTime: SAVED_VIEWS_STALE_TIME
    }
  )

  if ((!isLoading && !savedViews?.length) || !flags.useSavedViews) {
    return null
  }

  return (
    <Box padding="8px 8px" userSelect="none">
      <NavSection
        id="saved-views"
        title="Saved views"
        expandOnMatchingPath={pathname => pathname.startsWith('/saved-view/')}
      >
        {isLoading ? (
          <NavLinksLoading />
        ) : (
          savedViews?.map(view => (
            <NavLinkButton
              key={view.id}
              href={`saved-view/${view.id}`}
              icon={BookmarkIcon}
              isActive={pathname.startsWith(`/saved-view/${view.id}`)}
            >
              {view.name}
            </NavLinkButton>
          ))
        )}
      </NavSection>
    </Box>
  )
}
