import {
  PdfQuoteRendererData,
  ReactQuoteRendererData
} from '@sequencehq/quote-content'
import { quoteContactToContactOption } from 'modules/Cube/communication/external/quotes.api.v1/adapters/quote.adapters.out'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import {
  Address,
  CubeStatus,
  Customer
} from 'modules/Cube/domain/cube.domain.types'
import { apiDatesAdapters } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/apiDates.adapters'
import { states, toCountryLabel } from '@sequencehq/core-models'
import {
  calculatePhaseTotalAmount,
  Phase as TotalAmountPhase
} from '@sequencehq/utils/src/phase/calculatePhaseTotalAmount.ts'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { add, Duration } from '@sequencehq/utils/dates'
import { v1ApiUpdateQuote } from 'Cube/communication/external/quotes.api.v1/ports/entitySaving/useSaveQuote'
import { phasesToQuotePhases } from 'Cube/communication/external/common.api.v1/adapters/common.adapters.out'

const estimateExpiresAt = ({
  status,
  publishedAt,
  expiresIn = { days: 30 }
}: {
  status: CubeStatus
  publishedAt: Date | undefined
  expiresIn: Duration | undefined
}): string => {
  /**
   * For published and unedited quotes, use the publishedAt value as the date to calculate the expiresAt date.
   * Otherwise use the current time.
   */

  const baseDate =
    status === CubeStatus.QuoteDraft || !publishedAt ? new Date() : publishedAt

  return apiDatesAdapters.toApi(add(baseDate, expiresIn))
}

export const formatApiAddress = (address: Address): string[] =>
  [
    address.line1,
    address.line2,
    address.town,
    address.postcode,
    'state' in address
      ? states[address.state as keyof typeof states] ?? ''
      : undefined,
    toCountryLabel(address.country)
  ].filter(Boolean) as string[]

export const apiPhaseToCalculatePhaseTotalAmountPhase = (
  phases: v1ApiUpdateQuote['phases']
): TotalAmountPhase[] =>
  phases.map(phase => ({
    ...phase,
    discounts: phase.discounts.map(discount => ({
      ...discount,
      discountCalculationType: discount.type
    })),
    minimums: phase.minimums.map(minimum => ({
      id: '',
      ...minimum
    }))
  }))

export const previewRenderers = {
  reactPreview: {
    in: (
      data: CubeDomainInterface['queries']['rawData']['data']
    ): ReactQuoteRendererData => {
      const flags = useFlags()

      const customers = data.customers

      const selectedCustomer: Customer | undefined =
        customers[data.common.customerId]

      const quoteContacts = data.quote.contacts

      const contacts = quoteContacts.map(contact =>
        quoteContactToContactOption(contact)
      )

      const phases = phasesToQuotePhases(data)

      const { totalDiscountedRate, totalFullRate } = flags.useQuoteTotals
        ? calculatePhaseTotalAmount(
            apiPhaseToCalculatePhaseTotalAmountPhase(phases)
          )
        : { totalDiscountedRate: undefined, totalFullRate: undefined }

      return {
        title: data.common.title,
        prospectName: selectedCustomer?.legalName,
        prospectAddress: selectedCustomer?.address
          ? formatApiAddress(selectedCustomer.address)
          : undefined,
        merchantName: data.merchantBranding.legalCompanyName,
        merchantAddress: formatApiAddress(data.merchantBranding.address),
        merchantEmailAddress: data.merchantBranding.email,
        merchantLogoUrl: data.merchantBranding.logoUrl,
        phases,
        preliminaryBillingStartDate: data.common.startDate
          ? apiDatesAdapters.toApi(data.common.startDate)
          : undefined,
        expiresAt: estimateExpiresAt({
          status: data.common.status,
          publishedAt: data.quote.publishedAt,
          expiresIn: data.quote.expiresIn
        }),
        presentation: data.presentation,
        dealType: data.quote.dealType,
        contacts,
        counterSigners: data.quote.counterSigners,
        attachmentAssets: data.quote.attachmentAssets,
        totalDiscountedRate,
        totalFullRate
      }
    }
  },
  pdfPreview: {
    in: (
      data: CubeDomainInterface['queries']['rawData']['data']
    ): PdfQuoteRendererData => {
      const flags = useFlags()

      const customers = data.customers

      const selectedCustomer: Customer | undefined =
        customers[data.common.customerId]

      const phases = phasesToQuotePhases(data)

      const { totalDiscountedRate, totalFullRate } = flags.useQuoteTotals
        ? calculatePhaseTotalAmount(
            apiPhaseToCalculatePhaseTotalAmountPhase(phases)
          )
        : { totalDiscountedRate: undefined, totalFullRate: undefined }

      return {
        title: data.common.title,
        prospectName: selectedCustomer?.legalName,
        prospectAddress: selectedCustomer?.address
          ? formatApiAddress(selectedCustomer.address)
          : undefined,
        merchantName: data.merchantBranding.legalCompanyName,
        merchantAddress: formatApiAddress(data.merchantBranding.address),
        merchantEmailAddress: data.merchantBranding.email,
        merchantLogoUrl: data.merchantBranding.logoUrl,
        merchantBrandColour: data.merchantBranding.primaryColour,
        phases,
        preliminaryBillingStartDate: data.common.startDate
          ? apiDatesAdapters.toApi(data.common.startDate)
          : undefined,
        expiresAt: estimateExpiresAt({
          status: data.common.status,
          publishedAt: data.quote.publishedAt,
          expiresIn: data.quote.expiresIn
        }),
        presentation: data.presentation,
        publishedAt: data.quote.publishedAt?.toISOString(),
        quoteNumber: data.common.alias,
        dealType: data.quote.dealType,
        signers: [],
        includeSignaturePage: false,
        totalDiscountedRate,
        totalFullRate
      }
    }
  }
}
