import { useCallback } from 'react'
import * as entityLoaders from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entityLoaders'
import * as commonEntityLoaders from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders'

import { useNotifications } from 'lib/hooks/useNotifications'
import {
  NEW_PHASE_PREFIX,
  NEW_ROOT_ENTITY_PREFIX
} from 'modules/Cube/domain/cube.constants'
import { apiDatesAdapters } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/apiDates.adapters'
import { add } from '@sequencehq/utils/dates'
import { v1ApiPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadPrices'
import { v1ApiProduct } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadProducts'
import { v1ApiIntegration } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadIntegrations'
import {
  v1ApiBillingSchedule,
  v1ApiBillingScheduleSettings
} from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entityLoaders/useLoadBillingSchedule'
import { v1ApiTaxRate } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/entityLoaders/useLoadSingleTaxRate'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import {
  CorePortErrors,
  CubePortImplementationProp
} from 'modules/Cube/domain/cube.domain.types.ts'
import { v1ApiListPrice } from 'modules/Cube/communication/external/common.api.v1/ports/entityLoaders/useLoadListPrices'
import { dashboardv99990101Client } from '@sequencehq/api/dashboard/v99990101'
import { ApiCustomer } from '../../common.api.v1/ports/entityLoaders/useLoadSingleCustomer'

export type LoadedBillingScheduleData = {
  billingSchedule: Omit<
    v1ApiBillingSchedule,
    'status' | 'isDraft' | 'prices'
  > & {
    status: v1ApiBillingSchedule['status'] | 'NEW'
  }
  billingScheduleSettings?: {
    paymentProvider: v1ApiBillingScheduleSettings['paymentProvider']
    autoCharge: v1ApiBillingScheduleSettings['autoCharge']
  }
  prices: v1ApiPrice[]
  listPrices: v1ApiListPrice[]
  products: v1ApiProduct[]
  taxRates: v1ApiTaxRate[]
  customers: ApiCustomer[]
  integrations: v1ApiIntegration[]
  enabledCurrencies: Currency[]
}

type UseLoadBillingScheduleEditor = () => (ctx: CubePortImplementationProp) => (
  billingScheduleId?: string
) => Promise<{
  data: LoadedBillingScheduleData | null
  error: CorePortErrors | null
}>

/**
 * This hook is used for the initial load of critical data for the editor, such
 * as the billing schedule and associated settings. Note that we do not need to
 * load information such as prices in the critical path, as these are 'visual
 * enhancements' which are handled by their relevant components.
 */
export const useLoadBillingScheduleEditor: UseLoadBillingScheduleEditor =
  () => {
    const { displayNotification } = useNotifications()
    /**
     * We have a fair bit to load, and it can be split into multiple phases - the
     * first is loading the billing schedule itself, and we will then 'hydrate' the
     * ids in the schedule.
     */
    const pricesLoader = commonEntityLoaders.useLoadPrices()
    const listPricesLoader = commonEntityLoaders.useLoadListPrices()
    const productsLoader = commonEntityLoaders.useLoadProducts()
    const customerLoader = commonEntityLoaders.useLoadSingleCustomer()
    const allProductsLoader = commonEntityLoaders.useLoadAllProducts()
    const integrationsLoader = commonEntityLoaders.useLoadIntegrations()
    const enabledCurrenciesLoader =
      commonEntityLoaders.useLoadEnabledCurrencies()

    const billingScheduleLoader = entityLoaders.useLoadBillingSchedule()
    const allTaxRatesLoader = entityLoaders.useLoadAllTaxRates()

    /**
     * Loads data for a billing schedule, or returns a
     * default 'new' billing schedule
     */
    const loadBillingSchedule = useCallback(
      (ctx: CubePortImplementationProp) =>
        async (
          billingScheduleId?: string
        ): Promise<{
          data: Pick<
            LoadedBillingScheduleData,
            'billingSchedule' | 'billingScheduleSettings'
          > | null
          error: CorePortErrors | null
        }> => {
          if (!billingScheduleId) {
            const defaultAutoChargeSetting =
              await dashboardv99990101Client.getStripeCollectionsSettings()

            return Promise.resolve({
              data: {
                billingSchedule: {
                  id: `${NEW_ROOT_ENTITY_PREFIX}${crypto.randomUUID()}`,
                  status: 'NEW',
                  type: 'CUSTOM',
                  startDate: apiDatesAdapters.toApi(new Date()),
                  endDate: apiDatesAdapters.toApi(
                    add(new Date(), { years: 1, days: -1 })
                  ),
                  autoIssueInvoices: false,
                  recurrenceDayOfMonth: 1,
                  phases: [
                    {
                      id: `${NEW_PHASE_PREFIX}${crypto.randomUUID()}`,
                      startDate: apiDatesAdapters.toApi(new Date()),
                      endDate: apiDatesAdapters.toApi(
                        add(new Date(), { years: 1, days: -1 })
                      ),
                      priceIds: [],
                      discounts: [],
                      minimums: [],
                      recurrencePreference:
                        'CONTINUE_FROM_PREVIOUS_PHASE' as const,
                      phasePriceMetadata: []
                    }
                  ],
                  customerId: ctx?.defaultValues?.customerId ?? '',
                  purchaseOrderNumber: '',
                  taxRates: [],
                  rollUpBilling: false,
                  createdAt: new Date().toISOString(),
                  attachmentAssets: [],
                  integrationIds: []
                } as LoadedBillingScheduleData['billingSchedule'],
                billingScheduleSettings: {
                  paymentProvider: 'NONE',
                  autoCharge: Boolean(
                    defaultAutoChargeSetting?.data?.enableAutomaticPayments
                  )
                }
              },
              error: null
            })
          }

          return billingScheduleLoader(billingScheduleId)
        },
      [billingScheduleLoader]
    )

    const load = useCallback(
      (ctx: CubePortImplementationProp) =>
        async (billingScheduleId?: string) => {
          try {
            const loadedBillingSchedule =
              await loadBillingSchedule(ctx)(billingScheduleId)

            if (!loadedBillingSchedule.data || loadedBillingSchedule.error) {
              throw new Error("Couldn't load billing schedule")
            }

            const { billingSchedule, billingScheduleSettings } =
              loadedBillingSchedule.data

            const allPriceIds =
              billingSchedule.phases?.flatMap(({ priceIds }) => priceIds) ?? []

            const [
              integrationsLoaderData,
              pricesLoaderData,
              customerLoaderData,
              allTaxRatesLoaderData,
              allProductsLoaderData,
              enabledCurrencies,
              listPricesLoaderData
            ] = await Promise.all([
              integrationsLoader(),
              billingScheduleId
                ? pricesLoader({ priceIds: allPriceIds })
                : Promise.resolve({ prices: [] }),
              customerLoader({
                customerId: billingSchedule.customerId
              }),
              allTaxRatesLoader(),
              allProductsLoader(),
              enabledCurrenciesLoader(),
              listPricesLoader()
            ])

            const productsLoaderData = await productsLoader({
              prices: pricesLoaderData.prices
            })

            return {
              data: {
                billingSchedule,
                billingScheduleSettings,
                prices: pricesLoaderData.prices,
                listPrices: listPricesLoaderData,
                products: [
                  ...productsLoaderData.products,
                  ...allProductsLoaderData
                ],
                taxRates: allTaxRatesLoaderData,
                customers: [customerLoaderData].filter((c): c is ApiCustomer =>
                  Boolean(c)
                ),
                integrations: integrationsLoaderData.integrations,
                enabledCurrencies
              },
              error: null
            }
          } catch {
            displayNotification('Could not load billing schedule', {
              type: 'error'
            })
            return {
              data: null,
              error: CorePortErrors.Other
            }
          }
        },
      [
        loadBillingSchedule,
        integrationsLoader,
        pricesLoader,
        customerLoader,
        allTaxRatesLoader,
        allProductsLoader,
        enabledCurrenciesLoader,
        listPricesLoader,
        productsLoader,
        displayNotification
      ]
    )

    return load
  }
