import { FC } from 'react'
import { Box, Fade, Flex, Image, Text } from '@chakra-ui/react'
import { useNavigationV3Context } from 'components/NavigationV3/hooks/useNavigationV3Context'
import {
  GreyGrey70,
  Lato13Regular,
  Lato20Bold,
  LineHeights24
} from '@sequencehq/design-tokens'
import { Avatar } from 'components/NavigationV3/components/Avatar/Avatar'
import { getLogoSrc } from 'Customer/utils/logo'
import { useFetchMerchantBySequenceAccountId } from 'components/MerchantSettings/hooks/useFetchMerchantBySequenceAccountId'
import { parse } from 'tldts'

export const HomeHeader: FC = () => {
  const {
    data: { workspaceName, userEmail }
  } = useNavigationV3Context()

  return (
    <Flex alignItems="center" gap="16px">
      <WorkspaceAvatar workspaceName={workspaceName} />

      <Flex direction="column" gap="4px">
        <Text
          textStyle="sectionHeader"
          {...Lato20Bold}
          lineHeight={LineHeights24}
        >
          Welcome back, {workspaceName}
        </Text>

        <Text color={GreyGrey70} {...Lato13Regular}>
          Logged in as {userEmail}
        </Text>
      </Flex>
    </Flex>
  )
}

const WorkspaceAvatar = ({ workspaceName }: { workspaceName: string }) => {
  const { merchant } = useFetchMerchantBySequenceAccountId()

  const merchantDomain =
    merchant && parse(merchant.appUrl ?? merchant.email).domain

  const logoSrc = getLogoSrc({ domain: merchantDomain ?? undefined })

  return (
    <Box width="48px" height="48px" borderRadius="9999px">
      <Fade in={Boolean(merchant && logoSrc)}>
        <Image
          src={logoSrc}
          width="48px"
          height="48px"
          alt={`${workspaceName} logo`}
          style={{
            borderRadius: '9999px'
          }}
        />
      </Fade>

      <Fade in={Boolean(merchant && !logoSrc)}>
        <Avatar name={workspaceName} size="lg" />
      </Fade>
    </Box>
  )
}
