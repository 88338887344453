import { useNotifications } from 'lib/hooks/useNotifications'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import {
  CorePortErrors,
  CubePortImplementation,
  CubePortImplementationProp,
  QuoteTemplateCubePorts
} from 'modules/Cube/domain/cube.domain.types'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { quoteTemplateAdapters } from './adapters/quoteTemplate.adapters'
import { useLoadQuoteTemplateEditor } from './ports/useLoadQuoteTemplateEditor'
import { useSaveQuoteTemplateEditor } from './ports/useSaveQuoteTemplateEditor'

type UseQuoteTemplatePorts = (props: {
  quoteTemplateId?: string
}) => CubePortImplementation

export const useQuoteTemplatePorts: UseQuoteTemplatePorts = props => {
  const quoteTemplateLoader = useLoadQuoteTemplateEditor()
  const saveQuoteTemplateEditor = useSaveQuoteTemplateEditor()
  const notifications = useNotifications()
  const navigate = useNavigate()

  const [existingData, setExistingData] =
    useState<CubeDomainInterface['queries']['rawData']['data']>()

  const saveQuoteTemplate = useCallback(
    async (domainOutput: CubeDomainInterface['queries']): Promise<boolean> => {
      if (!existingData) {
        throw new Error("Can't save without initialising first")
      }

      const dataToSave = quoteTemplateAdapters.toApi(domainOutput.rawData.data)

      if (domainOutput.rawData.data.common.id !== 'uninitialized') {
        const saveResult = await saveQuoteTemplateEditor.updateQuoteTemplate(
          domainOutput.rawData.data.common.id
        )(dataToSave)

        notifications.displayNotification('Quote template updated', {
          type: 'success'
        })

        if (saveResult.success) {
          return true
        }
      } else {
        notifications.displayNotification('Creating quote template...', {
          duration: 30000
        })

        const createResult = await saveQuoteTemplateEditor.createQuoteTemplate()
        if (createResult.success) {
          notifications.displayNotification('Quote template created', {
            type: 'success'
          })

          if (createResult.quoteTemplate?.template.id) {
            navigate(
              `/quote-templates/${createResult.quoteTemplate.template.id}`,
              {
                replace: true
              }
            )
          }
        }
        return true
      }

      notifications.displayNotification('Failed to save quote template', {
        type: 'error'
      })
      return false
    },
    [existingData, navigate, notifications, saveQuoteTemplateEditor]
  )

  const loadQuoteTemplate = useCallback(
    (ctx: CubePortImplementationProp) => async () => {
      const { data: quoteTemplateData, error: quoteTemplateError } =
        await quoteTemplateLoader(ctx)(props.quoteTemplateId)

      if (quoteTemplateError || !quoteTemplateData) {
        return {
          data: null,
          error: quoteTemplateError || CorePortErrors.Other
        }
      }

      const resolvedData = quoteTemplateAdapters.toCube(quoteTemplateData)

      if (resolvedData.data.common.id !== props.quoteTemplateId) {
        navigate(`/quote-templates/${resolvedData.data.common.id}`, {
          replace: true
        })
      }

      setExistingData(resolvedData.data)

      const data = await Promise.resolve({
        data: resolvedData.data,
        configuration: {
          ...ctx.configuration,
          currency: {
            default: quoteTemplateData.currencies[0],
            enabled: quoteTemplateData.currencies
          }
        }
      })

      return {
        data,
        error: null
      }
    },
    [quoteTemplateLoader, props.quoteTemplateId, navigate]
  )

  const portImplementation: CubePortImplementation = useCallback(
    (ctx): QuoteTemplateCubePorts => ({
      in: {
        core: loadQuoteTemplate(ctx),
        customer: () => Promise.reject()
      },
      out: {
        save: saveQuoteTemplate,
        /**
         * We handle this as part of the menu item for quote templates - we'll eventually
         * refactor other ports as well as remove this entirely.
         * @returns
         */
        archive: () => Promise.reject()
      }
    }),
    [loadQuoteTemplate, saveQuoteTemplate]
  )

  return portImplementation
}
