import { apiDatesAdapters } from 'modules/Cube/communication/external/billingSchedule.api.v1/utils/apiDates.adapters'
import {
  PhaseDuration,
  Price,
  ResolvedPhase
} from 'modules/Cube/domain/cube.domain.types'
import { CubeDomainInterface } from 'modules/Cube/domain/cube.domain'
import { SaveBillingScheduleData } from 'modules/Cube/communication/external/billingSchedule.api.v1/ports/useSaveBillingScheduleEditor'
import { uniqBy, compose, isObject } from 'lodash/fp'
import { NEW_PRODUCT_PATTERN } from 'modules/Cube/domain/cube.constants'
import invariant from 'tiny-invariant'
import { add } from '@sequencehq/utils/dist/dates'

type SaveDataComposition = (
  existingData: CubeDomainInterface['queries']['rawData']['data']
) => (
  data: CubeDomainInterface['queries']
) => (prevData: SaveBillingScheduleData) => SaveBillingScheduleData

export const saveBillingSchedulePhaseDates = (args: {
  phaseDuration: PhaseDuration
  absoluteDates: {
    start?: Date | undefined
    end?: Date | undefined
  }
}): {
  startDate: string
  endDate: string
} => {
  if (args.phaseDuration === 'OPEN_ENDED') {
    return {
      startDate: apiDatesAdapters.toApi(args.absoluteDates.start),
      endDate: ''
    }
  }

  return {
    startDate: apiDatesAdapters.toApi(args.absoluteDates.start),
    endDate: apiDatesAdapters.toApi(args.absoluteDates.end)
  }
}

/**
 * We need to 'fake it until we make it' with persisting milestone billing.
 * However, we also have fun in the API land where phases need to be contiguous
 * or it throws a fit as well. This function will therefore take all of the
 * resolved phases and modify the absolute dates to ensure that they are contiguous
 * but also add large (100 year) durations for the Milestone billing phases to
 * allow us to identify them.
 *
 * We do this here so that the rest of Cube is as unaware as possible of the
 * 'hack' and just knows milestone as the string.
 *
 * @param allResolvedPhases
 * @returns
 */
export const modifyAbsoluteDatesForMilestoneBilling = (
  allResolvedPhases: Array<ResolvedPhase>
): Array<ResolvedPhase> => {
  return allResolvedPhases.reduce((acc: Array<ResolvedPhase>, phase, idx) => {
    const previousPhaseEndDate = acc[acc.length - 1]?.dates.absolute.end
    invariant(
      idx === 0 || previousPhaseEndDate,
      'Previous phase end date is required'
    )

    const phaseStartDate =
      idx === 0 || !previousPhaseEndDate
        ? phase.dates.absolute.start
        : add(previousPhaseEndDate, { days: 1 })
    invariant(phaseStartDate, 'Phase start date is required')

    if (phase.dates.duration === 'MILESTONE') {
      return [
        ...acc,
        {
          ...phase,
          dates: {
            ...phase.dates,
            absolute: {
              start: phaseStartDate,
              end: add(phaseStartDate, { years: 100 })
            }
          }
        }
      ]
    }

    return [
      ...acc,
      {
        ...phase,
        dates: {
          ...phase.dates,
          absolute: {
            start: phaseStartDate,
            end: isObject(phase.dates.duration)
              ? add(phaseStartDate, phase.dates.duration)
              : undefined
          }
        }
      }
    ]
  }, [])
}

export const createBaseBillingSchedulePhasesApiData = (
  domainQueries: CubeDomainInterface['queries']
): SaveBillingScheduleData => {
  const allPrices = Object.values(domainQueries.resolvedPhases)
    .flatMap(phase => phase.prices)
    .filter(Boolean)

  const paymentProvider = domainQueries.rawData.data.schedule.stripePayment
    ? 'STRIPE'
    : 'NONE'

  const phasesWithMassagedDates = modifyAbsoluteDatesForMilestoneBilling(
    Object.values(domainQueries.resolvedPhases)
  )

  return {
    billingSchedule: {
      rollUpBilling: domainQueries.rawData.data.schedule.rollUpBilling,
      phases: phasesWithMassagedDates.map(resolvedPhase => ({
        name: resolvedPhase.name,
        priceIds: resolvedPhase.prices.map(({ id }) => id),
        ...saveBillingSchedulePhaseDates({
          phaseDuration: resolvedPhase.dates.duration,
          absoluteDates: resolvedPhase.dates.absolute
        }),
        minimums: resolvedPhase.minimums.map(minimum => ({
          amount: parseFloat(minimum.value),
          restrictToPrices:
            minimum.scope.target === 'allUsage' ? [] : minimum.scope.priceIds
        })),
        discounts: resolvedPhase.discounts.map(discount => ({
          amount: discount.amount,
          restrictToPrices: discount.priceIds,
          type: discount.discountCalculationType,
          message: discount.message,
          seatDiscountType: discount.seatDiscountType
        })),
        recurrencePreference: resolvedPhase.recurrencePreference,
        phasePriceMetadata: domainQueries.rawData.configuration.features
          .phasePriceMetadataEditing
          ? resolvedPhase.prices.map(
              price =>
                resolvedPhase.phasePriceMetadata.find(
                  ppm => ppm.priceId === price.id
                ) ?? {
                  priceId: price.id,
                  arrCalculation: 'INCLUDE'
                }
            )
          : []
      })),
      customerId: domainQueries.rawData.data.common.customerId,
      startDate: apiDatesAdapters.toApi(
        phasesWithMassagedDates[0].dates.absolute.start
      ),
      endDate: apiDatesAdapters.toApi(
        phasesWithMassagedDates[phasesWithMassagedDates.length - 1].dates
          .absolute.end
      ),
      taxRates: domainQueries.rawData.data.schedule.taxRateId
        ? allPrices.map(price => ({
            priceId: price.id,
            taxRateId: domainQueries.rawData.data.schedule.taxRateId
          }))
        : [],
      autoIssueInvoices: domainQueries.rawData.data.schedule.autoIssueInvoices,
      recurrenceDayOfMonth:
        domainQueries.rawData.data.schedule.recurrenceDayOfMonth,
      purchaseOrderNumber:
        domainQueries.rawData.data.schedule.purchaseOrderNumber,
      reference: domainQueries.rawData.data.schedule.reference,
      label: domainQueries.rawData.data.schedule.label,
      attachmentAssetIds:
        domainQueries.rawData.data.schedule.attachmentAssets.map(
          asset => asset.id
        )
    },
    billingScheduleSettings: {
      paymentProvider,
      autoCharge:
        paymentProvider === 'STRIPE'
          ? domainQueries.rawData.data.schedule.stripeAutoCharge
          : undefined
    },
    prices: {
      all: [],
      new: [],
      deleted: []
    },
    products: {
      new: []
    }
  }
}

/**
 * Price creation is rather challenging - we need to perform a
 * replacement on discounts, tax rates, and schedules in order
 * to correctly scope them to prices.
 *
 * The complexity here is that we are leaking some desire to optimise
 * the number of priceIds generated to the FE, which means if we can
 * help it we don't want to create unnecessary new prices.
 *
 * However, a scenario in which we must create new prices is when a
 * discount is present for a price, as a discount has no inherent way
 * to scope to a billing schedule version right now on the API.
 *
 * Therefore, we must replace existing prices, on save, if a discount
 * is assigned to that priceId for any version (phase).
 * @param existingData
 * @returns
 */
export const createPhasedPricesSaveData: SaveDataComposition =
  existingData => domainQueries => prevData => {
    const allPrices = uniqBy<Price>('id')(
      Object.values(domainQueries.resolvedPhases)
        .flatMap(({ prices }) => prices)
        .filter(Boolean)
        .map(price => ({
          ...price,
          integrationIds:
            price.integrationIds?.filter(i => i.service && i.id) ?? []
        }))
    )

    const allExistingPrices = Object.values(existingData.phases)
      .flatMap(phase =>
        phase.priceIds.map(
          priceId => domainQueries.rawData.data.prices[priceId]
        )
      )
      .filter(Boolean)

    /**
     * Take the new prices and extract any replacements, and then run a replacement against
     * discounts, billing schedule prices, and tax rates
     */
    return {
      ...prevData,
      prices: {
        all: allPrices,
        new: allPrices.filter(price => {
          return !allExistingPrices.find(
            existingPrice => existingPrice.id === price.id
          )
        }),
        /**
         * Note that we don't expect to do anything with deleted prices today, but it's
         * useful to calculate them to drive functionality such as prompting for an effective date.
         */
        deleted: allExistingPrices.filter(
          existingPrice =>
            !allPrices.find(price => price.id === existingPrice.id)
        )
      }
    }
  }

export const createProductsSaveData: SaveDataComposition =
  () => data => prevData => {
    return {
      ...prevData,
      products: {
        new: Object.values(data.rawData.data.products).filter(product =>
          product.id.match(NEW_PRODUCT_PATTERN)
        )
      }
    }
  }

export const phasesAdapterOut =
  (existingData: CubeDomainInterface['queries']['rawData']['data']) =>
  (domainQueries: CubeDomainInterface['queries']): SaveBillingScheduleData => {
    const baseData = createBaseBillingSchedulePhasesApiData(domainQueries)

    return compose(
      createPhasedPricesSaveData(existingData)(domainQueries),
      createProductsSaveData(existingData)(domainQueries)
    )(baseData)
  }
