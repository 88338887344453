import { Box, Button, Flex, Grid, Icon } from '@chakra-ui/react'
import {
  CalendarDaysIcon,
  DocumentDuplicateIcon
} from '@heroicons/react/16/solid'
import {
  GlobeEuropeAfricaIcon,
  LinkIcon,
  ArrowDownTrayIcon,
  CheckIcon
} from '@heroicons/react/20/solid'
import { Tooltip } from '@sequencehq/core-components'
import {
  BorderRadius8,
  GreyGrey20,
  GreyGrey50,
  GreyGrey70,
  IndigoIndigo10,
  IndigoIndigo50,
  Lato13Bold
} from '@sequencehq/design-tokens'
import {
  Status,
  usePublishedStatus
} from 'Cube/view/layouts/quote/QuoteEditorSidebar/usePublishedStatus'

const styles = {
  button: {
    backgroundColor: GreyGrey20,
    height: '32px',
    alignItems: 'center',
    borderRadius: BorderRadius8,
    justifyContent: 'center',
    ...Lato13Bold
  },
  label: {
    ...Lato13Bold,
    color: GreyGrey70
  },
  icon: {
    height: '16px',
    width: '16px',
    color: { GreyGrey70 }
  }
}
const STATUS_TO_LABEL: Record<Status, string> = {
  [Status.Accepted]: 'Signed',
  [Status.Executed]: 'Executed',
  [Status.Published]: 'Published',
  [Status.NotPublished]: 'Not published',
  [Status.Template]: 'Template'
}

const StatusBadge = ({ status }: { status: Status }) => {
  if (status === Status.Template) {
    return (
      <Flex
        {...styles.button}
        color={IndigoIndigo50}
        backgroundColor={IndigoIndigo10}
        width="100%"
        gap="4px"
        justifyContent="center"
        userSelect="none"
      >
        <DocumentDuplicateIcon
          width="16px"
          height="16px"
          color={IndigoIndigo50}
        />
        Template
      </Flex>
    )
  }

  return (
    <Flex
      {...styles.button}
      color={status === Status.NotPublished ? GreyGrey50 : GreyGrey70}
      width="100%"
      gap="4px"
      justifyContent="center"
      userSelect="none"
    >
      {status !== Status.NotPublished && (
        <Box
          width="8px"
          height="8px"
          borderRadius="100%"
          backgroundColor="#3BB273"
        />
      )}
      {STATUS_TO_LABEL[status]}
    </Flex>
  )
}

export const PublishedStatus = () => {
  const hook = usePublishedStatus()

  return (
    <Grid
      columnGap="8px"
      alignItems="center"
      templateColumns="1fr"
      gridAutoFlow="column"
      marginBottom="16px"
    >
      <StatusBadge status={hook.status} />
      {hook.features.copyLinkAction.visible && (
        <Tooltip
          label={hook.features.copyLinkAction.tooltip}
          placement="bottom"
        >
          <Flex
            as={Button}
            {...styles.button}
            width="32px"
            onClick={hook.features.copyLinkAction.onClick}
            onBlur={hook.features.copyLinkAction.onBlur}
          >
            <Icon
              as={hook.features.copyLinkAction.isCopied ? CheckIcon : LinkIcon}
              {...styles.icon}
            />
          </Flex>
        </Tooltip>
      )}
      {hook.features.openCustomerPortal.visible && (
        <Tooltip
          label={hook.features.openCustomerPortal.tooltip}
          placement="bottom"
        >
          <Flex
            as={Button}
            {...styles.button}
            width="32px"
            onClick={hook.features.openCustomerPortal.onClick}
          >
            <Icon as={GlobeEuropeAfricaIcon} {...styles.icon} />
          </Flex>
        </Tooltip>
      )}
      {hook.features.download.visible && (
        <Tooltip label={hook.features.download.tooltip} placement="bottom">
          <Flex
            as={Button}
            {...styles.button}
            width="32px"
            onClick={hook.features.download.onClick}
            isDisabled={hook.features.download.disabled}
          >
            <Icon as={ArrowDownTrayIcon} {...styles.icon} />
          </Flex>
        </Tooltip>
      )}
      {hook.features.openBillingSchedule.visible && (
        <Tooltip
          label={hook.features.openBillingSchedule.tooltip}
          placement="bottom"
        >
          <Flex
            as={Button}
            {...styles.button}
            width="32px"
            onClick={hook.features.openBillingSchedule.onClick}
          >
            <Icon as={CalendarDaysIcon} {...styles.icon} />
          </Flex>
        </Tooltip>
      )}
    </Grid>
  )
}
