import { FC, useCallback, useEffect, useReducer, useRef } from 'react'
import { AttachmentsEmptyState } from './AttachmentsEmptyState'
import {
  ATTACHMENT_ERROR,
  attachmentReducer,
  isServerAsset,
  MAX_FILE_COUNT,
  noAttachments
} from './attachmentReducer'
import {
  GreyGrey80,
  Lato12Regular,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { Box, Flex, Text } from '@chakra-ui/react'
import { ServerAsset } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { isEqual } from 'lodash'
import { AttachmentList } from './AttachmentList'
import { match, P } from 'ts-pattern'
import { AttachmentPreview } from './AttachmentPreview'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useDropzone } from 'react-dropzone'
import { MAX_FILE_SIZE } from 'Assets/constants.ts'

type Props = {
  initialAttachments?: ServerAsset[]
  onChange: (assets: ServerAsset[]) => void // called each time an upload completes,
  disableModifyAttachments?: false | { reason: string }
  showContractExtraction?: boolean
}

export const Attachments: FC<Props> = ({
  initialAttachments = null,
  onChange,
  disableModifyAttachments = false,
  showContractExtraction = false
}) => {
  const [state, dispatch] = useReducer(attachmentReducer, {
    error: null,
    attachments: initialAttachments,
    attachmentToPreview: null
  })

  const flags = useFlags()

  useEffect(() => {
    if (!flags.useAttachmentPreview && state.attachmentToPreview) {
      window.open(state.attachmentToPreview.url, '_blank')
      dispatch({ type: 'PREVIEW_ATTACHMENT', attachment: null })
    }
  }, [state.attachmentToPreview, flags.useAttachmentPreview])

  const fileInputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    const serverAssets = state.attachments?.filter(isServerAsset) ?? []
    if (!isEqual(serverAssets, initialAttachments)) {
      onChange(serverAssets)
    }
  }, [state.attachments, initialAttachments, onChange])

  const triggerFileInput = () => {
    fileInputRef.current?.click()
  }

  const handleFilesPicked = () => {
    const files = fileInputRef.current?.files
    if (!files) {
      return
    }
    dispatch({ type: 'USER_PICKED_FILES', files: Array.from(files) })
    fileInputRef.current.value = ''
  }

  const onDrop = useCallback((acceptedFiles: File[]) => {
    dispatch({ type: 'USER_PICKED_FILES', files: Array.from(acceptedFiles) })
  }, [])

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
    multiple: true,
    accept: {
      'application/pdf': ['.pdf']
    },
    maxSize: MAX_FILE_SIZE,
    maxFiles: MAX_FILE_COUNT,
    disabled: Boolean(state.attachmentToPreview)
  })

  const containerWidth = state.attachmentToPreview ? '440px' : '320px'

  return (
    <Box
      width="fit-content"
      height="100%"
      overflow="auto"
      data-testid="attachments.inspector.content"
      {...getRootProps()}
    >
      <Box width={containerWidth} height="100%">
        {isDragActive ? (
          <Flex
            height="100%"
            width="100%"
            alignItems="center"
            justifyContent="center"
          >
            <Text {...Lato13Regular}>Upload attachments</Text>
          </Flex>
        ) : (
          match(state)
            .when(noAttachments, () => (
              <Box width="320px" height="100%">
                <AttachmentsEmptyState
                  onClickAddAttachment={triggerFileInput}
                  disableModifyAttachments={disableModifyAttachments}
                />
              </Box>
            ))
            .with(
              { attachmentToPreview: P.not(P.nullish) },
              () => !!flags.useAttachmentPreview,
              () => (
                <Box height="100%" pt="16px" width="440px">
                  <AttachmentPreview
                    state={state}
                    dispatch={dispatch}
                    disableControls={Boolean(disableModifyAttachments)}
                    showContractExtraction={showContractExtraction}
                  />
                </Box>
              )
            )
            .otherwise(() => (
              <Box height="100%" p="16px" width="320px">
                <AttachmentList
                  state={state}
                  dispatch={dispatch}
                  onClickAddAttachment={triggerFileInput}
                  disableModifyAttachments={disableModifyAttachments}
                />
              </Box>
            ))
        )}
        {state.error && (
          <Text align="center" color={GreyGrey80} {...Lato12Regular}>
            {toUserFriendlyError(state.error)}
          </Text>
        )}
        <input
          type="file"
          style={{ display: 'none' }}
          ref={fileInputRef}
          accept="application/pdf"
          multiple
          onChange={handleFilesPicked}
        />
        <input {...getInputProps()} />
      </Box>
    </Box>
  )
}

function toUserFriendlyError(error: ATTACHMENT_ERROR) {
  switch (error) {
    case 'MAX_FILE_COUNT_EXCEEDED':
      return `You can only add up to ${MAX_FILE_COUNT} files`
    default:
      return 'An error occurred'
  }
}
