import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useCsvExport } from 'lib/hooks/useCsvExport.tsx'

type UseCustomersCsvExport = () => {
  download: {
    available: boolean
    disabled: boolean
    onClick: (queryParams: URLSearchParams) => void
  }
}

export const useCustomersCsvExport: UseCustomersCsvExport = () => {
  return useCsvExport({
    getExport: queryParams =>
      dashboard20240730Client.getCustomersCsvDownload({
        legalName: queryParams.get('legalName') ?? undefined,
        email: queryParams.get('email') ?? undefined,
        alias: queryParams.get('alias') ?? undefined,
        includeArchived: queryParams.get('includeArchived') === 'true'
      }),
    fileName: 'customers-export.csv'
  })
}
