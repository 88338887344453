import { getIsAQuoteTemplate } from 'Cube/domain/postActionStages/utils/getIsQuoteATemplate'
import {
  CubeReducerState,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getExternalIntegrationsAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  return {
    available: {
      visible: !getIsAQuoteTemplate(prevState),
      enabled: !getIsAQuoteTemplate(prevState)
    },
    reasons: [
      {
        reasonType: DisabledReasonType.InvalidStatus,
        metadata: { status: prevState.data.common.status }
      }
    ]
  }
}
