import { useCallback, useEffect, useState } from 'react'

import * as Sentry from '@sentry/react'

import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'

type Alias = {
  value: string
  customerId: string
  id: string
  sequenceAccountId: string
  createdAt: string
}
type UseCustomerNamesByAliasReturn = {
  isLoading: boolean
  customersWithAliases?: CustomerWithAliases[]
  aliases?: Array<Alias>
}

export type CustomerWithAliases = {
  id: string
  customerName: string
  aliases: string[]
}

export const useCustomerNamesByAlias = (): UseCustomerNamesByAliasReturn => {
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [customersWithAliases, setCustomersWithAliases] = useState<
    CustomerWithAliases[] | undefined
  >()
  const [aliases, setAliases] = useState<Array<Alias> | undefined>()

  const getAllAliases = useCallback(
    async ({
      after,
      acc = [],
      iterations = 0
    }: {
      after?: string
      acc?: Array<Alias>
      iterations?: number
    }): Promise<Array<Alias>> => {
      /**
       * If it goes beyond 100 iterations, the load time
       * will allow for multiple cups of tea to be brewed.
       * I've bumped the limit as a short term, but bad, fix.
       * A medium term, but bad, fix would be to make the customer
       * search, and alias resolution, lazy and a search.
       *
       * The only real fix is to resolve aliases as part of an aggregate
       * on the customer object in the API.
       */
      if (iterations === 100) {
        return acc
      }

      const aliasesPage = await dashboard20240730Client.getCustomerAliases({
        after
      })
      const aliasesPageValue = aliasesPage.data

      if (aliasesPageValue?.pagination.after) {
        return getAllAliases({
          after: aliasesPageValue.pagination.after,
          acc: [...acc, ...aliasesPageValue.items],
          iterations: iterations + 1
        })
      } else {
        return [...acc, ...(aliasesPageValue?.items ?? [])]
      }
    },
    []
  )

  const getAllCustomers = useCallback(
    async ({
      after,
      acc = [],
      iterations = 0
    }: {
      after?: string
      acc?: Array<{
        id: string
        legalName: string
      }>
      iterations?: number
    }): Promise<
      Array<{
        id: string
        legalName: string
      }>
    > => {
      if (iterations === 20) {
        return acc
      }

      const customersPage = await dashboard20240730Client.getCustomers({
        after
      })
      const customersPageValue = customersPage.data

      if (customersPageValue && customersPageValue?.pagination.after) {
        // has more pages
        return getAllCustomers({
          after: customersPageValue.pagination.after,
          acc: [...acc, ...(customersPageValue?.items ?? [])],
          iterations: iterations + 1
        })
      } else {
        // last page
        return [...acc, ...(customersPageValue?.items ?? [])]
      }
    },
    []
  )

  useEffect(() => {
    const fetchData = async () => {
      const fetchedAliases = await getAllAliases({})
      const allCustomers = await getAllCustomers({})

      const allCustomersWithAliases: CustomerWithAliases[] =
        allCustomers.reduce((acc: CustomerWithAliases[], customer) => {
          const aliasesForCustomer = fetchedAliases.filter(
            alias => alias.customerId === customer.id
          )

          return [
            ...acc,
            {
              customerName: customer.legalName,
              id: customer.id,
              aliases: [
                customer.id,
                ...aliasesForCustomer.map(alias => alias.value)
              ]
            }
          ]
        }, [])

      setAliases(fetchedAliases)
      setCustomersWithAliases(allCustomersWithAliases)
      setIsLoading(false)
    }

    try {
      void fetchData()
    } catch (e) {
      Sentry.captureException(e)
    }
  }, [getAllAliases, getAllCustomers])

  return {
    isLoading,
    customersWithAliases,
    aliases
  }
}
