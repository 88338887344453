import { Box, Checkbox, Flex, FormControl, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { WidgetSection } from 'modules/Cube/view/common/atomic/WidgetSection'
import {
  TextInputField,
  InputSelectControlField,
  Popover
} from '@sequencehq/core-components'
import { GreyGrey10, Lato14Regular } from '@sequencehq/design-tokens'
import InformationCircleIcon from '@heroicons/react/24/outline/InformationCircleIcon'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { useAdditionalInformationWidget } from 'modules/Cube/view/layouts/billingSchedule/widgets/AdditionalInformation/useAdditionalInformationWidget'

export const AdditionalInformationWidget: FC = () => {
  const { fieldsConfig } = useAdditionalInformationWidget()
  const flags = useFlags()
  const enableAutoIssueInvoices = flags?.enableAutoIssueInvoices

  return (
    <WidgetSection title="Additional information" width="100%">
      <Box padding="0 16px 20px">
        <Box
          padding="12px 8px 12px"
          backgroundColor={GreyGrey10}
          borderRadius="6px"
          as={Flex}
          flexDirection="column"
        >
          {!fieldsConfig.taxRate.hidden && (
            <FormControl width="235px" as={Flex} flexDirection="column" gap={2}>
              <InputSelectControlField
                data-testid="additional.taxRate"
                label="Tax"
                placeholder="Select tax rate"
                options={fieldsConfig.taxRate.options}
                onChange={fieldsConfig.taxRate.onChange}
                disabled={fieldsConfig.taxRate.disabled}
                initialValue={fieldsConfig.taxRate.value}
                validationErrors={fieldsConfig.taxRate.validationErrors}
                matchWidth
              />
            </FormControl>
          )}

          <TextInputField
            label="Purchase order number (optional)"
            placeholder="Optional PO number"
            {...fieldsConfig.purchaseOrderNumber}
            onChange={event =>
              fieldsConfig.purchaseOrderNumber.onChange(event?.target?.value)
            }
            width="235px"
          />
          {!fieldsConfig.reference.hidden && (
            <TextInputField
              label="Invoice reference number (optional)"
              placeholder="Optional reference number"
              {...fieldsConfig.reference}
              onChange={event =>
                fieldsConfig.reference.onChange(event?.target?.value)
              }
              width="235px"
            />
          )}
          {!fieldsConfig.label.hidden && (
            <TextInputField
              label="Billing schedule label (optional)"
              placeholder="Optional custom label"
              {...fieldsConfig.label}
              onChange={event =>
                fieldsConfig.label.onChange(event?.target?.value)
              }
              width="235px"
            />
          )}
          {enableAutoIssueInvoices && (
            <Flex gap="4px" alignItems="center">
              <Checkbox
                isChecked={fieldsConfig.autoIssueInvoices.value}
                onChange={event => {
                  fieldsConfig.autoIssueInvoices.onChange(event.target.checked)
                }}
                isInvalid={Boolean(
                  fieldsConfig.autoIssueInvoices.validationErrors.length
                )}
                isDisabled={fieldsConfig.autoIssueInvoices.disabled}
              >
                <Text {...Lato14Regular} paddingRight="8px">
                  Auto-send invoices for each billing period
                </Text>
              </Checkbox>
              <Popover
                header="Auto-send invoices"
                content=" When enabled, Sequence will finalize the invoice and issue
                      it to your customer automatically (including an email
                      notification with the invoice attached as PDF)."
              >
                <InformationCircleIcon height="20px" />
              </Popover>
            </Flex>
          )}
        </Box>
      </Box>
    </WidgetSection>
  )
}
