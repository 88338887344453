import {
  SequenceMenuDivider,
  SequenceMenuItem,
  SequenceMenuItemProps,
  SequenceMenuItemType
} from '@sequencehq/core-components'
import { ActiveFilter } from '@sequencehq/tables'
import Invoices from 'components/Invoices'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { Dispatch, FC, SetStateAction, useCallback, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DeleteSavedViewDialog } from 'SavedViews/components/DeleteSavedViewDialog'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ErrorMessage } from 'components/ErrorPages/ErrorMessage'
import { Center, Spinner } from '@chakra-ui/react'
import { savedViewsPorts } from 'SavedViews/savedViews.ports'
import { SAVED_VIEWS_STALE_TIME } from 'SavedViews/savedViews.constants'

export const SavedViewPage: FC = () => {
  const { id } = useParams<{ id: string }>()
  const navigate = useNavigate()
  const flags = useFlags()
  const { displayNotification } = useNotifications()

  const {
    data: savedViews,
    isLoading,
    isFetching
  } = useQuery(dashboard20240730Client.getSavedViews, undefined, {
    select: data => {
      if (!data) {
        return null
      }

      return savedViewsPorts.savedViews.fromServer(data)
    },
    staleTime: SAVED_VIEWS_STALE_TIME
  })
  const putSavedViewMutator = useMutation(dashboard20240730Client.putSavedView)

  const savedView = savedViews?.find(savedView => savedView.id === id)

  const shouldRedirect = !flags.useSavedViews

  useEffect(() => {
    if (shouldRedirect) {
      navigate('/home')
    }
  }, [shouldRedirect, navigate])

  const kebabMenuItems = (
    setSavingFilters: Dispatch<SetStateAction<false | ActiveFilter[]>>
  ): SequenceMenuItemType[] | undefined => {
    if (!savedView) {
      return undefined
    }

    return [
      (props: Partial<SequenceMenuItemProps>) => (
        <SequenceMenuItem
          key="edit-name"
          uuid="edit-name"
          label="Edit name"
          onClick={() => {
            setSavingFilters(savedView.activeFilters)
            props.setIsOpen?.(false)
          }}
        />
      ),
      <SequenceMenuDivider key="divider" />,
      (props: Partial<SequenceMenuItemProps>) => (
        <DeleteSavedViewDialog
          key="delete-saved-view"
          savedViewId={savedView.id}
          onCancel={() => props.setIsOpen?.(false)}
        />
      )
    ]
  }

  const onUpdateSavedFilters = useCallback(
    async (updatedActiveFilters: ActiveFilter[]) => {
      if (!savedView) {
        return
      }

      const resPutSavedView = await putSavedViewMutator.mutateAsync({
        id: savedView.id,
        body: savedViewsPorts.savedView.toServer({
          name: savedView.name,
          entityType: savedView.entityType,
          activeFilters: updatedActiveFilters
        })
      })

      if (!resPutSavedView) {
        displayNotification('Failed to update saved view', { type: 'error' })
        return
      }

      displayNotification('Saved view updated', { type: 'success' })
    },
    [savedView, putSavedViewMutator.mutateAsync, displayNotification]
  )

  if (isLoading || (!savedView && isFetching)) {
    return (
      <Center height="100%" width="100%">
        <Spinner />
      </Center>
    )
  }

  if (!savedView) {
    return <ErrorMessage errorType="notFound" />
  }

  return (
    <Invoices
      key={id}
      showSegmentedFilters={false}
      title={savedView.name}
      forcedActiveFilters={savedView.activeFilters}
      kebabMenuItems={kebabMenuItems}
      onUpdateSavedFilters={onUpdateSavedFilters}
    />
  )
}
