import { Box, Flex, Grid, GridItem, Link } from '@chakra-ui/react'
import { FC, useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { EnvironmentBanner } from '@sequencehq/core-components'
import { useDispatch } from 'features/store'
import { openOverlay } from 'features/overlay'
import { NavigationV3 } from 'components/NavigationV3/NavigationV3'
import { GreyGrey30 } from '@sequencehq/design-tokens'
import { navigationWidth } from 'components/NavigationV3/components/Container/Container'
import { getSentryConfig } from 'features/sentry/getSentryConfig'
import { NavigationV3Provider } from 'components/NavigationV3/NavigationV3Provider'

const { environment } = getSentryConfig()

const Layout: FC = () => {
  const showEmailCollectionModal =
    window.localStorage.getItem('collectEmail') !== 'false'
  const dispatch = useDispatch()

  useEffect(() => {
    if (environment === 'demo' && showEmailCollectionModal) {
      dispatch(openOverlay({ content: 'collectEmailModal' }))
    }
  }, [])

  return (
    <>
      <Box width="100%" position="fixed" zIndex={3} pointerEvents="none">
        {environment === 'demo' ? (
          <EnvironmentBanner environment={environment}>
            Demo -{' '}
            <Link
              href="https://access.sequencehq.com"
              isExternal
              pointerEvents="all"
            >
              Request a Sandbox
            </Link>
          </EnvironmentBanner>
        ) : (
          <EnvironmentBanner environment={environment} />
        )}
      </Box>
      <NavigationV3Provider>
        <Grid
          gridTemplateColumns="auto 1fr"
          gridTemplateAreas={'"navbar content"'}
          height="100vh"
          width="100vw"
        >
          <GridItem gridArea="navbar">
            <NavigationV3 />
          </GridItem>
          <GridItem
            gridArea="content"
            boxSizing="border-box"
            borderLeft={`${GreyGrey30} 1px solid`}
            boxShadow="0px 1px 3px 0px rgba(0, 0, 0, 0.08), 0px 0px 5px 0px rgba(0, 0, 0, 0.03)"
            zIndex="0"
            width="100%"
            height="100%"
            overflow="auto"
          >
            <Outlet />
          </GridItem>
        </Grid>
      </NavigationV3Provider>
    </>
  )
}

export default Layout
