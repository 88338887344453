import { FormFields, useForm } from '@sequencehq/utils'
import {
  greaterThan,
  greaterThanEqualTo,
  required
} from '@sequencehq/validation'
import { usePricingEditorDomainContext } from 'common/drawers/PricingEditor/communication'
import {
  BillingType,
  PricingEditorReducerState,
  PricingModel,
  RecursivePartial,
  SeatBasedProrationStrategy
} from 'common/drawers/PricingEditor/domain'
import { defaultAvailableFrequenciesOptions } from 'common/drawers/PricingEditor/domain/pricingEditor.constants'
import { useMemo } from 'react'
import { useCommonFieldConfig } from '../common/useCommonFieldConfig'
import deepmerge from 'deepmerge'
import { useNavigate } from 'react-router-dom'
import { Currency } from '@sequencehq/api/dist/utils/commonEnums'
import { getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency } from './seatBasedPrice.utils'
import { tiersValidator } from 'common/drawers/PricingEditor/utils/validators/tiers.validators'
import { commonPriceDetails } from '../common/ReadOnlyDetails/readOnlyDetails.utils'
import { useSeatMetrics } from 'Seats/SeatMetric/useSeatMetrics'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { seatProrationLabels } from 'common/drawers/PricingEditor/view/editors/SeatBasedPrice/SeatProrationDropdown/SeatProrationDropdown.tsx'
import { omit } from 'lodash/fp'

export type CommonFields = Omit<
  PricingEditorReducerState['data']['pricingEditorData']['common'],
  'pricingModel'
>
export type SeatBasedPriceFields =
  PricingEditorReducerState['data']['pricingEditorData']['SEAT_BASED_GRADUATED']

type SeatBasedPriceFormFieldConfig = Omit<
  FormFields<CommonFields>,
  'pricingModel'
> &
  Omit<
    FormFields<SeatBasedPriceFields>,
    'seatTypeId' | 'overagesBillingFrequency' | 'prorationStrategy'
  > & {
    currency: FormFields<CommonFields>['currency'] & {
      hidden: boolean
    }
    seatMetricId: FormFields<SeatBasedPriceFields>['seatMetricId'] & {
      onAddNew: () => void
    }
    overagesBillingFrequency: Omit<
      FormFields<SeatBasedPriceFields>['overagesBillingFrequency'],
      'options'
    > & {
      options: { value: string; label: string; description: string }[]
      selected: { value: string; label: string; description: string }
    }
    prorationStrategy: Omit<
      FormFields<SeatBasedPriceFields>['prorationStrategy'],
      'options'
    > & {
      options: { value: string; label: string; description: string }[]
      selected: { value: string; label: string; description: string }
    }
    pricePerSeat: FormFields<SeatBasedPriceFields>['pricePerSeat'] & {
      hidden: boolean
    }
    prorateFlatFees: FormFields<SeatBasedPriceFields>['prorateFlatFees'] & {
      hidden: boolean
    }
  }

type UseSeatBasedPriceForm = () => {
  fieldsConfig: SeatBasedPriceFormFieldConfig
  conditions: {
    isInAdvanceSelected: boolean
    isContractedMinimumSet: boolean
    isBillingFrequencyLessThanMonthly: boolean
    isProrateStrategyIncrements: boolean
  }
  currency: Currency
  priceDetails: { label: string; value: string }[]
}

const availableBillingTypeOptions: { value: BillingType; label: string }[] = [
  { label: 'In arrears', value: 'IN_ARREARS' },
  { label: 'In advance', value: 'IN_ADVANCE' }
]

export const sanitizeFormData = (
  pricingModel: PricingModel,
  formData: SeatBasedPriceFields & CommonFields
): RecursivePartial<PricingEditorReducerState['data']['pricingEditorData']> => {
  const { name, currency, ...rest } = formData

  return {
    common: {
      name,
      currency: formData.currency
    },
    [pricingModel]: {
      ...rest,
      /**
       * We want to ensure the overages billing frequency remains valid.
       * Therefore, if it does not match the billing frequency, set
       * it to monthly (the default value).
       *
       * This means the value updates whenever billing frequency changes,
       * to retain a valid value.
       */
      overagesBillingFrequency:
        rest.overagesBillingFrequency === rest.billingFrequency
          ? rest.overagesBillingFrequency
          : 'MONTHLY'
    }
  }
}

export const prorationStrategyOptions: {
  value: SeatBasedProrationStrategy
  label: string
  description: string
}[] = [
  {
    value: 'USE_MAXIMUM',
    label: 'Charge full amount (no proration)',
    description:
      "'Charge the full monthly price for any seats added in the current billing period.'"
  },
  {
    value: 'PRORATE_INCREMENTS',
    label: 'Prorate added seats only (recommended)',
    description:
      'Charge a prorated fee for seats added in the billing period. Removed seats are charged the full fee.'
  },
  {
    value: 'PRORATE_ALL_CHANGES',
    label: 'Prorate added and removed seats',
    description:
      'Charge a prorated fee for any added and removed seats in the billing period.'
  }
]

export const useSeatBasedPriceForm: UseSeatBasedPriceForm = () => {
  const pricingEditorContext = usePricingEditorDomainContext()
  const seatMetricsLoader = useSeatMetrics()
  const navigate = useNavigate()
  const flags = useFlags()

  const seatTypeOptions = useMemo(() => {
    if (!seatMetricsLoader.metrics) {
      return []
    }

    return (
      seatMetricsLoader.metrics?.map(seatMetric => ({
        value: seatMetric.id,
        label: seatMetric.label
      })) ?? []
    )
  }, [seatMetricsLoader.metrics])

  const pricingModel = useMemo(
    () =>
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .pricingModel,
    [
      pricingEditorContext.queries.rawData.data.pricingEditorData.common
        .pricingModel
    ]
  )

  const commonFields = useCommonFieldConfig<
    SeatBasedPriceFields & CommonFields
  >()

  const { fields, queries } = useForm({
    value: {
      ...omit('pricingModel')(
        pricingEditorContext.queries.rawData.data.pricingEditorData.common
      ),
      ...(pricingEditorContext.queries.rawData.data.pricingEditorData[
        pricingModel
      ] as SeatBasedPriceFields & CommonFields)
    },
    fieldConfiguration: [
      ...commonFields.fields,
      {
        property: 'billingFrequency',
        validation: [required],
        options: defaultAvailableFrequenciesOptions
      },
      {
        property: 'billingType',
        validation: [required],
        options: availableBillingTypeOptions
      },
      {
        property: 'pricePerSeat',
        validation:
          pricingModel === 'SEAT_BASED_LINEAR'
            ? [required, greaterThan(0, "Price per seat can't be zero")]
            : []
      },
      {
        property: 'tiers',
        validation:
          pricingModel === 'SEAT_BASED_GRADUATED'
            ? [required, tiersValidator]
            : []
      },
      {
        property: 'contractedMinimumSeats',
        validation: [
          required,
          greaterThanEqualTo(0, 'Please enter at least 0 seats')
        ]
      },
      {
        property: 'prorationStrategy',
        validation: [required]
      },
      {
        property: 'overagesBillingFrequency',
        validation: [required]
      },
      {
        property: 'seatMetricId',
        validation: [required],
        options: seatTypeOptions
      },
      {
        property: 'prorateFlatFees'
      }
    ],
    showValidationErrors:
      pricingEditorContext.queries.rawData.editor.showValidationErrors,
    disabled:
      !pricingEditorContext.queries.availableFeatures.common.form.available
        .enabled,
    onValidationStateChange: isValid => {
      pricingEditorContext.mutators.common.updateEditor({
        valid: isValid
      })
    },
    onChange: newData => {
      pricingEditorContext.mutators.common.updatePricingEditorData(
        sanitizeFormData(pricingModel, newData)
      )
    }
  })

  const overagesFrequencies = useMemo(() => {
    return getAvailableSeatOveragesFrequenciesFromSelectedPriceFrequency(
      queries.formData.billingFrequency
    )
  }, [queries.formData.billingFrequency])

  const enhancedFields = useMemo(() => {
    return deepmerge(
      fields,
      {
        currency: {
          ...fields.currency,
          options: fields.currency.options.map(option => ({
            ...option,
            label: option.label.split(' ')[0]
          })),
          hidden: Boolean(
            pricingEditorContext.queries.rawData.configuration.currency
          )
        },
        seatMetricId: {
          onAddNew: () => navigate('./seats/new')
        },
        prorationStrategy: {
          options: prorationStrategyOptions,
          selected: prorationStrategyOptions.find(
            opt => queries.formData.prorationStrategy === opt.value
          )
        },
        overagesBillingFrequency: {
          options: overagesFrequencies,
          selected: overagesFrequencies.find(
            opt => queries.formData.overagesBillingFrequency === opt.value
          )
        },
        pricePerSeat: {
          hidden: pricingModel === 'SEAT_BASED_GRADUATED'
        },
        prorateFlatFees: {
          hidden:
            pricingModel !== 'SEAT_BASED_GRADUATED' ||
            (queries.formData.tiers ?? [])
              .map(tier => tier.flatFee)
              .filter(flatFee => +flatFee > 0).length === 0
        }
      },
      {
        arrayMerge: (_, source: unknown[]) => source
      }
    ) as SeatBasedPriceFormFieldConfig
  }, [
    fields,
    navigate,
    pricingModel,
    overagesFrequencies,
    queries.formData.overagesBillingFrequency,
    queries.formData.prorationStrategy,
    pricingEditorContext.queries.rawData.configuration.currency
  ])

  const isInAdvanceSelected = useMemo(
    () => queries.formData.billingType == 'IN_ADVANCE',
    [queries.formData.billingType]
  )
  const isContractedMinimumSet = useMemo(
    () => parseInt(queries.formData.contractedMinimumSeats) > 0,
    [queries.formData.contractedMinimumSeats]
  )
  const isBillingFrequencyLessThanMonthly = useMemo(
    () =>
      isInAdvanceSelected &&
      ['QUARTERLY', 'HALF_YEARLY', 'YEARLY'].includes(
        queries.formData.billingFrequency
      ),
    [isInAdvanceSelected, queries.formData.billingFrequency]
  )
  const isProrateStrategyIncrements = useMemo(
    () => queries.formData.prorationStrategy === 'PRORATE_INCREMENTS',
    [queries.formData.prorationStrategy]
  )

  const prorationDetail = flags.useSeatsProrateAllChanges
    ? {
        label: 'Proration',
        value: seatProrationLabels[queries.formData.prorationStrategy]
      }
    : {
        label: 'Prorate overage fees',
        value:
          queries.formData.prorationStrategy === 'PRORATE_INCREMENTS'
            ? 'Yes'
            : 'No'
      }

  const priceDetails = useMemo(
    () => [
      ...commonPriceDetails({
        taxCategoryName:
          pricingEditorContext.queries.rawData.data.product.taxCategoryName,
        pricingModel,
        billingFrequency: queries.formData.billingFrequency,
        billingType: queries.formData.billingType
      }),
      {
        label: 'Seat metric',
        value:
          fields.seatMetricId.options.find(
            opt => opt.value === queries.formData.seatMetricId
          )?.label ?? 'Not selected'
      },
      prorationDetail
    ],
    [
      fields.seatMetricId.options,
      queries.formData.billingFrequency,
      queries.formData.billingType,
      queries.formData.seatMetricId,
      prorationDetail,
      pricingModel,
      pricingEditorContext.queries.rawData.data.product.taxCategoryName
    ]
  )

  return {
    fieldsConfig: enhancedFields,
    currency: queries.formData.currency,
    conditions: {
      isInAdvanceSelected,
      isContractedMinimumSet,
      isBillingFrequencyLessThanMonthly,
      isProrateStrategyIncrements
    },
    priceDetails
  }
}
