import { SequenceMenuItem } from '@sequencehq/core-components'
import { useNavigate } from 'react-router-dom'

export const ViewQuoteTemplateMenuItem = (props: {
  quoteTemplateId: string
}) => {
  const navigate = useNavigate()

  return (
    <SequenceMenuItem
      uuid="view-quote-template"
      label="View template"
      onClick={() => navigate(`/quote-templates/${props.quoteTemplateId}`)}
    />
  )
}
