import { Text, Center } from '@chakra-ui/react'
import { GreyGrey80, Lato12Bold } from '@sequencehq/design-tokens'
import { useMemo } from 'react'

type Props = {
  name: string
  size?: 'md' | 'lg'
}

const avatarVariants = {
  md: {
    size: '20px',
    borderRadius: '6px',
    fontSize: '11px'
  },
  lg: {
    size: '48px',
    borderRadius: '9999px',
    fontSize: '26px'
  }
}

export const Avatar = ({ name, size = 'md' }: Props) => {
  const initials: string = useMemo(() => {
    const trimmedName = name.trim()

    if (!trimmedName) {
      return ''
    }

    const tokens = trimmedName.split(' ')

    if (tokens.length === 1) {
      return tokens[0].substring(0, 2)
    } else {
      return tokens
        .map(token => token[0])
        .slice(0, 2)
        .join('')
    }
  }, [name])

  return (
    <Center
      background={GreyGrey80}
      height={avatarVariants[size].size}
      width={avatarVariants[size].size}
      borderRadius={avatarVariants[size].borderRadius}
      flexShrink="0"
    >
      <Text
        {...Lato12Bold}
        fontSize={avatarVariants[size].fontSize}
        color="#ffffff"
      >
        {initials}
      </Text>
    </Center>
  )
}
