import { getIsAQuoteTemplate } from 'Cube/domain/postActionStages/utils/getIsQuoteATemplate'
import {
  CubeReducerState,
  CubeStatus,
  DisabledReasonType,
  VisibleEnabledFeatureAvailable
} from 'modules/Cube/domain/cube.domain.types'

export const getPublishQuoteAvailable = (
  prevState: CubeReducerState
): VisibleEnabledFeatureAvailable => {
  if (getIsAQuoteTemplate(prevState)) {
    return {
      available: {
        visible: false,
        enabled: false
      },
      reasons: [
        {
          reasonType: DisabledReasonType.InvalidStatus,
          metadata: { status: prevState.data.common.status }
        }
      ]
    }
  }

  return {
    available: {
      visible:
        !prevState.data.common.isArchived &&
        [
          CubeStatus.QuoteDraft,
          CubeStatus.QuotePublished,
          CubeStatus.QuoteReadyToSign
        ].includes(prevState.data.common.status),
      enabled:
        !prevState.data.common.isArchived &&
        ([CubeStatus.QuoteDraft].includes(prevState.data.common.status) ||
          ([CubeStatus.QuotePublished, CubeStatus.QuoteReadyToSign].includes(
            prevState.data.common.status
          ) &&
            prevState.data.quote.isExpired))
    },
    reasons: []
  }
}
