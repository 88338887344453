import { useCallback, useMemo } from 'react'
import { useQuery } from '@sequencehq/api'
import {
  dashboard20240730Client,
  DashboardApi20240730
} from '@sequencehq/api/dist/clients/dashboard/v20240730'

export type Invoice = DashboardApi20240730.GetInvoice.Invoice

type UseLoadInvoice = (props: { invoiceId: Invoice['id'] }) => {
  loading: boolean
  error?: {
    status: number
    data: unknown
  }
  data?: {
    invoice: Invoice
  }
  reloadInvoice: () => void
}

export const useLoadInvoice: UseLoadInvoice = ({ invoiceId }) => {
  const fetchInvoice = useQuery(dashboard20240730Client.getInvoice, {
    id: invoiceId
  })
  const invoice = fetchInvoice.data

  const loadInvoice = useCallback(() => {
    fetchInvoice.refetch()
  }, [fetchInvoice])

  const error = useMemo(() => {
    if (fetchInvoice.error) {
      return {
        status: fetchInvoice.error.status,
        data: {}
      }
    }
  }, [fetchInvoice.error])

  return {
    loading: fetchInvoice.isLoading,
    error,
    data: invoice ? { invoice } : undefined,
    reloadInvoice: loadInvoice
  }
}
