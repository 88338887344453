import { DocumentIcon } from '@heroicons/react/24/solid'
import {
  Button,
  InspectorEmptyState,
  Tooltip
} from '@sequencehq/core-components'
import { MAX_FILE_SIZE_TEXT } from 'Assets/constants'
import { FC } from 'react'

export const AttachmentsEmptyState: FC<{
  onClickAddAttachment: () => void
  disableModifyAttachments?: false | { reason: string }
}> = ({ onClickAddAttachment, disableModifyAttachments = false }) => {
  return (
    <InspectorEmptyState
      IconComponent={DocumentIcon}
      title="No attachments"
      body="All attachments will be stored here and can be downloaded."
      button={
        <Tooltip
          isDisabled={!disableModifyAttachments}
          label={
            disableModifyAttachments ? disableModifyAttachments.reason : ''
          }
        >
          <Button
            variant="secondary"
            onClick={onClickAddAttachment}
            disabled={!!disableModifyAttachments}
          >
            Add attachment
          </Button>
        </Tooltip>
      }
      helperText={`(Max. ${MAX_FILE_SIZE_TEXT} per file)`}
    />
  )
}
