import { Currency } from '@sequencehq/api'
import { Dashboardv20240509Api } from '@sequencehq/api/dist/clients/dashboard/v20240509'
import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import { arrayToIdKeyedObject } from '@sequencehq/utils'
import { Duration } from '@sequencehq/utils/dist/dates'
import {
  CubeReducerState,
  Discount,
  ListPrice,
  Minimum,
  Phase,
  Price
} from 'Cube/domain/cube.domain.types'

export type ApiQuotePhase = DashboardApi20240730.GetQuote.Quote['phases'][0]
type ApiListPrice = Dashboardv20240509Api.GetListPrice.ListPrice
type ApiPrice = Dashboardv20240509Api.GetPrice.Price

export const quotePhasesToPhases = (
  quotePhases: Array<Omit<ApiQuotePhase, 'id'> & { id?: string }>
): CubeReducerState['data']['phases'] => {
  if (quotePhases.length === 0) {
    const phaseId = crypto.randomUUID()
    return {
      [phaseId]: {
        id: phaseId,
        name: '',
        priceIds: [],
        discountIds: [],
        minimumIds: [],
        duration: {
          years: 1,
          days: -1
        },
        /**
         * Quotes cannot control this value, so we always set it to the
         * default of 'continue from previous phase'.
         */
        recurrencePreference: 'CONTINUE_FROM_PREVIOUS_PHASE',
        phasePriceMetadata: []
      }
    }
  }

  const convertedArray: Phase[] = quotePhases.map(quotePhase => ({
    id: quotePhase.id ?? crypto.randomUUID(),
    priceIds: quotePhase.prices.map(({ id }) => id),
    discountIds: quotePhase.discounts.map(discount => discount.id),
    name: quotePhase.name ?? '',
    minimumIds: quotePhase.minimums.map(minimum => minimum.id),
    duration: quotePhase.duration
      ? {
          years: quotePhase.duration?.years,
          months: quotePhase.duration?.months,
          days: -1
        }
      : 'OPEN_ENDED',
    recurrencePreference: 'CONTINUE_FROM_PREVIOUS_PHASE',
    phasePriceMetadata: []
  }))

  return arrayToIdKeyedObject(convertedArray)
}

export const quotePhasesToDiscounts = (
  quotePhases: Array<Omit<ApiQuotePhase, 'id'> & { id?: string }>
): Record<string, Discount> => {
  return quotePhases.reduce((acc, quotePhase) => {
    const discounts: Discount[] = quotePhase.discounts.map(discount => ({
      id: discount.id,
      message: discount.message,
      discountCalculationType: discount.discountCalculationType,
      amount: parseFloat(discount.amount),
      priceIds: discount.restrictToPrices,
      applyToAllPrices: !discount.restrictToPrices.length,
      seatDiscountType: discount.seatDiscountType
    }))

    return {
      ...acc,
      ...arrayToIdKeyedObject(discounts)
    }
  }, {})
}

export const quotePhasesToMinimums = (
  quotePhases: Array<Omit<ApiQuotePhase, 'id'> & { id?: string }>
): Record<string, Minimum> => {
  return quotePhases.reduce((acc, quotePhase) => {
    const minimums: Minimum[] = quotePhase.minimums.map(minimum => ({
      id: minimum.id,
      value: minimum.amount,
      name: '',
      scope: {
        target: minimum.restrictToPrices.length === 0 ? 'allUsage' : 'specific',
        priceIds: minimum.restrictToPrices
      }
    }))

    return {
      ...acc,
      ...arrayToIdKeyedObject(minimums)
    }
  }, {})
}

type ContractDuration = {
  years: number
  months: number
}

export const calculateContractLength = (
  phaseDurations: (Duration | undefined)[]
): ContractDuration | undefined => {
  if (phaseDurations.some(duration => duration === undefined)) {
    return
  }

  const accumulatedDuration: ContractDuration = phaseDurations.reduce(
    (accDuration, phaseDuration) => {
      const accMonths = accDuration.months + (phaseDuration?.months ?? 0)
      const accYears = accDuration.years + (phaseDuration?.years ?? 0)

      return {
        months: accMonths,
        years: accYears
      }
    },
    { months: 0, years: 0 } as ContractDuration
  )

  return accumulatedDuration
}

export const pricesToDomainPrices = (
  prices: Array<ApiPrice>,
  quoteCurrency: Currency
): Price[] => {
  return prices.map(price => {
    const { customMetricParameters, integrationIds, ...rest } = price

    return {
      ...rest,
      /**
       * The error below is to be fixd by an API change on June 3rd.
       */
      structure:
        'includedSeats' in price.structure
          ? //@ts-expect-error The 20240509 endpoint currently returns the 20240101 format for price structure
            priceModelAdapter.out({
              ...price,
              externalIds: []
            })?.structure
          : price.structure,
      currency: quoteCurrency,
      integrationIds,
      customMetricParameters
    }
  })
}

export const transformListPrices = (listPrices: Array<ApiListPrice>) => {
  return listPrices.reduce<Record<string, Array<ListPrice>>>(
    (acc, listPrice) => {
      const productListPrices = acc[listPrice.productId] ?? []

      return {
        ...acc,
        [listPrice.productId]: [...productListPrices, listPrice]
      }
    },
    {}
  )
}
