import { Box, Flex, Grid, Spinner } from '@chakra-ui/react'
import { useMutation, useQuery } from '@sequencehq/api'
import { dashboard20240730Client } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  Button,
  Dialog,
  Modal,
  ModalContextProvider,
  useModalContext
} from '@sequencehq/core-components'
import {
  GreyGrey60,
  GreyGrey80,
  Lato13Regular
} from '@sequencehq/design-tokens'
import { quoteAdapters } from 'Cube/communication/external/quotes.api.v1/adapters/quote.adapters'
import { useCubeContext } from 'Cube/communication/internal/cube.domain.context'
import { useNotifications } from 'lib/hooks/useNotifications'
import { ReactNode, useCallback, useState } from 'react'

const useRevokeQuoteModal = (props: {
  quoteId: string
  onSuccess?: () => void
}) => {
  const modal = useModalContext()
  const cubeContext = useCubeContext()

  const [revoking, setRevoking] = useState(false)
  const { displayNotification } = useNotifications()
  const quoteRes = useQuery(
    dashboard20240730Client.getQuote,
    { id: props.quoteId },
    {
      select: data => {
        if (!data) {
          return null
        }
        return {
          quoteNumber: data.quoteNumber
        }
      }
    }
  )

  const putQuoteMutator = useMutation(dashboard20240730Client.putQuote)

  const publishQuoteMutator = useMutation(
    dashboard20240730Client.postQuotePublish
  )

  const onError = useCallback(() => {
    displayNotification('Could not remove access to quote', {
      type: 'error'
    })
    setRevoking(false)
  }, [displayNotification])

  return {
    loading: quoteRes.isPending,
    quoteNumber: quoteRes.data?.quoteNumber,
    revoke: {
      revoking,
      onClick: async () => {
        setRevoking(true)

        const prevExpiresIn = cubeContext.queries.rawData.data.quote.expiresIn

        /**
         * Set quote `expiryDays` to -1 days
         */
        const dataToSave = quoteAdapters.out(cubeContext.queries.rawData.data)

        const putQuoteResult = await putQuoteMutator.mutateAsync({
          id: props.quoteId,
          body: {
            ...dataToSave,
            expiryDays: -1
          }
        })

        if (!putQuoteResult) {
          onError()
          return
        }

        /**
         * Republish quote
         */
        const republishQuoteResult = await publishQuoteMutator.mutateAsync({
          id: props.quoteId,
          body: { sendRecipientsEmail: false, emailMessage: undefined }
        })

        if (!republishQuoteResult) {
          onError()
          return
        }

        cubeContext.mutators.updateData({
          quote: {
            expiresAt: undefined,
            expiresIn: prevExpiresIn,
            isExpired: true
          }
        })

        if (props.onSuccess) {
          props.onSuccess()
        }

        setRevoking(false)
        modal.setIsOpen(false)
        displayNotification(
          `Access to quote ${quoteRes.data?.quoteNumber} removed`,
          { type: 'success' }
        )
      }
    }
  }
}

const RevokeQuoteModalUI = (props: {
  quoteId: string
  onClose?: () => void
  onSuccess?: () => void
}) => {
  const hook = useRevokeQuoteModal({
    quoteId: props.quoteId,
    onSuccess: props.onSuccess
  })

  return (
    <>
      <Dialog.Header showCloseButton>Remove quote access</Dialog.Header>
      <Dialog.Content>
        <Box color={GreyGrey80} {...Lato13Regular} flex={1}>
          Removing access will expire this quote. You can still republish this
          quote&nbsp;afterwards.
        </Box>
      </Dialog.Content>
      <Modal.Footer>
        <Grid
          gridTemplateColumns="1fr 1fr"
          gap="8px"
          width="100%"
          paddingTop="12px"
        >
          <Modal.CloseTrigger>
            <Box width="100%">
              <Button
                variant="secondary"
                style={{ width: '100%' }}
                data-testid="revokeQuoteModal.cancel"
                onClick={props.onClose}
              >
                Cancel
              </Button>
            </Box>
          </Modal.CloseTrigger>
          <Button
            variant="destructive"
            onClick={() => {
              void hook.revoke.onClick()
            }}
            disabled={hook.revoke.revoking}
            data-testid="revokeQuoteModal.submit"
          >
            {hook.revoke.revoking ? (
              <Flex gap="8px">
                <Spinner height="16px" width="16px" color={GreyGrey60} />
                Removing access...
              </Flex>
            ) : (
              'Remove access'
            )}
          </Button>
        </Grid>
      </Modal.Footer>
    </>
  )
}

/**
 * Displays a modal which will allows for revoking a quote
 * @param props
 * @returns
 */
export const RevokeQuoteModal = (props: {
  quoteId: string
  children?: ReactNode
  isOpen?: boolean
  onClose?: () => void
  onSuccess?: () => void
}) => {
  return (
    <ModalContextProvider isOpen={props.isOpen}>
      {props.children && <Dialog.Trigger>{props.children}</Dialog.Trigger>}
      <Dialog data-testid="revokeQuoteModal" unmountOnClose>
        <RevokeQuoteModalUI {...props} />
      </Dialog>
    </ModalContextProvider>
  )
}
