import { dashboardv20240509Client } from '@sequencehq/api/dashboard/v20240509'
import { dashboardv99990101Client } from '@sequencehq/api/dist/clients/dashboard/v99990101'
import { IntegrationsPortErrors } from 'modules/Integrations/domain'
import {
  QuickBooksConfig,
  QuickBooksTaxCodeMapping
} from 'modules/Integrations/integrationsConfig/quickBooks/quickbooks.integration.config'

export const quickBooksConfigurationLoader = async (): Promise<{
  data: QuickBooksConfig | null
  error: IntegrationsPortErrors | null
}> => {
  const integrationsResponse = await dashboardv20240509Client.getIntegrations()

  if (integrationsResponse.error) {
    return {
      data: null,
      error: IntegrationsPortErrors.Other
    }
  }

  const integration = integrationsResponse.data?.items.find(
    int => int.service === 'QuickBooks_Online'
  )

  // If QBO is not connected, dont load the configuration
  if (integration?.state !== 'CONNECTED') {
    return {
      data: {
        importCustomerWorkflow: integration?.enabled ?? false,
        taxExemptTaxCode: '',
        taxableTaxCode: '',
        reverseChargedTaxCode: '',
        taxCodeMappings: {},
        defaultAccount: ''
      },
      error: null
    }
  }

  const [defaultTaxCodesData, defaultAccountData, mappingsData] =
    await Promise.all([
      dashboardv99990101Client.getDefaultQuickbooksTaxCodes(),
      dashboardv99990101Client.getDefaultQuickbooksAccount(),
      dashboardv99990101Client.getQuickbooksTaxCodeMappings()
    ])

  const defaultTaxCodes = defaultTaxCodesData?.data ?? {}
  const defaultAccount = defaultAccountData?.data?.standard?.id ?? ''
  const mappings = mappingsData?.data?.items ?? []

  const mappingsByTaxCategoryIdMap = mappings.reduce(
    (acc, mapping) => {
      acc[mapping.taxCategoryId] = mapping
      return acc
    },
    {} as Record<string, QuickBooksTaxCodeMapping>
  )

  return {
    data: {
      importCustomerWorkflow: integration?.enabled ?? false,
      taxExemptTaxCode: defaultTaxCodes.exempt?.id ?? '',
      taxableTaxCode: defaultTaxCodes.taxable?.id ?? '',
      reverseChargedTaxCode: defaultTaxCodes.reverseCharged?.id ?? '',
      taxCodeMappings: mappingsByTaxCategoryIdMap,
      defaultAccount
    },
    error: null
  }
}
