import {
  DiscountEditorDomainInterface,
  DiscountEditorMode
} from 'modules/Cube/view/common/drawers/discountEditor/drawer/discountEditor.types'
import { INITIAL_DISCOUNT } from 'modules/Cube/view/common/drawers/discountEditor/drawer/domainManagement/discounts.constants'
import { createContext, useContext } from 'react'

// biome-ignore lint/suspicious/noConsoleLog: expected console
const notImplemented = () => console.error('Function not implemented')

const DiscountEditorContext = createContext<DiscountEditorDomainInterface>({
  data: {
    discount: {
      ...INITIAL_DISCOUNT,
      id: 'placeholder'
    },
    prices: {}
  },
  queries: {
    allValidationErrors: [],
    validation: [],
    canSave: false,
    changesMade: false
  },
  configuration: {
    availablePrices: [],
    applyToAllAvailable: false
  },
  editor: {
    mode: DiscountEditorMode.CREATE,
    showErrors: false
  },
  functions: {
    updateDiscount: notImplemented,
    saveDiscount: notImplemented,
    closeEditor: notImplemented
  }
})

export const useDiscountEditorContext = () => {
  const discountEditorContext = useContext(DiscountEditorContext)

  return discountEditorContext
}

export const DiscountEditorProvider = DiscountEditorContext.Provider
