import { Flex } from '@chakra-ui/react'
import { SequenceMenuItemType } from '@sequencehq/core-components'
import {
  Dispatch,
  FC,
  memo,
  SetStateAction,
  useCallback,
  useState
} from 'react'
import { InvoicesMagicTable } from 'components/Invoices/InvoicesMagicTable/InvoicesMagicTable'
import Page from 'components/Page'
import { Outlet, useSearchParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { InvoicesGrid } from 'components/Invoices/InvoicesGrid'
import { InvoicesExportToCsv } from 'components/Invoices/InvoicesExportToCsv'
import type { ActiveFilter } from '@sequencehq/tables'
import { useFlags } from 'launchdarkly-react-client-sdk'
import { SavedViewEditor } from 'SavedViews/components/SavedViewEditor'
import { useIsOnSavedViewPage } from 'SavedViews/savedViews.router'
import { SavedViewTitle } from 'SavedViews/components/SavedViewTitle'

const Invoices: FC<{
  showSegmentedFilters?: boolean
  title?: string
  forcedActiveFilters?: ActiveFilter[]
  kebabMenuItems?: (
    setSavingFilters: Dispatch<SetStateAction<false | ActiveFilter[]>>
  ) => SequenceMenuItemType[] | undefined
  onUpdateSavedFilters?: (updatedActiveFilters: ActiveFilter[]) => void
}> = memo(
  ({
    showSegmentedFilters = true,
    title = 'Invoices',
    forcedActiveFilters = [],
    kebabMenuItems,
    onUpdateSavedFilters
  }) => {
    const flags = useFlags()

    const isSavedView = useIsOnSavedViewPage()

    const [searchParams] = useSearchParams()
    const view = searchParams.get('view')

    const [savingFilters, setSavingFilters] = useState<false | ActiveFilter[]>(
      false
    )
    const [forceNewSavedView, setForceNewSavedView] = useState<boolean>(false)

    const onSaveFilters = useCallback(
      (activeFilters: ActiveFilter[]) => {
        if (isSavedView) {
          setForceNewSavedView(true)
        }

        setSavingFilters(activeFilters)
      },
      [isSavedView]
    )

    const richTitle = flags.useSavedViews ? (
      savingFilters ? (
        <SavedViewEditor
          activeFilters={savingFilters}
          onDismiss={() => {
            setForceNewSavedView(false)
            setSavingFilters(false)
          }}
          forceNewSavedView={forceNewSavedView}
        />
      ) : (
        <SavedViewTitle
          title={title}
          kebabMenuItems={kebabMenuItems?.(setSavingFilters)}
        />
      )
    ) : undefined

    const addNewConfig = savingFilters
      ? undefined
      : {
          buttonText: 'New invoice',
          overlayPayload: {
            content: 'createInvoiceModal' as const,
            data: {
              customer: undefined
            }
          }
        }

    const [filtersApiQueryParams, setFiltersApiQueryParams] =
      useState<string>('')

    const menuButtons = savingFilters ? null : (
      <InvoicesExportToCsv filtersApiQueryParams={filtersApiQueryParams} />
    )

    return (
      <Flex flexDirection="column" flex={1}>
        <Flex position="sticky" top={0} height="100vh">
          <Page
            title={title}
            richTitle={richTitle}
            addNewConfig={addNewConfig}
            menuButtons={menuButtons}
            paddingBottom={0}
          >
            {match(view)
              .with('grid', () => <InvoicesGrid />)
              .otherwise(() => (
                <InvoicesMagicTable
                  onSaveFilters={
                    flags.useSavedViews ? onSaveFilters : undefined
                  }
                  onUpdateSavedFilters={
                    isSavedView ? onUpdateSavedFilters : undefined
                  }
                  showSegmentedFilters={showSegmentedFilters}
                  forcedActiveFilters={forcedActiveFilters}
                  onFiltersApiQueryParamsChanged={newParams =>
                    setFiltersApiQueryParams(newParams)
                  }
                  lockedControls={savingFilters && savingFilters.length > 0}
                />
              ))}
          </Page>
        </Flex>

        <Outlet />
      </Flex>
    )
  }
)

export default Invoices
