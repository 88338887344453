import { DashboardApi20240730 } from '@sequencehq/api/dist/clients/dashboard/v20240730'
import {
  NEW_DISCOUNT_PREFIX,
  NEW_MINIMUM_PREFIX,
  NEW_PRICE_PREFIX
} from 'Cube/domain/cube.constants'
import { CubeReducerState } from 'Cube/domain/cube.domain.types'

type ApiPhase = DashboardApi20240730.PutQuote.PutQuoteBody['phases'][0]

export const phasesToQuotePhases = (
  data: CubeReducerState['data']
): Array<{
  name?: string
  prices: Array<ApiPhase['prices'][0]>
  discounts: Array<ApiPhase['discounts'][0]>
  minimums: Array<ApiPhase['minimums'][0]>
  duration?: {
    years: number
    months: number
    weeks: number
    days: number
  }
}> => {
  return Object.values(data.phases).map(phase => ({
    id: phase.id,
    name: phase.name,
    prices: phase.priceIds.map(priceId => {
      const price = data.prices[priceId]

      const { id, ...rest } = {
        ...price,
        structure: price.structure
      }

      return {
        temporaryId: id.split(NEW_PRICE_PREFIX)[1] ?? id,
        ...rest
      }
    }),
    discounts: Object.values(phase.discountIds).map(discountId => {
      const discount = data.discounts[discountId]

      return {
        id: discountId.split(NEW_DISCOUNT_PREFIX)[1] ?? discountId,
        restrictToPrices: discount.priceIds.map(
          priceId => priceId.split(NEW_PRICE_PREFIX)[1] ?? priceId
        ),
        type: discount.discountCalculationType,
        amount: discount.amount.toString(),
        message: discount.message,
        separateLineItem: true,
        seatDiscountType: discount.seatDiscountType
      }
    }),
    minimums: Object.values(phase.minimumIds).map(minimumId => {
      const minimum = data.minimums[minimumId]
      return {
        id: minimumId.split(NEW_MINIMUM_PREFIX)[1] ?? minimumId,
        restrictToPrices: minimum.scope.priceIds.map(
          priceId => priceId.split(NEW_PRICE_PREFIX)[1] ?? priceId
        ),
        amount: minimum.value
      }
    }),
    duration:
      phase.duration &&
      phase.duration !== 'OPEN_ENDED' &&
      phase.duration !== 'MILESTONE'
        ? {
            years: phase.duration?.years ?? 0,
            months: phase.duration?.months ?? 0,
            weeks: 0,
            days: 0
          }
        : undefined
  }))
}
